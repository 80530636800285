
import React from 'react';
import { Image, Loader, List, Icon, Modal, Grid, Divider, Select, Input, TextArea, Sidebar, Segment, Button, Dimmer } from 'semantic-ui-react'
import Utils from '../constants/Utils'
import Searcher from '../components/Searcher'
import Head from '../components/Head';
import Slider from '../components/Slider';
import ModifiableItem from '../components/ModifiableItem'
import CustomersSelection from '../components/CustomersSelection'
import SimpleMap from '../components/SimpleMap'
import axios from 'axios'
import TwoButtons from '../components/TwoButtons';
import TopicsSelection from '../components/TopicsSelection'
import AppLoader from '../components/AppLoader'

import {connect} from 'react-redux'


/**
 * @title NewRealisation Component
 * @dev Form to create a new realisation
 * ##PROPS
 *  - onPublish (func) method called each time client hits 'publish' button. MUST take one parameter : {published}(boolean)
 *  - preview (bool) indicate if a preview needs to be shown
 *  - closeRealisation (func) : method to close newRealisation modal
 *  - closeLockedRealisations (func) : methods to close lockedRealisations modal
 *  - fetchCredentials
 */
class NewRealisation extends React.Component {

    state = {
        newRealisation: {},
        isPublishing: false
    }

    setRealisation = (props) => {
        this.setState({newRealisation: {...this.state.newRealisation, ...props}})
    }

    onFileUpload = (e) => {
        let files = e.target.files  //FileList
        let $dom = document.getElementById('selectedFilesResult')
        $dom.innerHTML = ""

        for(var i = 0; i < files.length; i++)
        {
            (async(_i) => {
                await new Promise(async(resolve, reject) => {
                    let Reader = new FileReader();
                    let file = files[_i];
                    Reader.addEventListener('load', async(event) => {
                        let picFile = await Promise.resolve(event.target)
                        var div = document.createElement("div");
                        // todo : allow videos too: check file type first, then distrib appropriate dom tag with src
                        var mediaElement = await Promise.resolve(
                            file.type.indexOf('image') >= 0 
                            ? '<img class="ui centered small image" src="'+picFile.result+'" />'
                            : '<video width="100%" controls><source src="'+picFile.result+'"></video>'
                        )
                        div.innerHTML = await Promise.resolve('<div class="centered"><div class="ui marged-bottom anthracite pointing below label centered">'+file.name+'</div>'+mediaElement+'</div>'); 
                        $dom.append(div)

                    })

                    Reader.readAsDataURL(file)
                    resolve(true)
                })
            })(i)
        }
        // 'fileData' MUST be used for server to upload files to S3 automatically
        this.setRealisation({fileData: e.target.files})
    }

    sideBarPreview = () => {
        const { name, topics, files, details, city, date, relatedCustomer } = this.state.newRealisation
        return <div className="block" style={{minHeight: '50vh'}}>
            <Grid>
                <Grid.Column width={16}>
                    <Head 
                        title={name} 
                        content={<div className="silver">{topics && topics.map((t, ti) => `${t}${ti===topics.length-1 ? ' ' : ','} `)}</div>}
                    />
                    <Divider />
                    <p>{details}</p>
                    <Divider />
                    {/* <Slider files={files}/> */}
                    <div id="selectedFilesResult"></div>
                    <Divider />
                </Grid.Column>
               
                <Grid.Column width={16} style={{height: '20em'}}>
                    {city && <SimpleMap location={city} height="30vh" width="100%"/>}
                </Grid.Column>
            </Grid>
            
            
        </div>
    }


    /**
     * @dev Publish a new realisation via multer using axios
     * for file upload see https://muffinman.io/uploading-files-using-fetch-multipart-form-data/
     */
    publish = async() => {
        this.setState({isPublishing: true})
        const {email, hash} = this.props.fetchCredentials
        const {newRealisation} = this.state
        let formData = new FormData();

        if (this.state.newRealisation.fileData) {
            for (const file of this.state.newRealisation.fileData) {
                formData.append('fileData', file);
            }
        }
       
        for (const k of Object.keys(newRealisation)) {
            formData.append(k, newRealisation[k])
        }

        const endPublicationState = () => this.setState({isPublishing: false})

        setTimeout(() => {
            axios
            .post(`${process.env.REACT_APP_API_URL}api?credentials=${email.toLowerCase()}::${hash}&request=newRealisation`, formData)
            .then(res => {
                global.fireMsg({positive: `Nouvelle réalisation ${newRealisation.name} enregistrée`});
                endPublicationState()
                typeof this.props.onPublish === 'function' && this.props.onPublish({published: res.status === 200})
            })
            .catch(err => {
                console.error('publish AXIOS error:', err)
                endPublicationState()
                global.fireMsg({error:'Erreur lors de la publication, veuillez réessayer'})
                typeof this.props.onPublish === 'function' && this.props.onPublish({published: false})
            });
        })
        
            
        
    }


    render() {
        let preview = this.sideBarPreview()
        console.log('newRealisation', this.state.newRealisation)
        return(
     
            

            <Sidebar.Pushable as={Segment}>
                <Sidebar animation="scale down" direction="right" visible={this.props.preview || false} width="very wide" style={{height: '100%'}}>
                        {preview}
                </Sidebar>
                <Sidebar.Pusher>
                    {/* indicate that publication is being send if isPublishing */}
                    <Dimmer active={this.state.isPublishing }><Loader inverted>Publication en cours</Loader></Dimmer>

                    <div id="newRealisationForm" className="tekoAll block">
                        <Head 
                        title="Titre"
                        icon="font"
                        content={
                        <Input placeholder="Réalisation d'une isolation extérieure" onChange={(e) => this.setRealisation({name: e.target.value})} />
                        }
                    />
                    <Divider />
                    <Head 
                        title="Catégorie"
                        icon="filter"
                        content={
                            // <Select multiple
                            //     placeholder="Séléctionner une ou plusieurs catégories"
                            //     options={categoriesSelectionOptions}
                            //     onChange={(__, {value}) => this.setRealisation({topics: value})}
                            // />
                            <TopicsSelection onChange={(__, {value}) => this.setRealisation({topics: value})} />
                        }
                    />
                    <Divider />
                    <Head 
                        title="Détails"
                        icon="info circle"
                        content={
                            <TextArea placeholder="Batiment de 250m2 ré-isolé complétement, ..." onChange={(e) => this.setRealisation({details: e.target.value})}/>
                        }
                    />
                    <Divider />
                    <Head 
                        title="Médias"
                        icon="images outline"
                        content={
                            <Input type="file" multiple onChange={this.onFileUpload} />
                        }
                    />
                    <Divider />
                    
                    <Head 
                        title="city"
                        icon="map pointer"
                        content={
                            <Input placeholder="rue de paris, 80700 Roye" onChange={(e) => this.setRealisation({city: e.target.value})}/>
                        }
                    />
                    <Divider />
                    <Head 
                        title="Date"
                        icon="calendar alternate outline"
                        content={
                            <Input type="date" onChange={(e) => this.setRealisation({date: e.target.value})}/>
                        }
                    />
                    <Divider />
                    <Head 
                        title="Client"
                        icon="user outline"
                        content={
                            <CustomersSelection onSelect={(__, {value}) => this.setRealisation({relatedCustomer: value})}/>
                        }
                    />
                    <Segment>
                        <TwoButtons 
                            buttonOne={{
                                className: "greenAppButton",
                                title: "Publier",
                                clickHandler: this.publish.bind(this),
                                icon: "upload"
                            }}
                            buttonTwo={{
                                className: "redAppButton",
                                title: "Annuler",
                                icon: "cancel",
                                clickHandler: this.props.closeRealisation
                            }}
                        />
                        {/* <Button className="greenAppButton" onClick={this.publish}>Publier <Icon name="upload" /></Button> */}
                    </Segment>

                </div>
            </Sidebar.Pusher>
          </Sidebar.Pushable>
        )
    }
}

/**
 * @title RealisationFile Component
 * @dev Display a validated OR unvalidated realisation 
 * ##PROPS
 *  - realisation {object} : the realisation to display
 *  - validateRealisation (func) : method called to validate an unvalidated realisation (managers restricted)
 *  - removeRealisation (func) : method called to remove a realisation
 */
class RealisationFile extends React.Component {

    managerRestricted = () => this.props.licence === 'manager'

    render() {
        const {realisation} = this.props
        return(
            <div className="tekoAll">
                
                <ModifiableItem
                    as="h1"
                    className="centered"
                    pair={{key: 'name', oldValue: realisation.name}}
                    title="Titre de la réalisation"
                    titleStyle={{color: '#414141', fontSize: 25}}
                    request="setRealisation"
                    additionalRequestBodyProp={{id: realisation.id}}
                    restrictEdition={this.managerRestricted}
                />
                <Grid>
                    <Grid.Column width={6} style={{textAlign: 'left', marginTop: 10}}>
                        <ModifiableItem
                            icon="newspaper"
                            pair={{key: "details", oldValue: realisation.details}}
                            title={<span style={{color: 'rgba(0,0,0,.9)', fontSize: '2em'}}><Icon name="newspaper" className="margedRight" size="large"/>Description</span>}
                            request="setRealisation"
                            additionalRequestBodyProp={{id: realisation.id}}
                            customEditionComponent={(onChange, value) => <textarea onChange={onChange} className="notes-textarea" value={value}></textarea>}
                            restrictEdition={this.managerRestricted}
                        />
                        {/* <Head as="h5" title="Description" icon="newspaper" content={<Icon color="grey" name="edit" />}/>
                        <p className="txtM">{realisation.description || realisation.details}</p> */}
                    </Grid.Column>

                    <Grid.Column width={10}>
                        <Slider files={realisation.files} />
                    </Grid.Column>

                    {
                        !realisation.validated && this.managerRestricted()
                        && <Grid.Row className="centered">
                            <TwoButtons 
                                buttonOne={{
                                    className: "greenAppButton",
                                    title: "Valider la publication",
                                    clickHandler: () => this.props.validateRealisation(),
                                    icon: "check"
                                }}
                                buttonTwo={{
                                    className: "redAppButton",
                                    title: "Refuser",
                                    icon: "cancel",
                                    clickHandler: () => this.props.removeRealisation()
                                }}
                            />
                        </Grid.Row>
                    }
                    
                </Grid>
                
                
            </div>
        )
    }

}


/* REALISATIONS ICONS */
const newRealIcon = ({onClick, className}) => <Icon.Group key={Utils.keyExtractor()} className={className || undefined} onClick={onClick || undefined} size='large'>
    <Icon name='newspaper outline' />
    <Icon corner name='add' color="teal" />
</Icon.Group>

const lockedRealIcon = ({onClick, className}) => <Icon.Group key={Utils.keyExtractor()} className={className || undefined} onClick={onClick || undefined} size='large'>
    <Icon name='newspaper outline' />
    <Icon corner name='lock' color="red" />
</Icon.Group>

const removeRealIcon = ({onClick, className}) => <Icon.Group key={Utils.keyExtractor()} className={`${className || ''} vibrate`} onClick={onClick || undefined} size='large'>
    <Icon name='newspaper outline' />
    <Icon corner name='close' color="red" />
</Icon.Group>


/**
 * @title Realisations React Component
 *  - Fetch server realisations
 *  - rank unvalidated realisations 
 *  - allow managers validation, editions, deletations, consultations
 */
class Realisations extends React.Component {
  
    constructor() {
        super();

        this.state = {
        loading : true,
        openedRealisation : false,
        preview: false   
        }
    
    }

    
    async componentDidMount() { 
        try {//'https://amcleblanc.herokuapp.com/api/'+global.authCredentials.mail.toLowerCase()+'::'+global.authCredentials.pwd
            await this.fetchRealisations()

        }catch(err){
            console.error("Error fetching data----------", err);
        }
    }  

    async componentDidUpdate() {
        const { reload, appBarSet, datas, openedRealisation } = this.state;
        if (reload) {
            this.setState({reload: false, openedRealisation: undefined, newRealisation: undefined, lockedRealisations: false})
            this.fetchRealisations()
        }
        if (!appBarSet) {
            this.setState({appBarSet: true})
            this.props.appBarSetter({
                viewName: "Réalisations",
                searcher: <Searcher data={datas} editList={this._editList.bind(this)} onUpdateSearch={this.onUpdateSearch.bind(this)} dataLen={datas.length} dataName="réalisation"/>,
                controllers: [
                    <Icon key={Utils.keyExtractor()} className="overable" size="large" name="arrow left" onClick={() => this.setState({openedRealisation: undefined})} />,
                    newRealIcon({onClick: () => this.setState({openedRealisation: undefined, newRealisation: true}), className:"overable margedLeft"}),
                    (this.props.session.Client.licence === 'manager' || this.props.session.Client.access.realisationRight) && lockedRealIcon({onClick: () => this.setState({openedRealisation: undefined, lockedRealisations: true}), className:"overable margedLeft"}),
                    this.props.session.Client.licence === 'manager' && removeRealIcon({onClick: () => this.removeRealisation(), className:"overable margedLeft"})
                    // <Icon className="overable" size="large" name="plus" onClick={() => this.setState({newRealisation: true})} />
                ],
                centerController: "",
                bottomController: ""
            })
            
        }
    }


    fetchRealisations = async() => {
        !this.state.loading && this.setState({loading:true})
        const {error, response} = await Utils.fetchApi({
            method: 'get',
            request: "works",
            client: this.props.session.fetchCredentials

        })
        if (error) alert('Erreur lors de la récupération des réalisations, veuillez recharger la page')
        this.setState({datas : response, loading: false});
    }

    togglePreview = () => this.setState({preview: !this.state.preview})

    _openRealisation = (openedRealisation) => {
        this.setState({openedRealisation})
    }

    removeRealisation = async() => {
        const {openedRealisation} = this.state

         
            if (openedRealisation && window.confirm(`Souhaitez-vous supprimer définitivement la réalisation ${openedRealisation.name} et les fichiers associés ?`)) {
                let {error} = await Utils.fetchApi({
                    request: "removeRealisation",
                    body: {realisationToRemove: openedRealisation},
                    client: this.props.session.fetchCredentials

                })
                
                if (error) {
                    global.fireMsg({error: "Une erreur est survenue lors de la suppression. Veuillez recommencer l'opération"})
                } else {
                    this.setState({openedRealisation: undefined, reload: true})
                    global.fireMsg({positive: "Réalisation supprimée"})
                    
                }
            } else console.error('removeRealisation Error: missing realisation')
        
    }

    _isArray = test => {return (typeof test === 'object' && typeof test.length !== 'undefined')}

    itemList = ($t) => {
        return (typeof $t === 'string' ? $t : (($t.length > 1) ? $t.join(', ') : $t[0]))
    }

    keyExtractor = (item, index) => index.toString()  

    /* SEARCH */
    _editList = (searchDatas) => {
        this.setState({searchDatas})
    }
    onUpdateSearch = (search) => {
        this.setState({search})
    }

    /* render methods */
    renderItem = ( item, isUnvalidated ) => (
        <List.Item className="padded1 overable" key={Utils.keyExtractor()} onClick={() => {this._openRealisation.bind(this)(item)}}>
            <Image avatar src={item.files && item.files[0] && item.files[0].location} alt={item.name} />
            <List.Content>
                <List.Header>{item.name || 'pas de titre'}</List.Header>
                <List.Description>{(item.topics) ? this.itemList(item.topics) : 'pas de catégorie'}</List.Description>
            </List.Content>
            { isUnvalidated && <Divider /> }
        </List.Item>
    )

    renderUnvalidated = () => {
        let data = this.state.datas.filter((d) => !d.validated)
        return(
            (data && data.length)
            ? data.map((d) => this.renderItem(d, true))
            : <List.Item className="positiveText"><List.Content className="padded5"><List.Header className="positiveText bordered">Toutes les réalisations sont validées <Icon className="margedLeft" name="check" color="teal" /></List.Header></List.Content></List.Item>
        )
    }

    // publisher is bound
    onPublish = ({published}) => {
        // refetch realisations if published
        this.setState({reload: published})
    }

    validateRealisation = async() => {
        const {openedRealisation: realisation} = this.state

        const {error} = await Utils.fetchApi({
            request: 'validateRealisation',
            body: {id: realisation.id},
            client: this.props.session.fetchCredentials

        })
        if (error) {
            this.setState({lockedRealisations: false, openedRealisation: false})
            global.fireMsg({error: "Une erreur est survenue lors de la validation. Veuillez recommencer l'opération"})
        } else {
            this.setState({reload: true})
            global.fireMsg({positive: "Réalisation validé et mise en ligne"})
        }

    }

    closeRealisation = () => this.setState({newRealisation: false})
    closeLockedRealisations = () => this.setState({lockedRealisations: true, openedRealisation: false})

    render () {
        const {datas, searchDatas, search, loading, openedRealisation, newRealisation, lockedRealisations, preview} = this.state;
        if (!loading) { // datas are loaded
        
            return (
                <div>
                {!openedRealisation 
                ? ( /* show realisations searcher */
                    <div className="tekoAll txtM">

                        {/* NEW REALISATION */}
                        <Modal dimmer
                            size="large"
                            open={newRealisation}
                            onClose={this.closeRealisation}
                        >
                            <Image floated="right"><Icon name="eye" size="large" circular raised="true" onClick={this.togglePreview} /></Image>
                            <div className="centered padded1">
                                <Head 
                                    title="Enregistrer une nouvelle réalisation"
                                    icon={newRealIcon({})}
                                    className="margedTop"
                                />
                                <NewRealisation 
                                    fetchCredentials={this.props.session.fetchCredentials}
                                    preview={preview} 
                                    onPublish={this.onPublish.bind(this)} 
                                    closeRealisation={this.closeRealisation.bind(this)}
                                />
                            </div>
                        </Modal>

                        {/* UNVALIDATED REALISATIONS */}
                        <Modal dimmer
                            open={lockedRealisations}
                            onClose={() => this.setState({lockedRealisations: false})}
                        >
                            <div className="centered">
                                <Head 
                                    title="Réalisations en attente de validation"
                                    content={<span className="silver"></span>}
                                    icon={lockedRealIcon({})}
                                    className="margedTop"
                                />
                                <Divider />
                                <List>
                                    {
                                        this.renderUnvalidated()
                                    }
                                </List>
                                
                            </div>
                        </Modal>

                        <List animated divided relaxed>
                            {
                                (search && search.length > 0)
                                ? (
                                    (searchDatas && searchDatas.length > 0)
                                    ? searchDatas.map((d) => this.renderItem(d))
                                    : <div className="flexy flexCenter"><Head as="h3" icon="dont" title="Pas de résultat" className="negativeText" /></div>
                                ): (
                                    datas.map((d) => d.validated ? this.renderItem(d) : undefined)
                                )
                            }
                        </List>
                    </div>
                ) : ( /* open realisation file */
                    <RealisationFile 
                        licence={this.props.session.Client.licence}
                        realisation={openedRealisation} 
                        validateRealisation={this.validateRealisation.bind(this)}
                        removeRealisation={this.removeRealisation.bind(this)}
                    />
                )} 
                </div>
                
            )
            
        } else {    // datas are loading...
            return <span className="flexy flexCenter">
                {/* <Loader><p className="centered">Chargement des réalisations</p></Loader> */}
                <AppLoader open={loading} title="Chargement des réalisations" />
            </span>
        }
    }

}

export default connect(Utils.mapStateToProps)(Realisations)
 
