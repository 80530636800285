import React from 'react'
import {Image} from 'semantic-ui-react'

/**
 * @dev PROPS :
 *  - size (enum) : mini, tiny, small, medium, large, big, huge, massive 
 *  - classAttribute
 *  - floated (enum) : left, right, center
 */
export default class AppLogo extends React.Component {
    render() {
        return (
            <Image 
                src='logo_amc.png' 
                size={this.props.size} 
                alt="AMC Leblanc maçonnerie couverture à 80700 Roye" 
                className={this.props.classAttribute} 
                floated={this.props.floated} 
                onClick={this.props.onClick || undefined}
                centered 
            />

        )
    }
}