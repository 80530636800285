import React from 'react'
import Scheduler, {SchedulerData, ViewTypes} from 'react-big-scheduler'
import 'react-big-scheduler/lib/css/style.css'
import moment from 'moment'
import { Modal, Grid, Checkbox, Icon, Divider } from 'semantic-ui-react'
import Head from '../components/Head'
import withDragDropContext from '../components/withDnDContext'
import AppLoader from '../components/AppLoader'
import frenchLocal from 'moment/locale/fr'
import Utils from '../constants/Utils'
import CustomersSelection from '../components/CustomersSelection'
import RruleGenerator from '../components/RruleGenerator'
import { connect } from 'react-redux'

/**
* @dev Agenda Event Categories
*/
const Resources = [
   {
   id: 'r1',
   name: 'Clientèle'
   },
   {
   id: 'r2',
   name: 'Interne'
   },
   {
   id: 'r3',
   name: 'Partenaire'
   },
]

// const DemoData = {

//     /**
//      * @dev Client.events
//      */
//     events : [
//         {
//             id: 4,
//             start: '2019-12-19 14:30:00',
//             end: '2019-12-19 16:30:00',
//             resourceId: 'r2',
//             title: 'Réunion Commerciale',
//             startResizable: true
//         }, 
//         {
//             id: 2,
//             start: '2019-09-09 12:30:00',
//             end: '2019-09-10 23:30:00',
//             resourceId: 'r1',
//             title: 'I am resizable and movable',
//             resizable: true,
//             movable: true
//         }, 
//         {
//             id: 1,
//             start: '2017-12-18 09:30:00',
//             end: '2017-12-19 23:30:00',
//             resourceId: 'r1',
//             title: 'I am finished',
//             bgColor: '#D9D9D9'
//         }, 
    
//         {
//             id: 3,
//             start: '2017-12-19 12:30:00',
//             end: '2017-12-20 23:30:00',
//             resourceId: 'r3',
//             title: 'I am movable',
//             movable: true
//         }, 
//         {
//             id: 5,
//             start: '2017-12-19 15:30:00',
//             end: '2017-12-20 23:30:00',
//             resourceId: 'r2',
//             title: 'R2 has recurring tasks every week on Tuesday, Friday',
//             rrule: 'FREQ=WEEKLY;DTSTART=20171219T013000Z;BYDAY=TU,FR',
//             bgColor: '#f759ab',
//             resizable: true,
//             movable: true
//         }
//     ]
// }

const resourceNameFromId = (id) => {
    let name;
    Resources.forEach((r) => {
        if (r.id === id)
            name = r.name
    })

    return name
} 

const SilverSpan = ({title, value}) => <li><span className="silver">{title} </span> <span style={{fontSize: 20, color: 'grey'}}>{ value }</span></li>

class Calendar extends React.Component{
    constructor(props){
        super(props);
       
        this.state = {
            loading: true,
        }

        this.setLinkedCustomer = this.setLinkedCustomer.bind(this)
    }

    initCalendar = async() => {
        moment.updateLocale('fr', frenchLocal)
        //let schedulerData = new SchedulerData(new moment("2017-12-18").format(DATE_FORMAT), ViewTypes.Week);
        //let schedulerData = new SchedulerData('2019-09-14', ViewTypes.Week, false, false, {
        let schedulerData = new SchedulerData(moment().format('YYYY-MM-DD'), ViewTypes.Week, false, false, {
            resourceName: "Catégories",
            taskName: "Evènements",
            defaultFormat: "DD-MM-YYYYTHH:mm:ssZ",
            defaultFormatUtc: "DD-MM-YYYYTHH:mm:ss[Z]",
            addMorePopoverHeaderFormat: 'dddd DD MMM YYYY',
            eventItemPopoverDateFormat: "DD MMM",
            nonAgendaOtherCellHeaderFormat: "ddd DD/MM",
            
            views: [
                {viewName: 'Jour', viewType: ViewTypes.Day, showAgenda: false, isEventPerspective: false},
                {viewName: 'Semaine', viewType: ViewTypes.Week, showAgenda: false, isEventPerspective: false},
                {viewName: 'Mois', viewType: ViewTypes.Month, showAgenda: false, isEventPerspective: false},
                {viewName: 'Trimestre', viewType: ViewTypes.Quarter, showAgenda: false, isEventPerspective: false},
                {viewName: 'Année', viewType: ViewTypes.Year, showAgenda: false, isEventPerspective: false},
            ]
            
        }, {
            getDateLabelFunc: this.getDateLabel
        }, moment);
        let Events = await Promise.resolve(this.props.getEvents())
        if (typeof Events !== 'undefined') {
            let events = await Promise.resolve(Events.map((e, ei) => ({...e, id:(ei+1)}))) // Scheduler id here
            //console.log('Calendar got events on init', events)
            //schedulerData.localeMoment.locale('fr');
            schedulerData.setResources(Resources);
            schedulerData.setEvents(events);
    
            this.setState({ viewModel: schedulerData, events, loading: false})
        } else {
            // client has no events
            schedulerData.setResources(Resources);
            this.setState({viewModel: schedulerData, loading: false, events : []})
        }
       
    }

    componentDidMount() {
        this.initCalendar()
        this.props.appBarSetter({
            viewName: 'Agenda',
            controllers: '',
            centerController: "",
            bottomController: "",
            searcher: '',
            logError: undefined,
            logPositive: undefined
        })
    }

    render(){
        const {viewModel, editingEvent, loading} = this.state;
        //console.log('Calendar.js render state', this.state)
        return (
            loading 
            ? <AppLoader open={loading} title="Chargement de l'agenda" />
            : <div>
                <Modal dimmer="blurring" size="large" className="tekoAll" open={typeof editingEvent !== 'undefined'} onClose={this.closeOpenedEvent}>
                    {editingEvent && this.eventCard({event: editingEvent})}
                </Modal>
                <Scheduler schedulerData={viewModel}
                    prevClick={this.prevClick}
                    nextClick={this.nextClick}
                    onSelectDate={this.onSelectDate}
                    onViewChange={this.onViewChange}
                    //eventItemClick={this.modifyEvent}
                    viewEventClick={this.modifyEvent}
                    viewEventText="Modifier"
                    viewEvent2Text="Supprimer"
                    viewEvent2Click={this.removeAgendaEvent}
                    updateEventStart={this.updateEventStart}
                    updateEventEnd={this.updateEventEnd}
                    moveEvent={this.moveEvent}
                    newEvent={this.newEvent}
                    onScrollLeft={this.onScrollLeft}
                    onScrollRight={this.onScrollRight}
                    onScrollTop={this.onScrollTop}
                    onScrollBottom={this.onScrollBottom}
                    toggleExpandFunc={this.toggleExpandFunc}
                />
            </div>
        )
    }

    toggleAddRRuleProp = () => this.setState({addRRuleProp: !this.state.addRRuleProp})

    /* event eg.: {
        bgColor: "#f759ab"
        end: "2019-09-12 00:30:00"
        id: "5-180"
        movable: true
        recurringEventEnd: "2017-12-20 23:30:00"
        recurringEventId: 5
        recurringEventStart: "2017-12-19 15:30:00"
        resizable: true
        resourceId: "r2"
        rrule: "FREQ=WEEKLY;DTSTART=20171219T013000Z;BYDAY=TU,FR"       // *opt*
        start: "2019-09-10 16:30:00"
        title: "R2 has recurring tasks every week on Tuesday, Friday" 
    }
    */
    eventCard = ({event}) => <Grid> 
        <Grid.Column width={16}>
            <Head 
                as="h1"
                title={event.title}
                icon="calendar alternate outline"
                iconStyle={{padding: '0 15vw 0 15vw'}}
                content={<div>
                    <SilverSpan title="Catégorie" value={resourceNameFromId(event.resourceId)} />
                    <SilverSpan title="Évènement" value={`${event.rrule ? 'récurrent' : 'unique'}`} />
                    <SilverSpan title="Début" value={`${moment(event.start).format('LLLL')} (${moment(event.start).fromNow()})`} />
                    <SilverSpan title="Fin" value={`${moment(event.end).format('LLLL')} (${moment(event.end).fromNow()})`} />
                    <SilverSpan title="Ajouté par" value={`${event.addedBy ? Utils.teamMemberFromId(event.addedBy, null, this.props.session.TML).username : 'n/c'}`} />

                </div>}
                contentStyle={{textAlign: 'left', width: '100%'}}

            />
        </Grid.Column>
        <Grid.Column width={16}>
            <Divider />
            <Head 
                as="h1"
                title={"Client concerné"}
                icon="user outline"
                iconStyle={{padding: '0 15vw 0 15vw'}}
                content={<CustomersSelection value={event.linkedCustomer} onSelect={(__, {value}) => this.setLinkedCustomer(value)}/>}
                contentStyle={{textAlign: 'left', width: '100%'}}
            />
        </Grid.Column>

        <Grid.Column width={16}>
            <Divider />
            <Head
                as="h1"
                title="Couleur"
                icon="paint brush"
                iconStyle={{padding: '0 15vw 0 15vw'}}
                content={
                    <span>
                        {
                            ["black", "grey", "pink", "purple", "blue", "green", "yellow", "orange", "red"].map((color) => <Icon className="marged1 overable" onClick={() => this.setEventColor(color)} color={color} name="circle" />)
                        }
                    </span>
                }
                contentStyle={{textAlign: 'left', width: '100%'}}

            />
        
        </Grid.Column>

        <Grid.Column width={16}>
            <Divider />
            <Head 
                as="h1"
                title={<span style={!this.state.addRRuleProp ? {color: 'grey'} : undefined}>Évènement récurrent</span>}
                icon="redo"
                iconStyle={{padding: '0 15vw 0 15vw', color: !this.state.addRRuleProp && 'grey'}}
                content={<Checkbox toggle defaultChecked={event.rrule !== undefined} onChange={this.toggleAddRRuleProp.bind(this)}/>}
                contentStyle={{textAlign: 'left', width: '100%'}}
            />
            {
                (this.state.addRRuleProp || event.rrule)
                && <RruleGenerator 
                    event={event}
                    onRruleComputed={this.makeEventRecurrent.bind(this)}
                />
            }
        </Grid.Column>
    </Grid>

    makeEventRecurrent = async(rrule) => {
        const {editingEvent} = this.state
        if (editingEvent && (rrule && rrule.length)) {
            //console.log('makeEventRecurrent',{...editingEvent, rrule})

            let served = await this.eventOperationsServer({
                request: "setEvent",
                props: {eventId: editingEvent.eventId, merge: {...editingEvent, rrule}}
            })
            
            if (!served) alert("Une erreur est survenu lors de la modification de l'évènement: retour à l'état d'origine. Veuillez réessayer")
            else this.setState({editingEvent: undefined})
        }
    }
    

    getDateLabel = (schedulerData, viewType, startDate, endDate) => {
        let start = schedulerData.localeMoment(startDate);
        let end = schedulerData.localeMoment(endDate);
        let dateLabel = start.format('ddd DD MMM YYYY');
        if(viewType === ViewTypes.Week) {
            dateLabel = `${start.format('DD')} au ${end.format('DD MMM YYYY')}`;
            if(start.month() !== end.month())
                dateLabel = `${start.format('DD MMM')} au ${end.format('DD MMM YYYY')}`;
            if(start.year() !== end.year())
                dateLabel = `${start.format('DD MMM YYYY')} au ${end.format('DD MMM YYYY')}`;
        }
        else if(viewType === ViewTypes.Month){
            dateLabel = start.format('MMM YYYY');
        }
        else if(viewType === ViewTypes.Quarter){
            dateLabel = `${start.format('DD MMM YY')} au ${end.format('DD MMM YY')}`;
        }
        else if(viewType === ViewTypes.Year) {
            dateLabel = `Année ${start.format('YYYY')}`;
        }

        return dateLabel;
    }

    /**
     * @dev Know if an event occurs starts and end during the same day
     */
    sameDay = (event) => moment(event.start).format('L') === moment(event.end).format('L')

    setLinkedCustomer = async(linkedCustomer) => {
        const {editingEvent, viewModel, events} = this.state
        
        // set event remotely
        const eventOperationOptions = await Promise.resolve({
            request: 'setEvent',
            props: {
                eventId: editingEvent.eventId,
                merge: {...editingEvent, linkedCustomer}
            }
        })

        let served = await this.eventOperationsServer(eventOperationOptions)
        if (served) {
            // set event locally
            if (viewModel) {
                let Events = await Promise.resolve(events.map((e, ei) => {e.id = ei+1; if(e.eventId === editingEvent.eventId) { e.linkedCustomer=linkedCustomer; } return e }))
                viewModel.setEvents(Events)
            }
            this.setState({viewModel})
        }
    }

    /**
     * @dev POST REQUESTS DEDICATED TO EVENTS
     *  #param 'request' (string) *required* The API request for event operation :
     *      - 'setEvent' : set event as defined in operation.props by merging or replacing the event
     *      - 'deleteEvent' : delete one event by its id providing an 'eventId' prop
     * 
     *  #param 'props' {object} :
     *      - eventId (number) *required if deleteEvent or setEvent an existing event* : the event id to set (depending on operation.request)
     *      - merge {object} *opt*: event object to merge with existing one for modification (overwrite "replace" if "replace" is provided) or event object for creation
     *      - replace {object} *opt*: event object to replace existing one with (overwritten by "merge" if "merge" is provided)
     *      
     * @notice *opt: use only if operation.request === 'setEvent'*
     * @return {object} {error, response}
     */
    eventOperationsServer = async({props, request}) => {
        const {error, response} = await Utils.fetchApi({
            //default post
            request,
            body: {...props},
            client: this.props.session.fetchCredentials

        })
        //console.log('event operation server response', response)
        if (error) throw new Error(`[eventOperationServer Error] -> ${error}`)
        else {
            this.resetCalendar()
            return true
        }
    }

    eventFromId = (eventId) => {
        return new Promise((res) => {
            const {events} = this.props.session.Client
            if (!events || !events.length) return false
            else {
                events.forEach((evt, i) => {
                    if (evt.eventId === eventId)
                        res(evt)
                    else if (i === events.length-1)
                        res(undefined)
                })
            }
        })
    }

    resetCalendar = async() => {
        let {error, response:Client} = await Utils.fetchApi({
            method: 'get',
            request: 'client',
            client: this.props.session.fetchCredentials

        })
        if(error) console.error('resetCalendar ERROR:', error)
        
        else {
            //console.log('setting new session...\nresetting calendar events state...')

            // reset Session.Client
            Client && this.props.dispatch({
                type: 'SET_CLIENT',
                value: Client
            })
            // reset editingEvent
            let editingEvent = 
                this.state.editingEvent
                ? await this.eventFromId(this.state.editingEvent.eventId)   // take UPDATED version of editingEvent and reset
                : undefined
            //console.log('reset calendar editingEvent (should be updated)', editingEvent)
            
            let events = await Promise.resolve(Client.events && Client.events.length && Client.events.map((e, ei) => ({...e, id:(ei+1)})))
            this.setState({events: events || [], editingEvent})
        }
    }

    newEvent = async(schedulerData, slotId, slotName, start, end, type, item) => {
        //let sameDay = moment(start).format('L') === moment(end).format('L')
        if(window.confirm(
            this.sameDay({start, end})
            ? `Créer un nouvel évènement le ${moment(start).format('dddd DD MMM YYYY')} de ${moment(start).format('LT')} à ${moment(end).format('LT')} ?`
            : `Créer un nouvel évènement du ${moment(start).format('LLLL')} au ${moment(end).format('LLLL')} ?`
        )){
            let eventName = await Promise.resolve(prompt('Titre de l\'évènement'))
            let newFreshId = 1;
            schedulerData.events.forEach((item) => {
                if(item.id >= newFreshId)
                    newFreshId = item.id + 1;
            });

            let newEvent = await Promise.resolve({
                // the key 'id' is reserved for Scheduler to display events so we use 'eventId' to identify the event
                id: newFreshId,
                eventId: Utils.keyExtractor(),
                title: eventName || 'Nouvel Évènement',
                start: start,
                end: end,
                resourceId: slotId,
                bgColor: 'purple'
            })

            let served = await this.eventOperationsServer({
                request:'setEvent',
                props: {merge: newEvent}
            })

            if (served) {
                schedulerData.addEvent(newEvent);
                this.setState({
                    viewModel: schedulerData
                })
            }

        }
    }

    /**
     * @dev Set an event asthe editingEvent
     * @notice opens an edition modal on render
     */
    modifyEvent = (schedulerData, event) => {
        //console.log("You've just requested an event modification", event);
        // event eg.: { id: 2, start: "2019-09-09 12:30:00", end: "2019-09-10 23:30:00", resourceId: "r1", title: "I am resizable and movable", resizable: true, movable: true }
        this.setState({editingEvent: event})
    }

    /**
     * @dev Transfer an agenda event to a team member
     */
    transferAgendaEvent = (schedulerData, event) => {
        console.log('TODO: build transferAgendaEvent method', event)
    }

    removeAgendaEvent = async(schedulerData, event) => {
        if (window.confirm("Supprimer définitivement l'évènement ?")) {
            //console.log('removeAgendaEvent event', event)
            
            // remove remotely and reset Calendar
            let served = await this.eventOperationsServer({
                request: 'deleteEvent',
                props:  {eventId: event.eventId}
            })

            if (served) {
                // remove locally
                schedulerData.removeEvent(event);
                this.setState({
                    viewModel: schedulerData
                })
            }
        }
    }

    prevClick = (schedulerData)=> {
        schedulerData.prev();
        schedulerData.setEvents(this.state.events);
        this.setState({
            viewModel: schedulerData
        })
    }

    nextClick = (schedulerData)=> {
        schedulerData.next();
        schedulerData.setEvents(this.state.events);
        this.setState({
            viewModel: schedulerData
        })
    }

    onViewChange = (schedulerData, view) => {
        schedulerData.setViewType(view.viewType, view.showAgenda, view.isEventPerspective);
        schedulerData.setEvents(this.state.events);
        this.setState({
            viewModel: schedulerData
        })
    }

    onSelectDate = (schedulerData, date) => {
        schedulerData.setDate(date);
        schedulerData.setEvents(this.state.events);
        this.setState({
            viewModel: schedulerData
        })
    }

    closeOpenedEvent = () => this.setState({editingEvent: undefined})

    setEventColor = async(bgColor) => {
        let {editingEvent, viewModel} = this.state
        let merge = await Promise.resolve({...editingEvent, bgColor})
        let served = await this.eventOperationsServer({
            request: 'setEvent',
            props: {
                eventId: editingEvent.eventId,
                merge
            }
        })
        if (served) {
            viewModel.removeEvent(editingEvent)
            viewModel.addEvent(merge)
        }
    }

  
    updateEventStart = async(schedulerData, event, newStart) => {

        let msg = this.sameDay({...event, start: newStart})
        ? `Ajuster le début de l'évènement ${event.title} à ${moment(newStart).format('LT')} ?`
        : `Ajuster le début de l'évènement ${event.title} du ${moment(event.start).format('LLLL')} au ${moment(newStart).format('LLLL')} ?`
        if(window.confirm(msg)) {

            let merge = await Promise.resolve({...event, start: newStart})
            // ajust start remotely
            let served = await this.eventOperationsServer({
                request: 'setEvent',
                props: {
                    eventId: event.eventId,
                    merge
                }
            })

            if (served) {
                // ajust start locally
                schedulerData.updateEventStart(event, newStart);
                this.setState({
                    viewModel: schedulerData,
                    editingEvent: this.state.editingEvent ? merge : undefined
                })
            }

        }
            
    }

    updateEventEnd = async(schedulerData, event, newEnd) => {
        let msg = this.sameDay({...event, end: newEnd})
        ? `Ajuster la fin de l'évènement ${event.title} à ${moment(newEnd).format('LT')} ?`
        : `Ajuster la fin de l'évènement ${event.title} du ${moment(event.end).format('LLLL')} au ${moment(newEnd).format('LLLL')} ?`
        if(window.confirm(msg)) {
            
            let merge = await Promise.resolve({...event, end: newEnd})
            // ajust end remotely
            let served = await this.eventOperationsServer({
                request: 'setEvent',
                props: {
                    eventId: event.eventId,
                    merge
                }
            })

            if (served) {
                // ajust end locally
                schedulerData.updateEventEnd(event, newEnd);
                this.setState({
                    viewModel: schedulerData, 
                    editingEvent: this.state.editingEvent ? merge : undefined
                })
            }


        }
        
    }

    moveEvent = async(schedulerData, event, slotId, slotName, start, end) => {
        let msg = `Déplacer l'évènement du ${moment(start).format('LLLL')} au ${moment(end).format('LLLL')} ?`
        if(window.confirm(msg)) {
            //remote changes
            let merge = await Promise.resolve({...event, start, end})
            let served = await this.eventOperationsServer({
                request: 'setEvent',
                props: {
                    eventId: event.eventId,
                    merge
                }
            })

            // editingEvent state change
            if (served) {
                // local changes
                schedulerData.moveEvent(event, slotId, slotName, start, end);
                this.setState({
                    viewModel: schedulerData,
                    editingEvent: this.state.editingEvent ? merge : undefined
                })
            }
        }
    }

    onScrollRight = (schedulerData, schedulerContent, maxScrollLeft) => {
        if(schedulerData.ViewTypes === ViewTypes.Day) {
            schedulerData.next();
            schedulerData.setEvents(this.state.events);
            this.setState({
                viewModel: schedulerData
            });
    
            schedulerContent.scrollLeft = maxScrollLeft - 10;
        }
    }

    onScrollLeft = (schedulerData, schedulerContent, maxScrollLeft) => {
        if(schedulerData.ViewTypes === ViewTypes.Day) {
            schedulerData.prev();
            schedulerData.setEvents(this.state.events);
            this.setState({
                viewModel: schedulerData
            });

            schedulerContent.scrollLeft = 10;
        }
    }

    // onScrollTop = (schedulerData, schedulerContent, maxScrollTop) => {
    //     console.log('onScrollTop');
    // }

    // onScrollBottom = (schedulerData, schedulerContent, maxScrollTop) => {
    //     console.log('onScrollBottom');
    // }

    toggleExpandFunc = (schedulerData, slotId) => {
        schedulerData.toggleExpandStatus(slotId);
        this.setState({
            viewModel: schedulerData
        });
    }
}

export default connect(Utils.mapStateToProps)(withDragDropContext(Calendar))