import React from 'react'
import {Dropdown} from 'semantic-ui-react'

const tvaOptions = [
    {key: 'tva_20', text: '20', value: 20},
    {key: 'tva_10', text: '10', value: 10},
    {key: 'tva_5.5', text: '5,5', value: 5.5},
    {key: 'tva_2_1', text: '2,1', value: 2.1}
]

const TvaDropdown = ({onChange, defaultValue, value}) => <Dropdown inline
    onChange={(e, {value}) => onChange(value)}
    options={tvaOptions}
    defaultValue={defaultValue || tvaOptions[0].value}
    value={value}
/>

export default TvaDropdown