import React from 'react'
import { Route, Switch, Redirect, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import {Header, Image, Divider, Dimmer, Segment, Grid, Icon, Popup} from 'semantic-ui-react'

import Utils from './constants/Utils'
import Menu from './constants/Menu'


import AppLoader from './components/AppLoader'
import AppLogo from './components/AppLogo'

/* Views */
import Dashboard from './views/Dashboard'
import Login from './views/Login'
import Database from './views/Database'
import Crm from './views/Crm'
import Calendar from './views/Calendar'
import Realisations from './views/Realisations'
import Activities from './views/Activities'
import Products from './views/Products'
import Quotations from './views/Quotations'
import Bills from './views/Bills'
import Statistics from './views/Statistics'
import Team from './views/Team'
import Settings from './views/Settings'
import CornerIcons from './components/CornerIcons'
import Head from './components/Head'




const ERROR_404 = () => <Dimmer active>
    <Header inverted as='h2' icon>
        <Icon name='settings' />
        ERREUR #404
        <Header.Subheader>
            Cette page n'existe pas
        </Header.Subheader>
    </Header>
</Dimmer>

const managersRestrictedRoutes = 'database vehicules team bills quotations products statistics'


class Navigator extends React.Component {
    
    state = {
        loading: true,
        appBarConfig : {}        
    }

    appBarSetter = (_appBarConfig) => {
        console.log('AppBarSetter config request (to merge)', _appBarConfig)
        console.log('AppBarSetter NEW config', {...this.state.appBarConfig, ..._appBarConfig})

        this.setState({appBarConfig: {...this.state.appBarConfig, ..._appBarConfig}})
    }

    fireMsg = ({error, positive}) => {
        //this.setState({appBarConfig:{...this.state.appBarConfig, logError: typeof error === 'string' ? error : this.state.appBarConfig.logError, logPositive: typeof positive === 'string' ? positive : this.state.appBarConfig.logPositive}})
        this.setState({appBarConfig:{...this.state.appBarConfig, logError: error, logPositive: positive}})
    }
    /**
     * @dev Check if a session exist 
     *  - has cookie : check 
     *  - has NO cookie :
     */
    validateSession = async() => {
        const cookie = document.cookie
        const sessionId = cookie && cookie.split('=')[1]

        if (sessionId) {
            // get session from cookie
            let sessionRequest = await fetch(`${process.env.REACT_APP_API_URL}session`,
            {
                method:'POST',
                mode:'cors',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    sessionId
                })
            });

            // console.log('sessionRequest', sessionRequest)

            if (sessionRequest.status === 200) {
                const session = await sessionRequest.json()

                // console.log('session', session)
                session.fetchCredentials = await Promise.resolve({
                    email: session.Client.email,
                    hash: session.Client.hash,
                    id: session.Client.id
                })

                session.connected = true

                this.props.dispatch({
                    type: "SET_SESSION",
                    value: session
                })
                
                this.setState({loading: false})
            } else {
                this.setState({loading: false})
            }
        } else {
            // no cookie
            this.setState({loading: false})
        }
    }

  
    
    async componentDidMount() {
        const {validity, sessionId, connected} = this.props.session
        let _now = Date.now()

        global.fireMsg = this.fireMsg.bind(this)

        // check an eventual session reference within a cookie if props.session is not valid
        let dispatchCondition = await Promise.resolve(
            !sessionId
            || (validity <= _now && connected)
            || (!connected && validity > _now)
        )


        if (dispatchCondition) {
            this.validateSession()
        } else this.setState({loading: false})

        // set App Bar Setter
        let action = await Promise.resolve({
            type: 'APP_BAR_SETTER',
            value: this.appBarSetter
        })
        
        this.props.dispatch(action)
    }

    allowAccess = (path, Component) => {                            // Commercial restriction case
        const allowed = (
            (
                managersRestrictedRoutes.indexOf(path) >= 0             // Manager restriction case
                ? (
                    this.props.session.Client.licence === 'manager'         
                    ? true                                              // allow access
                    : false                                             // deny access
                ) : true                                                // route is unrestricted: allow access
            ) 
            || (
                path === 'database'     // databases access
                && this.props.session && this.props.session.Client && this.props.session.Client.access && (this.props.session.Client.access.privateDb || this.props.session.Client.access.publicDb) 
            )
            || (
                path === 'quotations'   // quotationRight
                && this.props.session 
                && this.props.session.Client 
                && this.props.session.Client.access 
                && this.props.session.Client.access.quotationRight
            )
            || (
                path === 'bills'        // billRight
                && this.props.session 
                && this.props.session.Client 
                && this.props.session.Client.access 
                && this.props.session.Client.access.billRight
            )
            || (
                path === 'products'     // productRight
                && this.props.session 
                && this.props.session.Client 
                && this.props.session.Client.access 
                && this.props.session.Client.access.productRight
            )
        )

        

        console.log(`** Access Allowed to ${path} **`, allowed)
        return(
            allowed
            ? Component
            : <Redirect to={{
                pathname: '/',
                state: {message: 'Vous devez détenir les autorisations nécessaires pour accéder à cette page'}
            }}/>
        )
        
    }

    toggleMenu = () => {
        this.setState({
            openMenu : !this.state.openMenu
        })
    }
  
    navMenu = () => <Popup 
        open={this.state.openMenu}
        onOpen={this.toggleMenu}
        onClose={this.toggleMenu}
        on="click"
        id="secondary_menu_items"
        trigger={<Icon className="overable" name="th" size="big" color="grey" />}
    >
        <div>
            {
            Menu.map((m) => <Popup.Content key={`navMenu_${m.view}`} style={{padding: 1}} onClick={this.toggleMenu}>
                <Link to={`/${m.view}`} style={{width: "100%", height: "100%", padding: "5px 10px 25px 10px"}}>
                    {m.icon}
                    {m.title}
                </Link>
                <Divider />
            </Popup.Content>)
            }
            
        </div>
        
    </Popup>

    /**
     * @dev Disconnect client
     * @param props {object} is the appBarSetter config object
     * @notice 
     * #used magic for this method is the previously used magic if its a redirection from expired session
     * #props MUST have a viewName prop in order to display the page name correctly (could be unrelated to currentView magic)
     * #resets controllers and searcher
    */
    setDisconnected = (props) => {
        this.props.appBarSetter({...props, controllers:'', searcher: '', bottomController: '', centerController: ''})
        
        this.props.dispatch({
            type: 'DESTROY_SESSION',
            value: props
        })
    }

    destroySession = async() => {
        const {sessionId} = this.props.session
        let {error} = await Utils.fetchApi({
            method: 'post',
            body: {sessionId},
            request: 'destroySession',
            client: this.props.session.fetchCredentials
        })
    
        if(!error) {
            this.setDisconnected({logPositive: 'Vous êtes déconnecté, à bientôt'})
        }
  }

    accountMenu = () => {
        const {avatar, username, email} = this.props.session.Client
        return (
            <Popup id="secondary_menu_items"
                    trigger={<CornerIcons
                    className="overable"
                    one={<Icon size="tiny"><Image size="mini" alt="" src={avatar} avatar /></Icon>}
                    two={<Icon corner size="mini" name="circle" color={this.props.session.connected ? 'green' : 'red'} />}
                />}
            >
                <div className="centered onTop">
                    <div className="padded3">
                        <Image avatar size="tiny" src={avatar} />
                        <Head
                            centered
                            title={username}
                            content={email}
                        />
                    </div>
                    <Divider />
                    <Popup.Content>
                        <span onClick={this.destroySession} className="redOnHover overable">
                            <Icon name="long arrow alternate right" />
                            Me déconnecter
                        </span>
                    </Popup.Content>

                </div>
            </Popup>
        )
    }



    render() {
        const {appBarConfig} = this.state
        const {viewName, logError, logPositive, controllers, searcher, centerController, bottomController} = appBarConfig || {}
        const {Client} = this.props.session 

        // console.log('NAVIGATOR Props', this.props)
        return(
            this.state.loading
            ? <AppLoader open={this.state.loading} title="Chargement de l'application" subTitle="veuillez patienter" />
            : (
                <div className="App">
                    <Segment className="tekoAll">
                        <Grid>
                            {/* LOGO */}
                            <Grid.Column width={2}>
                                <Link to="/"><AppLogo floated="left" className="padded1 overable" size="tiny" /></Link>
                               
                            </Grid.Column>
                            
                            {/* MSG */}
                            <Grid.Column width={12} className="centered">
                                {/* Error or Positive Message*/}
                                <Header as="h1"><p className={logError ? "centered txtM red" : "centered txtM green"}>{logError || logPositive}</p></Header>
                                
                            </Grid.Column>

                            {/* DASHBOARD SECONDARY MENU */}
                            <Grid.Column width={1} className="allSpace impFlex flexCenter">
                                {
                                    Client
                                    && this.navMenu()
                                }
                            </Grid.Column>
                            
                            {/* PROFILE - LOGOUT */}
                            <Grid.Column width={1} className="allSpace impFlex flexCenter">
                                {
                                    Client
                                    ? this.accountMenu()
                                    : <Header className="flexy">
                                        <Icon name="user secret" size="large"/>
                                        <span><Icon size="tiny" name="circle" color='red' /></span>
                                    </Header>
                                }
                            </Grid.Column>


                            <Divider className="appBarDivider" />
                            
                            {/* VIEWNAME + CONTROLLERS */}
                            <Grid.Column width={4}>
                                <Header as="h1"><p className="centeredFlex emOneTwo">#{viewName}</p></Header>
                                <span id="pageControllers" className="" style={{justifyContent: 'left'}}>
                                {
                                    controllers && controllers.length && controllers.map((c) => c)
                                }
                                </span>
                            </Grid.Column>

                            {/* SEARCHER + CENTER CONTROLLER */}
                            <Grid.Column width={12}>
                                {/* Searcher */}
                                <Grid.Row>
                                    {searcher}
                                </Grid.Row>
                
                                {
                                    centerController
                                    && <Grid.Row>
                                    {centerController}
                                    </Grid.Row>
                                }
                            </Grid.Column>

                            {/* BOTTOM CONTROLLER */}
                            {
                                bottomController
                                && <Grid.Column width={16}>
                                    {
                                        bottomController
                                    }
                                </Grid.Column>
                            }
                            
                        </Grid>

                    </Segment>

                    

                    {
                        this.props.session.validity > Date.now() 
                        ? <Switch>
                            <Route exact path="/" render={(props) => <Dashboard {...props} />} />
                            <Route path="/database" render={() => this.allowAccess('database', <Database accreditations={this.props.session.Client.access}/>)} />
                            <Route path="/crm" render={(props) => this.allowAccess('crm', <Crm {...props}/>)} />
                            <Route path="/agenda" render={() => <Calendar getEvents={() => this.props.session.Client.events}/>} />
                            <Route path="/realisations" render={() => <Realisations />} />
                            <Route path="/activities" render={(props) => <Activities {...props} />} />
                            <Route path="/products" render={() => this.allowAccess('products', <Products />)} />
                            <Route path="/quotations" render={(props) => this.allowAccess('quotations', <Quotations {...props} />)} />
                            <Route path="/bills" render={(props) => this.allowAccess('bills', <Bills {...props} />)} />
                            <Route path="/statistics" render={() => this.allowAccess('statistics', <Statistics />)} />
                            <Route path="/team" render={() => this.allowAccess('team', <Team />)} />
                            <Route path="/settings" render={() => <Settings />} />
                            {/* Not Found 404 */}
                            <Route component={ERROR_404} />
                        </Switch>
                        : <Login />
                    }
                </div>
            )
        )
    
    }

}


export default connect(Utils.mapStateToProps)(Navigator)