import React from 'react'
import { Map, Marker, Popup, TileLayer, Circle } from 'react-leaflet'
import { Loader } from 'semantic-ui-react'
import Utils from '../constants/Utils'
import {connect} from 'react-redux'

/**
 * ##PROPS
 *  - location [array] or (string: *address*): ex.: [49.6969875, 2.7905661] ou '80700, Roye'
 *  - popup (any): pop up to display when icon is clicked
 *  - height: height of the map container
 *  - width: width of the map container
 *  - onError (function) : called when an error occured during build process
 */
class SimpleMap extends React.Component {
    state = {
        loading: true
    }

    initMap = async(location) => {
        //console.log('init map with location', location)
        //console.log('SimpleMap props', this.props)
        // getCoordinates
        if (typeof location === 'string') { // location is a string
            let {error, response} = await Utils.fetchApi({
                method: 'post', 
                request : 'getCoordinates', 
                body: {location},
                client: this.props.fetchCredentials
            })
            //console.log('initMap error', error)
            //console.log('initMap response', response)
            error && this.props.onError()
            this.setState({loading: error ? true : false, coordinates: Utils.ofType(response) === 'object' ? [response.latitude, response.longitude] : [], location})
        } else {                            // location is a coords object
            this.setState({loading: false, coordinates: [location.latitude, location.longitude]}, location)
        }
    }

    componentDidMount() {
        const {location} = this.props
        this.initMap(location)
    } 

    async componentDidUpdate() {    // condition (this.props.location && this.state.location) recently added to avoid unexpected call on update (fixes a memory leak indicated by linter)
        if ((this.props.location && this.state.location) && this.props.location !== this.state.location) {
            // reset map with this.props.location if map is currently set with another location (state)
            await this.initMap(this.props.location)
        }

    }
    render() {
        const {popup, height, width, loading, coordinates} = this.state

        return(
            (loading || !coordinates)
            ? <Loader inverted></Loader>
            : (
                coordinates && coordinates.length 
                ? <Map center={coordinates} zoom={9} className={this.props.className} style={{height, width}}>
                    <TileLayer
                        url='https://{s}.tile.osm.org/{z}/{x}/{y}.png'
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors 2'
                    />
                
                    <Circle
                        center={[49.6969875, 2.7905661]}    // Cie position (80700 Roye)
                        fillColor='#007c51'
                        radius={60000}
                    />
                    <Marker position={coordinates}>
                        <Popup>{popup}</Popup>
                    </Marker>
                </Map>
                : <b className="negativeText centered allWidth block">{`La localisation ${typeof this.props.location === 'string' ? `pour "${this.props.location}" ` : ""}a échouée`}</b>
            )
        )
    }
    
}

export default connect(Utils.mapFetchCredentialsToProp)(SimpleMap)



