import React, {useState} from 'react'
import { Menu, Grid, Icon, Divider, Input, Image, Form, TextArea, Card } from 'semantic-ui-react'

import Utils from '../constants/Utils'
import Layout from '../constants/Layout'
import Crypto from '../constants/Crypto'

import ModifiableItem from '../components/ModifiableItem'
import AnimatedButton from '../components/AnimatedButton'
import Head from '../components/Head'
import ImageImport from '../components/ImageImport'

import {connect} from 'react-redux'
import moment from 'moment'

const Settings = (props) => {
    /* STATES */
    let [state, _setState] = useState({
      activeTab: "acc"
    })
    const setState = (m) => _setState({...state, ...m})

    if (!state.appBarSet) {
        // set client's password
        (async() => {
          let _pwd = await Crypto.decrypt({is_a_string: props.session.Client.hash})
          const pwd = JSON.parse(_pwd)
        

          // set app bar
          props.appBarSetter(
            {
              viewName: "Configurations",
              controllers: "",
              centerController: "",
              searcher: "",
              bottomController: 
                <div style={{marginTop: -40}}>
                  <Menu pointing secondary size="huge">
                    <Menu.Item
                    // Account settings tab
                      name='Compte'
                      active={state.activeTab === 'acc'}    
                      onClick={() => setState({activeTab: 'acc'})}
                    />
                    {
                      props.session.Client.licence === 'manager'
                      && <Menu.Item
                      // Application settings tab
                        name='Application'
                        active={state.activeTab === 'app'}
                        onClick={() => setState({activeTab: 'app'})}
                      />
                    }
                
                  </Menu>
                </div>
              
            }
          )

        setState({appBarSet: true, pwd})
      })()

        
        
    }

    const sendPwd = async() => {
      global.fireMsg({positive: <span>Nous vous renvoyons votre mot de passe <Icon name="cog" loading /></span>})
      let {error} = await Utils.fetchApi({
        method: 'GET',
        request: 'forgottenPwd',
        client: props.session.fetchCredentials

      })

      global.fireMsg({error: error && "Erreur lors de l'envoi de l'email, veuillez réessayer", positive: !error && 'Votre mot de passe vous a été envoyé par email'})
    }

    const toBullet = (str) => {
      let _s = ""
      for (let i in str) {
        _s += "•"
      }
      return _s
    }

    const userEditor = async({key, newValue}) => {
      let fetchin = await Utils.fetchApi({
        request: "editUser",
        body: {
          id: props.session.Client.id,
          [key]: newValue
        },
        client: props.session.fetchCredentials

      })
      return fetchin
    }

    const onUserEmailSet = async({error, response, key}) => {
      global.fireMsg({error: error && 'Erreur lors de la modification, veuillez recommencer', positive: !error && "L'email a été modifié"})
      if (!error) {
        props.session.Client = response
        let clientTml = await Promise.resolve(props.session.TML.filter((t) => t.id === response.id))
        
        let updatedTml = await Promise.resolve(props.session.TML.map((t) => t.id === response.id ? {...clientTml[0], [key]: response[key]} : t))
        props.session.TML = updatedTml
      } else console.error(error)
    }

    const removeRecruitment = async(timestamp) => {
      const recruitments = await Promise.resolve(props.session.Config.recruitments.filter((r) => r.addedOn !== timestamp))
      const {error} = await Utils.fetchApi({
        request: "mergeConfigFile", 
        body: {
          merge: {
            recruitments
          }
        },
        client: props.session.fetchCredentials
      })

      global.fireMsg({
        error: error && "L'offre d'emploi n'a pas correctement été supprimée, veuillez recommencer",
        positive: !error && "L'offre d'emploi a été supprimée"
      })

      !error && props.dispatch({
        type: "MERGE_SESSION", 
        value: {
          Config: {
            ...props.session.Config,
            recruitments
          }
        }
      })
      
    }

    const addRecruitment = async() => {
      const recruitment = {
        title: state.newRecruitmentTitle,
        details: state.newRecruitmentDetails,
        addedBy: props.session.Client.id, 
        addedOn: Date.now()
      }

      const recruitments = [
        ...(props.session.Config.recruitments || []),
        recruitment
      ]

      const {error} = await Utils.fetchApi({
        request: 'mergeConfigFile',
        body: {
          merge: {
            recruitments
          }
        },
        client: props.session.fetchCredentials
      })

      global.fireMsg({
        error: error && "L'ajout d'une nouvelle offre d'emploi a échoué",
        positive: !error && "Nouvelle offre d'emploi ajoutée au site internet"
      })

      if (!error) {

        props.dispatch({
          type: "MERGE_SESSION", 
          value: {
            Config: {
              ...props.session.Config,
              recruitments
            }
          }
        })

        setState({
          newRecruitmentDetails: undefined,
          newRecruitmentTitle: undefined
        })
      }
    }

    if (state.forceAvatarDisplayMode) setTimeout(() => setState({forceAvatarDisplayMode: false}))
    
    return(
        state.activeTab === 'app'
        ? <div className="padded1 tekoAll" style={{fontSize: '1.3em'}}>
          <Grid centered>
            <Grid.Column width={5}>
              <b className="red">Message d'accueil</b>
              <p>Ajoutez un message d'accueil sur votre site internet, celui-ci sera visible par les visiteurs à l'ouverture de la page</p>
            </Grid.Column>
            <Grid.Column width={1} textAlign="right">
              <Icon name="comment alternate outline" size="large" color="grey" />
            </Grid.Column>
            <Grid.Column width={8}>
              <ModifiableItem
                pair={{key: 'visitorsMsg', oldValue: props.session.Config && props.session.Config.visitorsMsg}}
                customEditionComponent={(changeHandler, newValue) => 
                  <Form><TextArea onChange={changeHandler} value={newValue}></TextArea></Form>
                }
                changeMethodAsync={async({newValue}) => {
                  let {error, response} = await Utils.fetchApi({
                    request: "mergeConfigFile",
                    body: {
                      merge : {visitorsMsg: newValue}
                    },
                    client: props.session.fetchCredentials

                  })
                  if (!error) {
                    props.session.Config = {...props.session.Config, visitorsMsg: newValue}
                  }

                  global.fireMsg({positive: !error && "Message visiteur enregistré", error: error && "L'enregistrement du message visiteur a échoué, veuillez recommencer"})

                  return {error}
                }}
              />
            </Grid.Column>

            <Grid.Column width={16}><Divider /></Grid.Column>

            <Grid.Column width={5}>
              <b className="red">Recrutement</b>
              <p>Gérez l'onglet "recrutement" de votre site internet</p>
            </Grid.Column>
            <Grid.Column width={1} textAlign="right">
              <Icon name="graduation" size="large" color="grey" />
            </Grid.Column>
            <Grid.Column width={8}>
                {/* new recruitment */}
                <Grid.Row>
                  <Head
                    size="small"
                    title={<span>Ajouter une offre d'emploi <span>{
                      (state.newRecruitmentTitle && state.newRecruitmentDetails)
                      && <Icon className="overable" name="check circle outline" color="teal" size="large" onClick={addRecruitment} />
                    }</span></span>}
                    titleClassName="silver"
                  />
                  <Head
                    size="small"
                    title="Poste recherché"
                    contentWrapperClassName="allWidth"
                    content={<Input
                      value={state.newRecruitmentTitle}
                      className="allWidth"
                      onChange={(e) => setState({newRecruitmentTitle: e.target.value})}
                    />}
                  />
                  <Head
                    size="small"
                    title="Votre annonce"
                    contentWrapperClassName="allWidth"
                    content={<Form><TextArea className="allSpace" value={state.newRecruitmentDetails} onChange={(e) => setState({newRecruitmentDetails: e.target.value})} /></Form>}
                  />
                </Grid.Row>
                <Divider />
                <Grid.Row>

                </Grid.Row>

                {/* all recruitments */}
                
                <Head
                  title="Offres d'emploi en ligne"
                  size="small"
                  titleClassName="silver"  
                />

                  <div className="flexy flexStart">{
                    (
                      props.session.Config.recruitments
                      && props.session.Config.recruitments.length
                      && props.session.Config.recruitments.map((r) => <Card className="padded1 nomargin">
                        <Card.Content header={<div><span className="txtM">{r.title}</span> <Icon className="redOnHover vibrate overable rightAligned margedLeft" name="close" onClick={() => removeRecruitment(r.addedOn)} /></div> } />
                        <Card.Content description={r.details} />
                        <Card.Content extra>
                          <div className="leftAligned">
                            <div><Icon size="tiny" name="calendar outline" /> ajouté le {moment(r.addedOn).format('DD-MM-YYYY')}</div>
                            <div><Icon size="tiny" name="user outline" /> ajouté par {Utils.teamMemberFromId(r.addedBy, {returnKey: "username"}, props.session.TML)}</div>
                          </div>
                        </Card.Content>
                      </Card>)
                      || <div className="warningText">Vous n'avez proposé aucune offre d'emploi sur votre site internet</div>
                    )
                  }</div>
            </Grid.Column>
          </Grid>
        </div>
        : state.activeTab === 'acc'
        && <div className="padded1 tekoAll" style={{fontSize: '1.3em'}}>
          <Grid centered>
            <Grid.Column width={5}>
              <b className="red textShadow">Identifiants</b>
              <p>Votre adresse email est utilisée comme identifiant par l'application</p>
            </Grid.Column>
            <Grid.Column width={1} textAlign="right">
              <Icon name="user outline" size="large" color="grey" />
            </Grid.Column>
            <Grid.Column width={8}>
              {/* email edition */}
              <ModifiableItem
                title="Adresse Email"
                pair={{key: "email", oldValue: props.session.Client.email}}
                changeMethodAsync={userEditor}
                onChangeMethodCalled={onUserEmailSet}
              />
              <Divider />
              
              {/* password edition */}
              <Grid>
                <Grid.Column width={8} className="noPaddingLeft">
                  <ModifiableItem
                    disabled={true}
                    validated={state.typeNewPassword && state.passwordChecked}
                    editIconSize={state.passwordChecked ? "large" : undefined}
                    title="Mot de passe actuel"
                    pair={{key: "passwordValidator", oldValue: toBullet(state.pwd)}}
                    renderValue={toBullet}
                    customEditionComponent={(changeHandler, value) => <Input type="password" value={value.indexOf('•') < 0 ? value : ""} onChange={changeHandler} />}
                    onModify={(e, {newValue, toggleEdit}) => {
                      if (newValue === state.pwd) {
                        toggleEdit()
                        setState({typeNewPassword: true, passwordChecked: true})
                      }
                      // return {error}
                    }}
                  />
                </Grid.Column>
                <Grid.Column width={8}>
                  {
                    state.typeNewPassword
                    && <ModifiableItem
                      title="Nouveau mot de passe"
                      validated={state.typeNewPassword && state.newPasswordSet}
                      pair={{key: "newPassword", oldValue: ""}}
                      renderValue={toBullet}
                      customEditionComponent={(changeHandler, value) => <Input type="password" value={value.indexOf('•') < 0 ? value : ""} onChange={changeHandler} />}
                      changeMethodAsync={async({newValue}) => {
                        setState({newPassword: newValue})
                        return {error: false}
                      }}
                    />
                  }
                </Grid.Column>
                <Grid.Column width={8} className={!state.typeNewPassword ? 'noPadding' : undefined}>{/* EMPTY LEFT SPACE */}</Grid.Column>
                <Grid.Column width={8} className={!state.typeNewPassword ? 'noPadding' : undefined}>
                    {
                      state.typeNewPassword
                      && <ModifiableItem
                        disabled={true}
                        validated={state.newPasswordSet}
                        title="Confirmer nouveau mot de passe"
                        pair={{key: "password", oldValue: ""}}
                        renderValue={toBullet}
                        customEditionComponent={(changeHandler, value) => <Input error={state.newPwdValidationError} type="password" value={value.indexOf('•') < 0 ? value : ""} onChange={changeHandler} />}
                        onModify={async(e, {newValue}) => {

                          // if newPassword === newValue ask permission and modify password
                          if (state.newPassword && state.newPassword === newValue && !state.newPasswordSet) {
                            setState({newPasswordSet: true})
                            if (window.confirm('Valider le changement de mot de passe ?')) {
                              const hash = await Crypto.encrypt(newValue, true)
                              let {error, response} = await Utils.fetchApi({
                                request: "mergeClient",
                                body: {
                                  id: props.session.Client.id,
                                  hash
                                },
                                client: props.session.fetchCredentials

                              })
                              global.fireMsg({error: error && "Erreur lors de la modification de mot de passe, veuillez réessayer", positive: !error && "Nouveau mot de passe enregistré, veuillez vous reconnecter"})
                              if(!error) {
                                // close edition
                                setState({typeNewPassword: false, newPasswordSet: false, newPwdValidationError: false, passwordChecked: false})
                                
                                // reset password locally
                                props.dispatch({
                                  type: "SET_SESSION",
                                  value: {
                                    Client: {
                                      ...props.session.Client, 
                                      hash
                                    },
                                    fetchCredentials: {
                                      ...props.session.fetchCredentials,
                                      hash
                                    }
                                  }
                                })

                              }
                            }
                          }

                          // set validation input to error if to long else, reset to normal
                          else setState({newPwdValidationError: newValue && state.newPassword && newValue.length > state.newPassword.length})
                          
                        }}

                      />
                    }
                   
                </Grid.Column>
              </Grid>
              <Divider />
              <Head
                textAlign="left"
                title="Mot de passe oublié"
                titleClassName="silver"
                titleStyle={{fontSize: ".6em"}}
                content={
                  <AnimatedButton 
                    size="small"
                    className="greenAppButton" 
                    visible={<span style={{fontSize: '1.3em'}}>Reçevoir mon mot de passe par email</span>}
                    invisible={<Icon name="envelope" size="large" />} 
                    onClick={sendPwd} 
                  />
                }
              />
          </Grid.Column>

          {/*Contact*/}
          <Grid.Column width={16}><Divider /></Grid.Column>
          <Grid.Column width={5}>
              <b className="red">Contact</b>
              <p>Le numéro de téléphone reste privé et est communiqué aux managers uniquement</p>
          </Grid.Column>
          <Grid.Column width={1} textAlign="right">
            <Icon name="mobile" size="large" color="grey" />
          </Grid.Column>
          <Grid.Column width={8}>
            
                <ModifiableItem
                  title="Numéro de téléphone"
                  pair={{key: "tel", oldValue: props.session.Client.tel}}
                  changeMethodAsync={async({newValue}) => {
                    let {error} = await Utils.fetchApi({
                      request: 'editUser',
                      body: {
                        id: props.session.Client.id,
                        tel: newValue
                      },
                      client: props.session.fetchCredentials

                    })

                    global.fireMsg({
                      error: error && "Erreur lors de l'enregistrement du numéro de téléphone, veuillez recommencer",
                      positive: !error && 'Nouveau numéro de téléphone enregistré'
                    })

                    if(!error) props.session.Client.tel = newValue

                    return ({error})
                  }}
                />

          </Grid.Column>

          <Grid.Column width={16}><Divider /></Grid.Column>

          <Grid.Column width={5}>
              <b className="red">Avatar</b>
              <p>Votre avatar n'est pas utilisé sur le site internet mais reste néanmoins visible par vos collègues sur l'intranet</p>
          </Grid.Column>
          <Grid.Column width={1} textAlign="right">
            <Icon name="image outline" size="large" color="grey" />
          </Grid.Column>
          <Grid.Column width={8}>
            <ModifiableItem
              title="Changer l'avatar"
              forceDisplayMode={state.forceAvatarDisplayMode}
              pair={{key:"avatar", oldValue:props.session.Client.avatar}}
              renderValue={(value) => <Image className="whiteBkg centered" style={Utils.responsiveDimensions({height:Layout.height * 0.20})} src={value} ui={false} />}
              disabled={true}
              customEditionComponent={(changeHandler, value) => 
                <ImageImport 
                  onSelect={async({src, rejected}) => { // fire result
                    // console.log('Image Import src', src)
                    global.fireMsg({error: rejected, positive: !rejected && "1 fichier téléchargé"});
                    const {error, response: uclient} = await userEditor({key:"avatar", newValue:src});
                    if(!error) { 
                      // console.log('NEW avatar', uclient.avatar)
                      props.session.Client.avatar = uclient.avatar
                      let uTml = await Promise.resolve(props.session.TML.map((t) => (t.id === props.session.Client.id ? ({...t, avatar: uclient.avatar}) : t)))
                      props.session.TML = uTml

                      setState({forceAvatarDisplayMode: true})

                      global.fireMsg({positive: "L'avatar a été mis à jour"})
                      //changeHandler({target: {value: src}})
                    } else {
                      console.error('userEditor error', error);
                      global.fireMsg({error: "Erreur lors de la mise à jour de l'avatar, veuillez recommencer"})
                    }
                    
                  }}
                />
              }
            />
          </Grid.Column>

        </Grid>
      </div>
    )
}

export default connect(Utils.mapStateToProps)(Settings)