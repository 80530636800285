import React from 'react'
import { Icon, Modal } from 'semantic-ui-react'

const AppLoader = ({open, title, subTitle}) => <Modal className="allHeight" size="large" open={open}>
    <div className="flexy flexCenter allHeight" style={{flexDirection: "column"}}>
        <Icon color="grey" size="huge" name="cog" loading={true} />
        <b className="black">{title}</b>
        <div className="silver">{subTitle}</div>    
    </div>
</Modal>

export default AppLoader