import React from 'react';
import { Icon, Dropdown } from 'semantic-ui-react';

const Priorities = [
    { name: 'Haute', color: 'red', value: 'high' },
    { name: 'Moyenne', color: 'orange', value: "medium" },
    { name: 'Basse', color: 'green', value: "low" },
]

/**
 * 
 * @param {*} props
 * ##PROPS 
 *  - selected (enum) : show selected priority "high", "medium" or "low"
 *  - onSelect (function) : method called each time a priority is selected *(e,{value})=>{}*
 *  - changeHandler (function)
 *  - style
 */
const PrioritySelection = (props) => {
    const { selected, onSelect, changeHandler, style } = props
    const prioritiesMap = () => Priorities.map((p) => ({key: p.value, value: p.value, text: <span><Icon name="circle" color={p.color} /> {p.name}</span>}))
    const prio = Priorities.filter((p) => p.value === selected)[0]
    return <Dropdown 
        style={style || undefined}
        //value={selected || undefined}
        text={<span><Icon name="circle" color={prio.color} /> {prio.name}</span>}
        fluid 
        search 
        selection 
        placeholder='Séléctionnez une priorité' 
        options={prioritiesMap()} 
        onChange={changeHandler ? (e, {value}) => changeHandler({target: {value}}) : onSelect}    
    />
   
}

export default PrioritySelection;