
import axios from 'axios'

const Utils = {
    mapStateToProps : (state) => {
        return state
    },
    mapSessionToProps : (state) => {
        return ({
            session: state.session || {}
        })
    },
    mapFetchCredentialsToProp : (state) => {
        return({
            fetchCredentials: state.session.fetchCredentials
        })
    },
    
    /** Return Dates with format dd/mm/yyyy from new Date() method
    * @param da the date to format
    */
   formatedDate : (da, onlyDay) => {
        let reformatTimeField = (tField) => {
            return tField < 10 ? '0' + tField : tField;
        }
        let d = da.getDate();
        let m = da.getMonth() + 1; // months goes 0-11
        let y = da.getFullYear();

        var H = ' à ' + reformatTimeField(da.getHours()) + ' H ' + reformatTimeField(da.getMinutes());
        let M = reformatTimeField(m);
        let D = reformatTimeField(d);
        return (D + '/' + M + '/' + y + (onlyDay ? '' : H));
    },

    // return real type for objects 
    ofType : (elem) => {
        return(
            ((_e) => {

                let t = typeof _e 
                //console.log('\n- ofType receives -\n_e: %s\ntypeof _e: %s\n', _e, t)


                if (!_e) 
                    return 'undefined' 

                else if (t === 'object') 
                    return typeof _e.length !== 'undefined' ? 'array' : 'object'

                else return t

            })(elem)
        )
    },

    hasLen : str => (str && str.length) ? true : false,


    majFirstChar : (str) => str ? (str[0].toUpperCase() + str.slice(1)) : str,


    isJSON: (supJ) => {
        try {
            JSON.parse(supJ);
            return true;
        } catch(err) {
            return false;
        }
    },

    cutText : ($text) => {
        let $maxPos = 16, $lastPos;
      
        if ($text.length > $maxPos)    
        {
            $lastPos = ($maxPos - 3) - $text.length;
            $text = `${$text.slice(0, $lastPos)}…`
            return $text;
        }
        else return $text;
    },

    responsiveDimensions : ({height, width, value}) => (
        {
            display: "block",
            width: width ? `${width}px` : "auto",
            height: height ? `${height}px` : 'auto',
            objectFit: "contain"
        }

    ),

    /**
     * Fetch Amc Leblanc API with a specific request, method, body(post datas)
     * param options {object}
     *  - mockGlobal : {session:{Client: {email, hash, id}}} (will replace props.session if redux store is not set at usage time) (used by App.js on 'awake' request)
     *  - acceptHeader (string) : set an Accept header 
     */
    fetchApi : async({body, request, method, client}, options) => {
        //console.log('fetchApi', {body, request, method, options})
        // console.log('FETCHING ', request)
        const dateNow = Date.now()
        const Client = client || (options && options.mockGlobal && options.mockGlobal.session.Client) || {}
        const {email, hash, id: clientId} = Client || {}
        
        const isPost = await Promise.resolve((method && method.toLowerCase() === 'post') || !method); 
        //console.log('...body', {...body})
        let fetchOptions = await Promise.resolve(
            {
                method: method || 'POST',
                mode: 'cors',
                headers: isPost
                    ? {
                    Accept: (options && options.acceptHeader) || 'application/json',
                    'Content-Type': 'application/json',
                    }
                    :undefined,
    
                body: isPost 
                    ? JSON.stringify({...body, addedOn: dateNow, addedBy: clientId})
                    : undefined
            }
        )
        let url = await Promise.resolve(`${process.env.REACT_APP_API_URL}api?request=${request}&credentials=${(options && options.caseSensitive) ? email : email.toLowerCase()}::${hash}`)
        //console.log('fetch url', url)
        let fetching = await fetch(url, fetchOptions)
        //console.log('[fetchApi] fetching', fetching)

        let error = await Promise.resolve(fetching.status !== 200 ? fetching.text() : false)
       
        let response = !error && await Promise.resolve(fetching.json())

        //console.log('response', response)
        error && console.error(`${request} fetch ERROR (server returned status ${fetching.status})`, error)

        return (
            {response, error}
        );
    },

    customerFromName : (name, customers) => {
        return new Promise((resolve) => {
            
            if (customers && customers.length) {
                for (var customer of customers) {
                    //console.log('customer', customer.name)
                    if (customer.name === name)
                    resolve(customer);
                    else if(customer === customers[customers.length-1])
                    resolve(undefined)
                }
            } else resolve(undefined)
        })
    },

    random : (mod) => Math.floor(Math.random() * (mod || 10000000000)),

    keyExtractor : () => Utils.random().toString(),

    keyExtractorAsync : async(len) => {
        let Len = len || 12;
        let digits = 'kQ1q2Y3r4J5RNnGg67t8j9TFf0dDy_'
        let rand = ''
        for (let i = 0; i < Len; i++) {
            let r = (Math.floor(Math.random() * 100)%digits.length) 
            rand += digits[r]
            
            if (i === Len - 1)
                return rand
        }    
    },

    toDataUrl : (file) => {
        return new Promise((resolve) => {
            var xhr = new XMLHttpRequest();
            xhr.responseType = 'blob';
            xhr.onload = function() {
                var reader = new FileReader();
                reader.onload = function() {
                resolve(reader.result);
                }
                reader.readAsDataURL(xhr.response);
            };
            xhr.open('GET', file);
            xhr.send();
        })
    },

    blobToFile : (blobData, fileName) => {
        const fd = new FormData();
        fd.set('a', blobData, fileName);
        return fd.get('a');
    },

    toFile : (dataUrl, fileName) => {
        return new Promise(async(resolve) => {
            console.log('Utils.toFile called')
            let blob = await new Promise(function(res, rej) {
                try {
                    var xhr = new XMLHttpRequest();
                    xhr.open("GET", dataUrl);
                    xhr.setRequestHeader('Accept', '*')
                    xhr.setRequestHeader('Origin', '*')
                    xhr.responseType = "blob";
                    xhr.onerror = function() {rej("Network error.")};
                    xhr.onload = function() {
                        if (xhr.status === 200) {res(xhr.response)}
                        else {rej("Loading error:" + xhr.statusText)}
                    };
                    xhr.send();
                }
                catch(err) {rej(err.message)}
            });
            console.log('blob'+ blob)
            let file = await Promise.resolve(Utils.blobToFile(blob, fileName))
            console.log('toFile file', file)
            resolve(file)

        })
    },

    storeFile : ({b64, originalname, file: f}, fetchCredentials) => {
        return new Promise(async(resolve, reject) => {
            const {email, hash} = fetchCredentials
            console.log('storeFile f', f)
            // build file from b64
            let file = f || await Utils.toFile(b64, originalname) // got from last database file selection
            //console.log('file (from b64)', file)

            // prepare formData object
            let fd = new FormData()
            fd.append("fileData", file)
            fd.append('returnKey', 'location')
            console.log('\n ---> axios request....\n')
            axios
            .post(`${process.env.REACT_APP_API_URL}api?credentials=${email.toLowerCase()}::${hash}&request=storeFile`, fd)
            .then(res => {
                console.log('axios response', res)
                resolve(res.data)
            })
            .catch(err => {
                console.error('storeLimitedSource ERROR',err)
                reject(err)
            });
        })
    },

    isValidSession : (session) => {
        
        //console.log('[isValidSession] checking validity locally..')
        return(
            session
            ? session.validity && session.validity > Date.now()
            : false
        )
    },

    /**
     * @params returnOptions options are : returnKey or returnMountedCV
     */
    teamMemberFromId : (clientId, returnOptions, TML) => {
    
        const {returnKey, returnMountedCV} = returnOptions || {}
        
        let TM = 'n/c';
        //if(!TML || !clientId) return TM;
        
        TML && TML.map((tm, tmi) => {
            
            if (tm.id === clientId) {
                //console.log('tm found !', tm[returnKey])
                TM = returnKey ? tm[returnKey] : (returnMountedCV ? (tm.username+', '+tm.licence+' '+tm.position+' chez AMC Leblanc') : tm);
            }

            return tm;
        })

        return TM;
        // get TML
    },

    /**
     * 
     */
    activityFromId : async(activityId, _activities, credentials) => {
        const activity = await new Promise(async(_acts) => {
                const {error, response} = 
                _activities
                ? {
                    response: _activities
                }
                : await Utils.fetchApi({
                    request: "activities",
                    method: "GET", 
                    client: credentials // only if !_activities is provided
                })
                if (error) {
                    console.error('activityFromId fetch activities error', error)
                    _acts({})
                } else {
                    let act = await Promise.resolve(response.filter((a) => 
                        a.activityId === activityId
                    ))

                    _acts((act && act.length) ? act[0] : {})
                }
            
        })
        return activity
        // let activity = await new Promise((thisAct) => {
        //     activities.forEach((a) => {
        //         if (a.activityId === activityId)
        //             thisAct(a)
        //     })
        // })
        
        //return activity
    },

    currentView : (connected) => {
        const {pathname: path} = window.location
        return(
            path === '/'
            ? (
                connected 
                ? 'dashboard'
                : 'login'
            ) : path.slice(1, path.length)
        ) 
    },

    currentMagic : (connected) => {
        return process.env[`REACT_APP_${Utils.currentView(connected).toUpperCase()}_MAGIC`]
    },

    spliceFromTo : ({data, from, to, include}) => {
        let topIndex = data.indexOf(from)
        let endIndex = data.indexOf(to)
      
        return (
            !include
            ? data.slice(topIndex + from.length, endIndex + to.length-1)
            : data.slice(topIndex, endIndex+to.length)
        )
    },

    parseAddress : (address) => {
        let index, i=0;
        let couldBeCP = 0; 
        for(let d of address) {
          i++;
          let aN = !isNaN(parseInt(d))
        
          index = (aN && !index) ? i : (aN ? index : undefined)
          couldBeCP = aN ? (couldBeCP+1) : 0
        if (couldBeCP === 5) {
      
            return({
              street: address.slice(0, index-1), 
            cp: address.slice(index-1)
          })
          
        } else if (i === address.length-1) return({street: address})
        
      }
    },
    // converts 1 to 00001
    pad : (_str, _pad) => {
        var pad = _pad ? _pad.toString() : "000000"
        let str = _str.toString()
        return(pad.substring(0, pad.length - str.length) + str)
        
    }

}

export default Utils