import React, { useState } from 'react';
// constants
import { Card, Grid, Image, Icon, Divider, Input, Statistic, Checkbox, Segment, Button } from 'semantic-ui-react';
import Layout from '../constants/Layout';
import Utils from '../constants/Utils';
import Colors from '../constants/Colors'

// components
import AppLoader from '../components/AppLoader';
import Head from '../components/Head';
import AnimatedButton from '../components/AnimatedButton';
import CornerIcons from '../components/CornerIcons'
import FileSelector from '../components/FileSelector'

import {connect} from 'react-redux'


// set typeof licence
const LicencesSelector = ({onChange, value}) => <Grid>
    <Grid.Column width={3}>
        <Checkbox 
            toggle 
            checked={value === 'manager' || value === true} // 'manager' when already set, 'true'(bool) if new setting
            onChange={
                (e, {checked}) => onChange({target: {value: checked}})
            }
        />
    </Grid.Column>
    <Grid.Column width={13}>
        <Segment style={{fontSize:'1.2em', marginTop: -30}}>
            <div className="mouseGrey">Si <b>l'accès manager</b> est activé, le nouvel utilisateur aura accès à la création/consultation des documents comptables, aux statistiques ainsi qu'aux fichiers clients des autres utilisateurs</div>
        </Segment>
    </Grid.Column>
</Grid>


// set private db access
const DatabasesAccessSelectors = ({onChange, value}) => <Grid>
    <Grid.Column width={8}>
        <Segment style={{fontSize:'1.2em'}}>
            <b>Accès à une base de données privée</b>
            <p className="mouseGrey">Si l'accès est autorisé, l'utilisateur aura accès à une base de données privée qui lui sera réservé</p>
            <Checkbox 
                toggle 
                checked={typeof value !== 'undefined' ? value.privateDb : true}
                onChange={
                    (e, {checked}) => onChange({target: {value: {privateDb: checked}}})
                }
            />
        </Segment>
    </Grid.Column>

    <Grid.Column width={8}>
        <Segment style={{fontSize:'1.2em'}}>
            <b>Accès à la base de données entreprise</b>
            <p className="mouseGrey">Si l'accès est autorisé, l'utilisateur aura accès à la base de données entreprise commune</p>
            <Checkbox 
                toggle 
                checked={typeof value !== 'undefined' ? value.publicDb : true}
                onChange={
                    (e, {checked}) => onChange({target: {value: {publicDb: checked}}})
                }
            />
        </Segment>
    </Grid.Column>
</Grid>

// set quotations and bills creation rights
const DocumentsAccessSelectors = ({onChange, value}) => <Grid>
    <Grid.Column width={8}>
        <Segment style={{fontSize:'1.2em'}}>
            <b>Création de devis</b>
            <p className="mouseGrey">Si l'accès est autorisé, l'utilisateur pourra visualiser les devis existants et en créer de nouveaux</p>
            <Checkbox 
                toggle 
                checked={typeof value !== 'undefined' ? value.quotationRight : true}
                onChange={
                    (e, {checked}) => onChange({target: {value: {quotationRight: checked}}})
                }
            />
        </Segment>
    </Grid.Column>

    <Grid.Column width={8}>
        <Segment style={{fontSize:'1.2em'}}>
            <b>Création de facture</b>
            <p className="mouseGrey">Si l'accès est autorisé, l'utilisateur pourra visualiser les factures existantes et en créer de nouvelles</p>
            <Checkbox 
                toggle 
                checked={typeof value !== 'undefined' ? value.billRight : true}
                onChange={
                    (e, {checked}) => onChange({target: {value: {billRight: checked}}})
                }
            />
        </Segment>
    </Grid.Column>
</Grid>

const ProductsAccessSelectors = ({onChange, value}) => <div className="padded1">
    <Segment style={{fontSize:'1.2em'}}>
        <b>Droit à la création et consultation d'articles ou de services</b>
        <p className="mouseGrey">Si le droit est donné, l'utilisateur pourra visualiser les articles/services existants et en créer de nouveaux</p>
        <Checkbox 
            toggle 
            checked={typeof value !== 'undefined' ? value.productRight : true}
            onChange={
                (e, {checked}) => onChange({target: {value: {productRight: checked}}})
            }
        />
    </Segment>
</div>

const RealisationsAccessSelectors = ({onChange, value}) => <div className="padded1">
    <Segment style={{fontSize:'1.2em'}}>
        <b>Droit à la création et à la mise en ligne de réalisations</b>
        <p className="mouseGrey">Si le droit est donné, l'utilisateur pourra créer de nouvelles réalisations, les valider et les mettre en ligne</p>
        <Checkbox 
            toggle 
            checked={typeof value !== 'undefined' ? value.realisationRight : true}
            onChange={
                (e, {checked}) => onChange({target: {value: {realisationRight: checked}}})
            }
        />
    </Segment>
</div>


const Fields = [
    {
        title: "Nom Prénom",
        editionRestriction: true,
        key: "username",
        icon: "id card outline"
    },
    {
        title: "Adresse Email",
        editionRestriction: true,
        key: "email",
        icon: "at"
    },
    {
        title: "Poste occupé",
        key: "position",
        icon: "suitcase"
    },
    {
        title: "Téléphone",
        key: "tel",
        icon: "mobile"
    },
    {
        title: 'Avatar',
        key: 'avatar',
        icon: 'id badge outline',
        component: (props) => <FileSelector {...props} />
    },
    {
        title: 'Accréditation',
        key: 'licence',
        icon: 'barcode',
        component: (props) => <LicencesSelector {...props} />
    },
    {
        title: 'Accès aux bases de données',
        key: 'access',
        icon: 'database',
        component: (props) => <DatabasesAccessSelectors {...props} />,
        merge: true
    },
    {
        title: 'Accès aux documents sensibles',
        key: 'access',
        icon: 'file alternate outline',
        component: (props) => <DocumentsAccessSelectors {...props} />,
        merge: true
    },
    {
        title: 'Accès aux articles / services',
        key: 'access',
        icon: 'file alternate outline',
        component: (props) => <ProductsAccessSelectors {...props} />,
        merge: true
    },
    {
        title: 'Droit à la mise en ligne de réalisations',
        key: 'access',
        icon: 'file alternate outline',
        component: (props) => <RealisationsAccessSelectors {...props} />,
        merge: true
    }
]

const Team = (props) => {
    // console.log('Team props', props)
    /**********************************[ INITIALIZATION ]********************************/
    let [tml, setTml] = useState(undefined)
    let [state, _setState] = useState({
        newTM: {},
        isManager: props.session && props.session.Client && props.session.Client.licence === 'manager'
    })
    // set missing fields to red
    let [missing, _setMissing] = useState({})
    const setMissing = (mergeObj) => {
        mergeObj && _setMissing({...missing, ...mergeObj})
    }

    const setState = (mergeObj) => {
        mergeObj && _setState({...state, ...mergeObj})
    }
    //const responsiveDimensions = Utils.responsiveDimensions({height:Layout.height * 0.3})
    // // console.log('responsiveDimensions', responsiveDimensions)
        // set app bar once for the lifetime of the component
        if (!state.appBarSet) {
            setState({appBarSet:true})
            let backController = <Icon key={'tm_bc'} className="overable" name="chevron left" onClick={() => setState({addTeamMember: false, editionMode: false, appBarSet: false, newTM: {}})} />
            props.appBarSetter(
                {
                    viewName: "Gestion des utilisateurs", 
                    controllers: (
                        state.isManager && !state.editionMode
                        ? [
                            backController,
                            <CornerIcons className="margedLeft" key={Utils.keyExtractor()} onClick={() => setState({appBarSet: false, addTeamMember: true})} one="user outline" two="add" colorTwo="teal" />
                        ]
                        : [backController]
                    ),
                    centerController: ( 
                        state.addTeamMember 
                        ? <div style={{marginRight: Layout.width/16}}><Statistic size="mini" className="allWidth centered">
                            <Statistic.Label><Icon name="handshake outline" size="huge" /></Statistic.Label>
                            <Statistic.Value>Enregistrement d'un Nouvel Utilisateur</Statistic.Value>
                        </Statistic></div> 
                        : "" 
                    ),
                    searcher: "",
                    bottomController: ""
                }
            )
        } //else // console.log('---> Team app bar set')

        if (!tml) 
            setTml((props.session && props.session.TML) || {})
        //else // console.log('---> tml loaded', tml)



    /*************************************[ METHODS ]***********************************/

    const createNewTM = async() => {
        const {newTM, editionMode} = state
        const {access, licence} = newTM
        let newStaffMember = await Promise.resolve({
            ...newTM,
            licence: editionMode ? ((licence && licence===true) ? 'manager' : (licence || 'agent')) : (licence ? 'manager' : 'agent'),
            access: {   // !access: give access by default
                privateDb: (!access || typeof access.privateDb === 'undefined') ? true : access.privateDb,
                publicDb: (!access || typeof access.publicDb === 'undefined') ? true : access.publicDb,
                quotationRight: (!access || typeof access.quotationRight === 'undefined') ? false : access.quotationRight,
                billRight: (!access || typeof access.billRight === 'undefined') ? false : access.billRight,
                productRight: (!access || typeof access.productRight === 'undefined') ? false : access.productRight,
                realisationRight: (!access || typeof access.realisationRight === 'undefined') ? false : access.realisationRight
            }
        })
        // console.log('newStaffMember', newStaffMember)
        let freeUsername = await new Promise(async(canUse) => {
            if (editionMode) {
                canUse(true)
            } else {
                let un = newStaffMember.username
                if (un) {
                    let sameUsername = await Promise.resolve(tml.filter((_tm) => _tm.username === un))
                    // console.log('sameUsername', sameUsername)
                    // console.log(sameUsername.length)
                    canUse(!sameUsername.length)
                } else canUse(false)
            }
        })
        // console.log('freeUsername', freeUsername)


        if (freeUsername) {

            // check missing required fields
            let cleanRequest = await new Promise(async(cleanReq) => {
                let required = editionMode ? ["position", "email"] : ["username", "position", "email"]
                let mssg = {}
                

                required.forEach(async(r, ri) => {
                    if (typeof newStaffMember[r] === 'undefined' || !newStaffMember[r]) {
                        mssg[r] = true;
                        //// console.log(`setMissing({[${r}]: true})`)
                    }
                    setTimeout(() => {
                        if (ri === required.length-1) { // 2: last loop
                            // console.log('mssg', mssg)
                            // console.log('missing', missing)
                            cleanReq(mssg)
                        }
                    })
                })
                    
            })

            // console.log('cleanRequest', cleanRequest)

            if (Object.keys(cleanRequest).length < 1) {

                let {error, response} = await Utils.fetchApi({
                    request: editionMode ? 'editUser' : 'createUser',
                    body: newStaffMember,
                    client: props.session.fetchCredentials

                }) 
                if (error) {
                    console.error('create new user Error', error)
                }
                else {
                    let replaced = await Promise.resolve(
                        editionMode
                        && tml.map((t) => t.id === newTM.id ? response : t)
                    )
                    setTml(
                        editionMode
                        ? replaced
                        : [...tml, response]
                    )
                    setState({addTeamMember: false, appBarSet: false, editionMode: false})
                }
                global.fireMsg({error: error && "Il y a eu une erreur lors de l'enregistrement, veuillez recommencer", positive: !error && (editionMode ? 'Modifications effectuées' : 'Nouvel utilisateur enregistré')})
            }
            else {
                global.fireMsg({error: 'Des champs sont manquants'})
               
                setMissing(cleanRequest)
                
            }
        }

        else {
            global.fireMsg({error: "Ce nom d'utilisateur n'est pas disponible, veuillez en choisir un autre"}) 
            alert("Nom d'utilisateur non disponible")
            setMissing({username: true})
        }
        
    }


    const removeTM = async(tm) => {
        if (state.isManager && tm.id && window.confirm(`Supprimer définitivement l'utilisateur ${tm.username} ainsi que tous les fichiers contenus dans sa base de données personnelle ?`)) {
            // console.log('removing user ', tm)
            let {error, response: removed} = await Utils.fetchApi({
                request: 'removeUser',
                body: {
                    clientId: tm.id
                },
                client: props.session.fetchCredentials

            })

            if (removed) {
                let cleanList = await Promise.resolve(tml.filter((m) => m.id !== tm.id))
                global.fireMsg({error: error && "il y a eu une erreur lors de la suppression de l'utilisateur", positive: !error && `L'utilisateur ${tm.username} a été définitivement supprimé` })
                
                setTml(cleanList)
                setState({})
            }
        } else console.error(`Il y a eu une erreur: ${state.isManager ? (tm.id ? "Vous avez refusé la suppression" : "L'utilisateur ne peux pas être supprimé en raison d'une erreur interne") : "vous n'êtes pas autorisé à modifier un utilisateur"}`)
    }
     

    /************************************[ RENDERERS ]**********************************/
    const accessIcons = (access, licence) => 
        <span className="rightAligned">  {/* !access = client.access has not been defined : access is authorized */}
            { licence === 'manager' && <Icon name="certificate" color="yellow" /> }
            { (!access || typeof access.privateDb === 'undefined' || access.privateDb) && <Icon name="database" color="teal" />}
            { (!access || typeof access.publicDb === 'undefined' || access.publicDb) && <Icon name="database" color="red" />}
        </span>
    

    const renderTeamMember = (tm) => <Grid.Column key={Utils.keyExtractor()} width={4}>
         <Card>
            <Image className="whiteBkg" style={Utils.responsiveDimensions({height:Layout.height * 0.25})} src={tm.avatar} ui={false} />
            <Card.Content>
                <Card.Header className="centered">{tm.username}</Card.Header>
                <Card.Meta>
                <div className="flexy">
                    <div className="flexy" style={{flex: .5, justifycontent: 'left'}}>
                        {tm.position}
                    </div>
                    <div className="flexy flexEnd" style={{flex: .5}}>
                        { accessIcons(tm.access, tm.licence) }
                    </div>
                </div>
                    {/* <span className='silver block allWidth'>{tm.position}<span className="margedLeft allWidth rightAligned">{accessIcons(tm.access, tm.licence)}</span></span> */}
                </Card.Meta>
                <Divider />
                <Card.Description>
                    <Head textAlign="left" size="small" title="" icon="at" content={tm.email}/> 
                    <Divider />
                    <Head textAlign="left" size="small" title="" icon="mobile" content={tm.tel}/>
                    <Divider />
                    <Head textAlign="left" size="small" title="Fichier Client" icon="users" content={tm.customers ? (tm.customers.length || 1) : 0} /> 
                    <Divider />
                    <Head textAlign="left" size="small" title="Base de données personnelle" icon="database" content={tm.mirrors ? tm.mirrors.length : 0} />
                    <Divider />
                    <Head textAlign="left" size="small" title="Évènements clients" icon="calendar" content={tm.events ? tm.events.length : 0} />
                    
                </Card.Description>
            </Card.Content>
            <Card.Content extra className="centered">
                
                <AnimatedButton onClick={() => setState({newTM: tm, addTeamMember: true, editionMode: true, appBarSet: false})} size="mini" className="greenAppButton" visible={<p className="txtXS">Éditer</p>} invisible={<Icon size="large" name="edit"/>}/>
                {/*<AnimatedButton onClick={() => setState({msgTM: true})} size="mini" className="greenAppButton" visible={<p className="txtXS">Messagerie</p>} invisible={<Icon size="large" name="comments outline"/>}/>*/}
                {
                    state.isManager
                    && <AnimatedButton onClick={() => removeTM(tm)} size="mini" className="appButton vibrate" visible={<p className="txtXS">Supprimer</p>} invisible={<Icon size="large" name="close"/>}/>
                }
            </Card.Content>
        </Card>
    </Grid.Column>

    const modifiableItem = ({title, key, icon, component, merge, editionRestriction}) => {
        const isMissing = missing[key]
        const thisProps = {
            value: state.newTM && state.newTM[key],
            onChange: (e) => {
                setState({newTM: {...state.newTM, [key] : merge ? {...state.newTM[key], ...e.target.value} : e.target.value}})
                setMissing({[key]: false})
            },
        }
        return <span key={`tmf_${title}`}>  {/*THIS KEY MUST REMAIN AND NOT BE UNIQUE or client will loose focus on inputs after a re-render (rebuilding the all component from scratch) */}
            <Head
                textAlign="left"
                contentStyle={{padding: '1em', width: '100%'}}
                titleClassName="mouseGrey"
                title={key==='licence' ? `${title} ${state.newTM && state.newTM.licence ? 'Manager' : 'Salarié'}` : title}
                subHeader={editionRestriction ? '(définitif, non modifiable)' : undefined}
                icon={<Icon name={icon} color={isMissing ? 'red' : ((state.newTM && state.newTM[key]) || (key === 'access' || key === 'licence') ? "teal" : "black")} />}
                content={
                    (state.editionMode && editionRestriction)
                    ? <h3>{thisProps.value}</h3>
                    : (
                        component 
                        ? component({...thisProps}) 
                        : <Input className="allWidth" {...thisProps}/>
                    )
                }
            />
            <Divider />
        </span>
    }

  



    /*************************************[ RETURN ]***********************************/
     console.log('Team state', state)
    return(
        !tml
        ? <AppLoader open={!tml} title="Chargement du fichier équipe" /> 
        : (
            state.addTeamMember
            ? <div className="paddingHorizontal tekoAll centered">
                {
                    Fields.map((field) => modifiableItem({...field}))
                }
                <Divider />
                {
                    <AnimatedButton size="big" className="greenAppButton centered" visible={state.editionMode ? "Enregistrer les modifications" : "Enregistrer le nouvel utilisateur"} invisible={<Icon name="check circle outline" color="teal" size="large" />} onClick={createNewTM} />
                }
                <Divider className="paddingVertical"/>
                
            </div>
            : <Grid>
                {
                    tml.map((tm) => renderTeamMember(tm))
                }
            </Grid>
        )
    )
}

export default connect(Utils.mapStateToProps)(Team)