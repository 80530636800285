import React from 'react'
import {Icon, Grid, Header, Image} from 'semantic-ui-react'
import Utils from '../constants/Utils'
import { Link } from 'react-router-dom'
import Menu from '../constants/Menu'    
import {connect} from 'react-redux'


class Dashboard extends React.Component {
    
    menuButton = ({title, view, subHead, icon, restriction}) => <Grid.Column key={Utils.keyExtractor()} className="cardField hasValue" width={3} style={{height: (window.innerWidth > window.innerHeight) ? '25vh' : '15vh'}}>
        <Link to={`/${view}`}>
            <Header as="h2" className="centered">
                <Image centered alt="" size="massive"><span className="centered iconImage">{icon}</span></Image>
                <p className="txtLS margedTop">{title}</p>
                <Header.Subheader>
                <p className="txtM" style={{lineHeight: 1}}>{subHead}</p>
                </Header.Subheader>
            </Header>
        </Link>
        
    </Grid.Column>

    fireMessage = () => {
        const { state } = this.props.location;
        if (state && state.message) {
        const stateCopy = { ...state };
        delete stateCopy.message;
        alert(state.message)
        this.props.history.replace({ state: stateCopy });

        }
    }

    componentDidMount() {
        const {appBarSetter} = this.props
        typeof appBarSetter === 'function' && appBarSetter({viewName:'Dashboard', controllers: '', searcher: '', centerController: "", bottomController: ""})
        this.fireMessage()
    }

    componentDidUpdate() {
        
        this.fireMessage()
    }


    render() {
        //let landscape = window.innerWidth > window.innerHeight
        //console.log('== SESSION ==', this.props.session)
        return(
            <div className="centeredNoFlex tekoAll paddingHorizontal ">
                
                <Grid className="centered dashboardGrid">                
                    {
                        Menu.map((menu) => this.menuButton(menu))
                    }
                </Grid>

            </div>
        )
    }

}

export default connect(Utils.mapStateToProps)(Dashboard)