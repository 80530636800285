import React from 'react';
import AppLogo from './AppLogo';
import Utils from '../constants/Utils'
import { Icon, Divider, Segment, Grid, TextArea, Header, Dropdown, Image, Modal, Label } from 'semantic-ui-react';
import moment from 'moment'
import Layout from '../constants/Layout'
import axios from 'axios';
import Cropper from './ImageCropper';
import Drawer from './Drawer';
import AppLoader from './AppLoader'
import ImageImport from './ImageImport';
import Head from './Head';
import Colors from '../constants/Colors';

const imageField = (src, align, fit, meta) => ({width:'auto', image:src, fit: (fit || [300, 490]), alignment:(align || 'center'), margin:5, meta:meta})
const textField = (txt, size, bold, align) => ({text:txt, fontSize:(size || 16), alignment: (align || 'center'), bold:(bold || false) }) 

// const AddImageIcon = ({onClick}) => <CornerIcons onClick={onClick} size="huge" one="images outline" two="add" colorOne="grey" colorTwo="grey" />
// const AddTextIcon = ({onClick}) => <CornerIcons onClick={onClick} size="huge" one="file outline" two="write" colorOne="grey" colorTwo="grey" />


/* ======================================================================== R E P O R T   F I E L D ======================================================================== */

/**
 * @title ReportField Component
 * is responsible for proposing both text and files inputs and compute a dd field
 * @dev 1- Propose fields 2- compute dd 3- call onDDReady
 * @notice 
 * returns a documentationDefinition object via 'onDDReady' prop
 * ##PROPS
 *  - onDDReady (function) method called each time a documentation definition object is computed (pdfmake)
 *  - nextFieldPosition (number) : template 1, 2 or 3 for field items position
 *  - setImageReplacementMethod (function) : receives {name, method} distribute a method to PhotoReport to modify each currentImages (update ui image after edition)
 *  - cropImage
 *  - editImage
 */
class ReportField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            files : [],             // will store all selected files for this field (FileList)
            currentImages: [],      // will store images used by ui (not updated images)
            freezed: false,         // freeze component when field is validated to avoid position changes when nextFieldPosition props changes
            nextFieldPosition: props.nextFieldPosition,
            displayMode: false      // displayMode is set to true when the field is validated and DD is sent via props.onDDReady
        }

        this.setCurrentImage = this.setCurrentImage.bind(this)
    }

    componentDidUpdate() {
        const {nextFieldPosition} = this.props;

        if ((this.state.nextFieldPosition !== nextFieldPosition) && !this.state.freezed) {
            this.setState({nextFieldPosition})
        }
    }
   

    setText = (e) => {
        this.setState({currentText: e.target.value})
    }

    setMethod = (name) => {
        //console.log('setting method for', name)
        let method = ({src}) => {
            //console.log('WITHIN METHOD RECEIVED SRC', src)
            this.setCurrentImage({name, src})
        }
        this.props.setImageReplacementMethod({name, method})
    }
    /**
     * @dev ##flow
     *  - 1: Display file on local template with base64
     *  - 2: Add a reference to the file by its name in dd
     *  - 3: send selected files as FileList
     */
    setFieldImages = async(e) => {
        let _files = await Promise.resolve(e.target.files)
        console.log('\nsetFieldImages FILE _FILES\n', _files)
        let currentImages = await new Promise(async(resolve) => {
            let exitLen = _files.length
            let files = []
            //
            for (let i = 0; i < exitLen; i++) {
                let f = _files[i];
                
                
                var picReader = new FileReader();
                    
                picReader.addEventListener("load",async(event) => {
                    this.setMethod(f.name)
                    var picFile = event.target;
                    
                    let src = picFile.result
                    let file = await Promise.resolve({...f, src, name: f.name})

                    await Promise.resolve(files.push(file))

                   
                })

                picReader.readAsDataURL(f)
                if (i === exitLen-1)
                resolve(files)
            }
        })

        this.setState({currentImages, files: _files})
        return true
    }

    //this.props.setImageReplacementMethod({method: (({name, src}) => this.setCurrentImage({name, src})).bind(this)})
    setCurrentImage = async({name, src}) => {
        
        let {currentImages} = this.state

        let CI = await Promise.resolve(
            currentImages && currentImages.length && currentImages.map((img) => (
                img.name === name
                ? {...img, src}
                : img
            ))
        )


        this.setState({currentImages: CI || []})
    } 
   
    /**
     * @dev DocumentDefinition PreProcessing (for future pdfmake computation)
     * @notice all images used here have no sources and are File objects from a FileList, 
     * server will make them readable later on while creating the photo-report
     */
     createFieldDD = () => {
        return new Promise(async(resolve) => {
            let emptyTextField = await Promise.resolve(textField(' '));
            let {currentText, currentImages, nextFieldPosition} = this.state
            let TextField = await Promise.resolve(textField(currentText || ' '));

            let _encodedFileName = (name) => name.replace('.', '_*%$_').split(' ').join('')

            let _createFieldDD = async(image, textAppened) => {
                let TEXT = await Promise.resolve(textAppened ? emptyTextField : TextField)
                let render = await Promise.resolve(
                    (nextFieldPosition < 3)
                    ? {
                        columns: [
                            (
                                nextFieldPosition > 1
                                ? imageField(_encodedFileName(image.name), null, null, image.name)  // 1st image
                                : TEXT                            // text
                            ),
                            (
                                nextFieldPosition > 1
                                ? TEXT                             // text
                                : imageField(_encodedFileName(image.name), null, null, image.name)  // 1st image        
                            )
                        ]
                    } 
                    : [(!textAppened && TextField), imageField(_encodedFileName(image.name), null, null, image.name)]
                
                )
                return render
            }
            ///

            let imgLen = currentImages && currentImages.length;
            let fieldDD = []

            if (imgLen) {
                // build DD from pre process (images still have no source and DD is not usable by pdfmake as is)
                for (let i = 0; i < imgLen; i++) {
                    let image = this.state.files[i];   // do not pass base64 source to server (SHOULD contain 'name')
                    let rendered = await _createFieldDD(image, i>0)

                    if (Utils.ofType(rendered) === 'array') {
                        rendered.forEach((r, ri) => {
                            if (r)
                                fieldDD.push(r)

                            if ((ri === imgLen-1) && (i === imgLen-1))  
                                resolve(fieldDD)
                        })
                    } else {
                        fieldDD.push(rendered)

                        if (i === imgLen-1) 
                        resolve(fieldDD)
                    }
                }
            } else {
                if (currentText) {
                    resolve([TextField])
                }
            }
            
            
        })
    }

    validateField = async() => {
        // Create field on DOM : display field images and text instead of upload choice
        this.setState({displayMode : true, freezed: true})

        // Create field Document Definition and call props.onDDReady
        let dd = await this.createFieldDD()
        typeof this.props.onDDReady === 'function'
        && this.props.onDDReady({dd, files: this.state.files}) 
    }

    imageImportSelector = async({src, fileName, rejected}) => {
        rejected && global.fireMsg({error: rejected})
        if (src) {
            //this.setState({loadingImage: true})
            console.log('PhotoReport.js --> ImageImport onSelect --> function params', {src, fileName, rejected})
            let file = await Utils.toFile(src, fileName)    // <--- BUG HERE
            console.log('PhotoReport -> ReportField -> imageImportSelector -> (Utils.storeFile) file', file)
            await this.setFieldImages({target: {files: [file]}})
            return true
        } else console.error('PhotoReport.js -> ReportField --> render --> ImageImport component onSelect props --> ERROR : no src received')
    }

    /* 
        // MULTIPLE Files Version
        // @param _files : [ {src, fileName, rejected}, ...]
        imageImportSelector = async(_files) => {
            if (_files && _files.length) {
                //this.setState({loadingImage: true})
                console.log('PhotoReport.js --> ImageImport onSelect --> _files', _files)
                let rejections = []
                let files = await Promise.resolve(
                    _files.map((file) => {
                        file.rejected && rejections.push(<li>{file.rejected}</li>)
                        return Utils.toFile(file.src, file.fileName)
                    })
                )

                rejections.length && global.fireMsg({error: <ul>{rejections}</ul>})

                
                await this.setFieldImages({target: {files}})
                return true
            } else console.error('PhotoReport.js -> ReportField --> render --> ImageImport component onSelect props --> ERROR : no src received')
        }
    */

    render() {
        const {displayMode, currentImages, currentText, nextFieldPosition} = this.state
        console.log('ReportField state', this.state)
        console.log('ReportField props', this.props)
        const activityFilesOptions = (() => {
            return (() => {
                let opts = []
                for (let f of this.props.activityFiles) {
                    console.log('f', f)
                    opts.push({value: f, key: f.key, text: f.name})
                }
                return opts
            })()
        })()
        console.log('activityFilesOptions', activityFilesOptions)

        const hasAFiles = this.props.activityFiles && this.props.activityFiles.length
        return(
            displayMode
            ? <Grid style={{textAlign: 'center'}}>
                <Grid.Column width={nextFieldPosition === 3 ? 16 : 8}>
                    {
                        nextFieldPosition !== 2
                        ? <p>{currentText}</p> 
                        : currentImages && currentImages.length && currentImages.map((im) => <div key={Utils.keyExtractor()}>
                            <Image key={Utils.keyExtractor()} className="centered" style={{margedTop: '1em', width: '80%', height: 'auto'}} src={im.src} />
                            <div className="centered" style={{marginBottom: "1em"}}>
                                <Icon name="crop" circular onClick={() => this.props.cropImage(im)}/>
                                <Icon circular name="edit outline" onClick={() => this.props.editImage(im)} />
                            </div>
                        </div>)
                        //: currentImages && currentImages.length && currentImages.map((im, imi) => <Image key={`im_${imi}`} className="centered" src={im.src} style={{margedTop: '1em', width: '80%', height: 'auto'}} />)
                        
                    }
                </Grid.Column>
                <Grid.Column width={nextFieldPosition === 3 ? 16 : 8}>
                    {
                        nextFieldPosition !== 2
                        ? currentImages && currentImages.length && currentImages.map((im) => <div key={Utils.keyExtractor()}><Image key={Utils.keyExtractor()} className="centered" style={{margedTop: '1em', width: '80%', height: 'auto'}} src={im.src} /><div className="centered" style={{marginBottom: "1em"}}><Icon name="crop" circular onClick={() => this.props.cropImage(im)}/><Icon circular name="edit outline" onClick={() => this.props.editImage(im)} /></div></div>)
                        : <p>{currentText}</p> 
                    }
                </Grid.Column>
            </Grid>
            : <Grid divided style={{textAlign: 'center'}}>
                {/* <Grid.Column className={`flexy flexCenter ${this.state.addTextClicked ? '' : 'textPop'}`} width={7}> */}
                <Grid.Column className="flexy flexCenter" width={7}>
                    {!this.state.freezed && <p className="silver">1 - Texte du champ</p>}
                    {
                        // this.state.addTextClicked
                        /*?*/ <TextArea onChange={this.setText.bind(this)} placeholder="" style={{minHeight: '20vh', width: '100%'}} />
                        // : <AddTextIcon onClick={() => this.setState({addTextClicked: true})}/>
                    }
                </Grid.Column>

                {/* <Grid.Column className={`flexy flexCenter ${this.state.addImagesClicked ? '' : 'textPop'}`} width={7}> */}
                <Grid.Column className="flexy flexCenter" width={7}>
                    {
                        !this.state.freezed 
                        && <div className="flexy">
                            <p className="silver">2 - Image du champ </p>
                            <span className="margedLeft">
                                {
                                    (this.state.files && this.state.files.length)
                                    ? <span>Image reçu <Icon name="check" color="teal" /></span>
                                    : <span> En attente de réception<Icon loading name='spinner' className="silver"/></span>
                                }
                            </span>
                        </div>
                        
                        // <span className="flexy flexCenter">
                        //     <p className="silver">2 - Image du champ</p>
                        //     <Head
                        //         size="small"
                        //         titleClassName="silver"
                        //         contentWrapperClassName=""
                        //         title={(this.state.files && this.state.files.length) ? "Image Reçu" : "En attente de réception"}
                        //         icon={
                        //             (this.state.files && this.state.files.length)
                        //             ? <Icon name="check" color="teal" />
                        //             : <Icon loading name='spinner' className="silver"/>
                        //         }
                        //     />
                        // </span>
                    }

                    <ImageImport
                        onSelect={this.imageImportSelector.bind(this)}
                    />

                    <Grid divided>
                        {
                            hasAFiles
                            ? <Grid.Row>
                                <Label attached="top" className="centered" style={{fontSize: 17, margin: 0}} pointing="below" color="grey">
                                    Fichiers du dossier
                                </Label>
                                <Dropdown
                                    className="margedTop flexCenter overable padded1"
                                    options={activityFilesOptions}
                                    text={(!(this.state.files && this.state.files.length)) && "Sélectionnez un fichier du dossier"}
                                    onChange={(e, {value}) => this.imageImportSelector({
                                        src: value.src, 
                                        fileName: value.name
                                    })}
                                />
                            </Grid.Row>
                            : null
                        }

                        {/* { hasAFiles ? <Divider vertical /> : null } */}

                        {/* <Grid.Column centered width={hasAFiles ? 8 : 16}>
                            <Head
                                size="medium"
                                titleClassName="silver"
                                contentWrapperClassName="allWidth"
                                title={(this.state.files && this.state.files.length) ? "Image Reçu" : "En attente de réception"}
                                icon={
                                    (this.state.files && this.state.files.length)
                                    ? <Icon name="check" color="teal" />
                                    : <Icon loading name='spinner' className="silver"/>
                                }
                            />
                        </Grid.Column> */}
                    </Grid>
                    {
                        // this.state.addImagesClicked
                        // ? <Input type="file" multiple onChange={this.setFieldImages.bind(this)} />
                        // : <AddImageIcon onClick={() => this.setState({addImagesClicked: true})} />
                    }

                </Grid.Column>

                <Grid.Column width={1} style={{backgroundColor: 'silver'}} className="verticalText centered" onClick={this.validateField.bind(this)}>
                    VALIDER
                </Grid.Column>
            </Grid>
        )
    }
}




/* ======================================================================== P H O T O   R E P O R T ======================================================================== */
/**
 * @title PhotoReport Component
 * #Responsible for generating a PDF photo report from entries
 * ## PROPS
 *  - activity {object} : the activity concerned by the photo-report
 *  - close (function) : method called to close the new photo report popup (modal)
 *  - updateActivities (function): is the updateActivities method from Activities.js (refetch activities and update activity)
 */
export default class PhotoReport extends React.Component {
    /* ------------------------ INITIAL STATE ------------------------ */
    state = {
        savingReport: false,
        files: [],          // photo report all files (FileList)
        fieldsCount: 0,
        documentDefinition: {content:[], images:{}},
        nextFieldPosition: 1,  // default : text left, img right
        /* edition state */
        sourceReplacementMethods: {}     // will store all updated sources for a given image (set by PhotoReport when validated)
    }

    componentDidMount() {
        this.initBaseReport()
    }
    /* 
    const imageField = (src, align, fit, meta) => ({width:'auto', image:src, fit: (fit || [300, 490]), alignment:(align || 'center'), margin:5, meta:meta})
    const textField = (txt, size, bold, align) => ({text:txt, fontSize:(size || 16), alignment: (align || 'center'), bold:(bold || false) }) 
    */
    initBaseReport = async() => {
        let {documentDefinition} = this.state
       

        // add pdf header
        let header = await Promise.resolve({
            alignment: 'justify',
            columns: [
                {
                    type: 'none',
                    ul: [
                        textField(this.props.activity.name, 18, true, 'left'),
                        textField(`À ${this.props.activity.location}`, 15, false, 'left'),
                        textField(`Le ${moment(Date.now()).format('LL')}`, 12, false, 'left')
                    ]
                },
            ]
        })
        // set pdf header
        documentDefinition.content.push(header)

        // set pdf after header document type title + date
        let afterHeader = await Promise.resolve(
            textField('Rapport - Photos', 20, true, 'center'),
        )
        // set after header
        documentDefinition.content.push(afterHeader);  // add document title
        documentDefinition.content.push(this.separatorLine())

        this.setState({documentDefinition})

    }

    /* ------------------------ REMOTE OPERATIONS ------------------------ */
    /* SAVE PHOTO REPORT NEW 
        Flow : 
            *front*
            1- Create a new FormData
            2- Add all bodyObject key to it using for loop
            3- Add selected files to a fileData key
            4- use axios to send post request to api (createPhotoReport)
            *back*
            5- Once stored with multer, use aws images urls to build PDF (use url if possible by pdfmake else use base64 url)
    */

    savePhotoReport = async() => {
        const {photoReports, name} = this.props.activity || {}
        const {documentDefinition, files} = this.state
        this.setState({savingReport: true})
        let fileName = await Promise.resolve(`rapport_${name.split(' ').join()}_${((photoReports && photoReports.length) || 0) + 1}`)

        //console.log(`Saving ${fileName} (${documentDefinition.content.length} fields)...`)
       
        // add file infos to pdf document
        documentDefinition.info = await Promise.resolve({
            title: fileName,
            author: 'AMC LEBLANC',
            subject: 'Rapport d\'expertise AMC Leblanc',
            keywords: 'Rapport Photos Expertise AMC Leblanc'
        });
        //console.log('savePhotoReport documentDefinition', documentDefinition)
       
        // axios (uploading files via multer)
        let formData = new FormData();
        formData.append("activityId", this.props.activity.activityId)
        
        if (files) {
            for (const file of files) {
                formData.append('fileData', file);
            }
        }
       
        for (let k of Object.keys(documentDefinition)) {
            formData.append(k, JSON.stringify(documentDefinition[k]))
        }
        

        setTimeout(() => {
            const {email, hash} = this.props.client

            axios
            .post(`${process.env.REACT_APP_API_URL}api?credentials=${email.toLowerCase()}::${hash}&request=createPhotoReport`, formData)
            .then(res => {
                //console.log('axios response', res)
                let positive = "Nouveau Rapport Créé";
                this.props.close(true)          // close new report pop up without confirmation
                this.props.updateActivities()

                setTimeout(() => {
                    global.fireMsg({positive});
                    this.setState({savingReport: false})
                });      // indicate success

            })
            .catch(err => {
                console.error(err)
                this.setState({savingReport: false})
                alert('Il y a eu une erreur lors de la création de votre Rapport en PDF')
            });

            


            
        })
        //end axios request
        
       
    }

    /* ------------------------ PDF CREATION ------------------------ */
    /*** add a separator line to pdf ***/
    separatorLine = () => ({
        layout: "separatorLayout",
        table: {
                widths: ['*'],
                body: [[" "], [" "]]
        }
    })

    receiveFieldDD = async({dd, files}) => {
        //console.log('PhotoReport receiveFieldDD dd', dd)
        //console.log('PhotoReport receiveFieldDD files', files)
        
        let {documentDefinition} = this.state
        // add files to DD.images

        let filesObj = await new Promise((res) => {
            let fo = {}
            if (files && files.length) {
                for (const file of files) {                     //  _*%$_ replaces all dots in file names + no space
                    
                    Promise.resolve(file.name.replace('.', '_*%$_')).then((k) => {
                        fo[k.split(' ').join('')] = file  //no space file name as key {page1_*%$_png} (will be parsed by server to retrieve real name)

                        if (file === files[files.length-1])
                            res(fo)
                    })
                    
                }
            } else res(fo)
        
        })
        //console.log('filesObj', filesObj)

        documentDefinition.images = {...documentDefinition.images, ...filesObj}
        // add dd (content) to DD.content + pdf separator  
        documentDefinition.content = [...documentDefinition.content, ...dd, this.separatorLine()]
        this.setState({documentDefinition, files: [...this.state.files, ...files], fieldsCount: this.state.fieldsCount + 1})
    }

    undo = () => {
        // remove last field 
        // from front end report
        
        // from document definition
    }

    // IMAGE EDITION METHODS WILL USE replaceFieldDD prop to replace fieldDD and files (edit FileList too) in final Document Definition
    // @param image {object} {src:base64}
    cropImage = (imageToCrop) => {
        //console.log('cropping image', imageToCrop)
        this.setState({imageToCrop})

    }   
    editImage = (imageToEdit) => {
        //console.log('editing image', imageToEdit)
        this.setState({imageToEdit})
    }

    /**
     * Set a FileList object programatically from File objects
     * @param items : [array] of File objects
     */
    _FileList = (items) => {
        // flatten rest parameter
        items = [].concat.apply([], [items]);
        // check if every element of array is an instance of `File`
        if (items.length 
            && !items.every(function(file) { 
                return file instanceof File})) {
        throw new TypeError("expected argument to FileList is File or array of File objects");
        }
        // use `ClipboardEvent("").clipboardData` for Firefox, which returns `null` at Chromium
        // we just need the `DataTransfer` instance referenced by `.clipboardData`
        var dt = new ClipboardEvent("").clipboardData || new DataTransfer();
        // add `File` objects to `DataTransfer` `.items`
        for (var i = 0; i < items.length; i++) {
        dt.items.add(items[i])
        }
        return dt.files;
    }

    resetImage = async(dataUrl) => {
        const {imageToEdit, imageToCrop, files, sourceReplacementMethods} = this.state
        let image = await Promise.resolve(imageToEdit || imageToCrop)
        const __file = await Utils.toFile(dataUrl, image.name)

        // replace file within this.state.files
        const Files = await new Promise((filesRdy) => {
            let _Files = []
            for (let i = 0; i < files.length; i++) {
                let file = files[i]

                _Files.push(
                    file.name === image.name
                    ? __file
                    : file
                )
                
                if (i === files.length-1) {
                    filesRdy(_Files)
                }
            }
        })
        // rebuilt FileList
        let List = await Promise.resolve(this._FileList(Files))
        // edit files, close edition modal
        this.setState({files: List, imageToEdit: undefined, imageToCrop: undefined})

        // replace file ui (related ReportField component's currentImage state) 
        //console.log(`typeof sourceReplacementMethods[${image.name}]`, typeof sourceReplacementMethods[image.name])

        if (typeof sourceReplacementMethods[image.name] === 'function') {
            sourceReplacementMethods[image.name]({src: dataUrl})
        }

    }
    
    /**
     * @dev ReportFields Image Replacement Receiver
     * @dev Will store all replacement methods within state.sourceReplacementMethods{}
     */
    receiveImageReplacementMethod = ({name, method}) => {
        //console.log('ImageReplacementMethod received', {name, method})
        let {sourceReplacementMethods} = this.state
        sourceReplacementMethods[name] = method
        this.setState({sourceReplacementMethods})

    }

    closeEditions = () => this.setState({imageToEdit: undefined, imageToCrop: undefined})
    
    /* ------------------------ RENDERERS ------------------------ */
    
    // <TextArea placeholder="Description des images..." style={{minHeight: '20vh', width: '100%'}} />
    render() {

        const { imageToEdit, imageToCrop, savingReport } = this.state
        //console.log('Photo Report render state', this.state)
        return(
          
            
            <div style={{height: window.innerHeight * 0.8}}>
               
                <AppLoader
                    open={savingReport}
                    title={"Création du rapport en cours"}
                    subTitle={"l'opération peut prendre un moment"}
                />
                {/* Images Editor */}
                <Modal open={typeof imageToEdit === 'object' || typeof imageToCrop === 'object'} onClose={this.closeEditions}>
                    {
                        imageToCrop
                        ? <Cropper src={imageToCrop.src} onCrop={this.resetImage.bind(this)} />
                        : <Drawer close={this.closeEditions} source={imageToEdit && imageToEdit.src} onSave={this.resetImage.bind(this)}/>
                    }
                </Modal>
                <Segment style={{display: 'flex', flex:1, flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center'}}>
                    {/* <CornerIcons size="big" one="file outline" two="right arrow" colorTwo="teal" onClick={this.addFieldToDocument.bind(this)}/> */}
                    <Dropdown 
                        options={[
                            {text: 'Texte à gauche, image à droite', value: 1, key: "field_1"},
                            {text: 'Texte à droite, image à gauche', value: 2, key: "field_2"},
                            {text: 'Texte au dessus, image en dessous', value: 3, key: "field_3"}
                        ]}
                        defaultValue={1}
                        onChange={(__, {value}) => this.setState({nextFieldPosition: value})}
                    />
                    <Icon size="large" name="undo" circular color="grey" onClick={this.undo.bind(this)} />
                    <Icon size="large" name="send" circular color="teal" onClick={this.savePhotoReport.bind(this)} />
                </Segment>
                <div className="tekoAll" style={{...(Layout.landscape ? {paddingRight: 50, paddingLeft: 50} : {}), height: '100%', overflow: 'scroll'}}>
                    
                    <Grid centered>
                        <Grid.Column width={8}>
                            <AppLogo size="small" floated="left"/>
                        </Grid.Column>
                        <Grid.Column width={6}>
                            <Header>{this.props.activity.name}</Header>
                            <ul>
                                <li>À {this.props.activity.location || ('n/c')},</li>
                                <li>Le {moment(Date.now()).format('LL')}</li>
                            </ul>
                        </Grid.Column>
                    </Grid>
                    
                    
                    <Divider horizontal content="Rapport Photo" />
                    
                    {
                        ((count) => {
                            let fields = []
                            for (var i = 0; i <= count; i++) {
                                fields.push(<div key={`rep_field_${i}`}>
                                    <ReportField 
                                        activityFiles={this.props.activity.files}
                                        setImageReplacementMethod={this.receiveImageReplacementMethod.bind(this)} 
                                        cropImage={this.cropImage.bind(this)} 
                                        editImage={this.editImage.bind(this)} 
                                        nextFieldPosition={this.state.nextFieldPosition} 
                                        onDDReady={this.receiveFieldDD.bind(this)} 
                                    />
                                    <Divider className="marged1" />
                                </div>)
                            }
                            

                            return fields.map((f) => f)
                        })(this.state.fieldsCount)
                    }
                    </div>
            </div>
            
        )
    }

}