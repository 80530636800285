import React from 'react'
import { Header, Divider, Loader, Dimmer } from 'semantic-ui-react';
import Colors from '../constants/Colors';
import TwoButtons from '../components/TwoButtons';
import Utils from '../constants/Utils';
import { connect } from 'react-redux'
import Crypto from '../constants/Crypto';

const styles = {
    largeInput: {
        width: window.innerWidth * 0.4, 
        height: '40px', 
        fontSize: 30
    },
}

/**
 * @dev Login Component
 * ##PROPS
 *  - onLoggedIn (function) *required* : called when login is confirmed and client is connected
 */
class Login extends React.Component {

    state = {
        email: '',
        pwd: '',
        appBarSet: false
    }

    setAppBar = () => {
        if (this.props.appBarSetter && !this.state.appBarSet) {

            this.props.appBarSetter({
                viewName: "Login",
                controllers: "",
                bottomController: "",
                centerController: "",
                searcher: ""
            })

            this.setState({appBarSet: true})
        }
    }


    componentDidMount() {
        this.setAppBar()
    }
    componentDidUpdate() {
        this.setAppBar()
    }

    _connect = async() => {
        this.setState({isConnecting : true})
        try {
            let {email, pwd} = this.state; 
            // console.log('email', email)
            // console.log('pwd', pwd)
            if (!email || !pwd) {
                // check for missing fields
                this.setState({isConnecting : false})
                throw new Error(!email ? "L'addresse e-mail est manquante" : (!pwd ? "Le mot de passe est manquant" : "Erreur d'authentification"))

            } else {
                const pwdHash = await Crypto.encrypt(pwd, true)
                const hash = await Crypto.encrypt(`${email}${pwdHash}`, true)
                // console.log('hash', hash)
                // try to connect client from its credentials
                let apiCall = await fetch(`${process.env.REACT_APP_API_URL}login`,
                // var apiCall = await fetch('https://amcleblanc.herokuapp.com/api?credentials='+email.toLowerCase()+'::'+pwd+'&request=initialConnect',
                {
                    method:'POST',
                    mode:'cors',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        hash, email
                    })
                });

                // console.log('apiCall', apiCall)

                // process session creation and client connection
                var Session = await new Promise(async(resolve, reject) => {
                    if(apiCall.status === 200) {
                        let AppDatas = await apiCall.json()
                        var {Client, TML, sessionId, Config, validity} = AppDatas;
                        const session = await Promise.resolve({
                            authCredentials: {email, pwd: pwdHash},
                            Client,
                            TML, 
                            sessionId,
                            Config,
                            validity,
                            fetchCredentials: {
                                email: Client.email,
                                hash: Client.hash,
                                id: Client.id
                            },
                            connected: true
                        })

                        // setSession sessionId 
                        document.cookie = `sessionId=${sessionId}`;
                                   
                        // console.log('%s est connecté [session id: %s]', Client.username, sessionId);
                        global.fireMsg({error: ""})
                        resolve(session);  
                    }
                    else reject('L\'authentification a échouée');
                }) 

                this.setState({isConnecting : false})
                Session && this.props.dispatch({type:'SET_SESSION', value: Session})

                //return (connected)
            }
        }catch(err) {
            console.error('_connect error:', err)
            this.setState({isConnecting : false})
            global.fireMsg({error: typeof err === 'string' ? err : "Il y a eu une erreur lors de votre identification, veuillez recommencer"})
        }
    }

    
    render() { 
        // console.log('Login props', this.props)
        // console.log('Login state', this.state)
       const { isConnecting } = this.state
        return(
            <div className="centered padded5 allWidth tekoAll">
                <Dimmer active={isConnecting}><Loader style={{fontSize:20}}>Connexion en cours</Loader></Dimmer>
                <Header as="h1">E-Mail</Header>
                <input onChange={(e) => this.setState({email: e.target.value})} style={styles.largeInput} id="login-email-field" type="email" name="email" autoComplete="on" />
                <Header as="h1">Mot de Passe</Header>
                <input onChange={(e) => this.setState({pwd: e.target.value})} style={styles.largeInput} type="password" name="pwd" autoComplete="on" />
                <Divider style={{margin: '10vh'}} />
                <TwoButtons
                    buttonOne={{title: 'Mot de passe oublié', clickHandler: () => alert('not done yet'), color: Colors.anthracite, textColor: Colors.amcred}}
                    buttonTwo={{title: 'Me Connecter', clickHandler: this._connect, regular: true, color: Colors.cyan, textColor: '#fff'}}
                />
            </div>
        )
    }
}

export default connect(Utils.mapStateToProps)(Login)
