import React from 'react';
import {Dropdown} from 'semantic-ui-react'
import Utils from '../constants/Utils';
import {connect} from 'react-redux'
/**
 * 
 * @param {object} props 
 * ##PROPS
 *  - onSelect (func) called each time user select a customer (receives )
 *  - TMLMode (bool) : set component to allow selection of team members iso default customers (default: false)
 *  - style
 *  - value
 */
const CustomersSelection = (props) => {
    const srcMaps = {
        sourceMap : (src) => src && src.length && src.map((c) => ({key: c.key, value: c.name, text: c.name})),
        tmlSourceMap : (src) => src && src.length && src.filter((_t) => _t.id !== props.clientId).map((t) => ({key: t.id, value: t.id, text: t.username}))
    }
    const {customers, TMLMode, TML} = props
    return <Dropdown 
        noResultsMessage={`Pas ${props.TMLMode ? "d'utilisateur" : "de client"}`}
        key={`cusdrop_${props.value || Utils.keyExtractor()}`}
        style={props.style || undefined}
        text={props.value || undefined}
        fluid 
        search 
        selection 
        placeholder={`Sélectionnez un ${TMLMode ? "collègue" : "client"}`}
        options={srcMaps[TMLMode ? "tmlSourceMap" : "sourceMap"](TMLMode ? TML : customers)} 
        onChange={props.onSelect}    
    />
}

const mapCustomersToProps = (state) => {
    return({
        customers: state.session.Client.customers,
        TML: state.session.TML,
        clientId: state.session.Client.id
    })
}

export default connect(mapCustomersToProps)(CustomersSelection);