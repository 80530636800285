import React from 'react'
import {SketchField, Tools} from 'react-sketch'
import Layout from '../constants/Layout'
import { Grid, Icon, Divider, Input, Modal, Segment, TextArea } from 'semantic-ui-react'
import Utils from '../constants/Utils'
import Dropzone from 'react-dropzone'
import Head from './Head'
import ImageResizer from './ImageResizer'
import LongPressable from './LongPressable'
const MenuDivider = () => <Divider style={{width: '100%', height: 3}} />
const sketchColors = ["black", "grey", "red", "orange", "yellow", "green", "blue", "purple", "brown"]
const tools = [
    {icon: 'pencil', set: 'Pencil'},
    {icon: 'square outline', set: 'Rectangle'},
    {icon: 'circle outline', set: 'Circle'},
    {icon: 'arrows alternate horizontal', set: 'Line'},
    {icon: 'arrows alternate', set: 'Pan'},
    {icon: 'mouse pointer', set: 'Select'}
]

/**
 * ##PROPS
 *  - source (source to load within drawer canvas)
 *  - activityId (used for activity.drawings storage)
 *  - close (func) : method called to close the drawer
 *  - onSave (func) : method called when client saves its drawing (receives (string) drawing : base64 dataUrl of drawing )
 */
export default class Drawer extends React.Component {
    state = {   // default
        tool: 'Pencil',
        color: 'black',
        lineWidth: 2,
        canUndo: false,
        canRedo: false
    }

    componentDidMount() {
        const {source} = this.props
        if (source) {
            this.setState({unsized: source})
        }
    }

    setTool = (tool) => {
        this.setState({tool, isEraser: false})
    }

    setColor = (color) => {
        const {tool, isEraser} = this.state
        
        this.setState({color, tool: (tool === 'Pan' || isEraser) ? 'Pencil' : tool, isEraser: false})
    }

    incrementLineWidth = () => {
        let {lineWidth} = this.state;
        lineWidth++;

        this.setState({lineWidth})
    }

    decrementLineWidth = () => {
        let {lineWidth} = this.state;
        lineWidth = (lineWidth - 1) < 1 ? 1 : lineWidth - 1
        this.setState({lineWidth})
    }

    undo = () => {
        this.sketch.undo()
        this.setState({
            canUndo: this.sketch.canUndo(),
            canRedo: this.sketch.canRedo(),
          });
    }

    redo = () => {
        this.sketch.redo()
        this.setState({
            canUndo: this.sketch.canUndo(),
            canRedo: this.sketch.canRedo(),
          });
    }

    _onSketchChange = () => {
        let prev = this.state.canUndo;
        let now = this.sketch.canUndo();
        if (prev !== now) {
            this.setState({ canUndo: now });
        }
    };

    _onBackgroundImageDrop = (_files, rejected) => {
        if (_files && _files.length > 0) {
            let reader = new FileReader();
            reader.addEventListener(
                'load',
                () =>
                // sketch.setBackgroundFromDataUrl(reader.result, {
                //     stretched: true,
                //     stretchedX: false,
                //     stretchedY: false,
                //     originX: 'left',
                //     originY: 'top',
                // }),
                this.setState({unsized: reader.result, backgroundImageLoaded: true}),
                false,
            );
            reader.readAsDataURL(_files[0]);
        } else if (rejected) {
            console.error(rejected)
        }
    };

    validateImage = ({source}) => {
        //console.log('validateImage source', source)
        this.sketch.setBackgroundFromDataUrl(source, {
            stretched: true,
            stretchedX: false,
            stretchedY: false,
            originX: 'left',
            originY: 'top',
        })

        this.setState({unsized: undefined})
    }

    download = async() => {
        if (this.props.onSave && (this.state.canRedo || this.state.canUndo || this.state.backgroundImageLoaded)) {
            let drawing = await Promise.resolve(this.sketch.toDataURL())
            
            this.props.onSave(drawing)
        } else alert('Opération Impossible\n  - Un croquis doit contenir au moins un élément pour être sauvegarder')
    }

    closeDrawer = () => {
        if (this.state.canRedo || this.state.canUndo) {
            if (window.confirm(`Abandonner ${this.props.source ? "l'édition" : "le croquis"} en cours ?`))
                this.props.close()
        } 
        else this.props.close()
    }

    render() {
        const { tool, color, lineWidth, unsized, addText, isEraser, canUndo, canRedo } = this.state
        //console.log('this.sketch', this.sketch)
        return (
            <Grid>
                {/* Image Resizer (upload) */}
                <Modal open={typeof unsized !== 'undefined'} size="large" style={{backgroundColor: 'transparent'}}>
                    <ImageResizer source={unsized} onResized={this.validateImage.bind(this)} returnSource={true}/>
                </Modal>
                <Modal open={addText} onClose={() => this.setState({addText: false})} size="large">
                    <Head
                        contentStyle={{width: '100%'}}
                        title="Ajouter un texte"
                        content={<div style={{width: '100%'}}>
                            <TextArea style={{width: '100%', height: '50vh'}} onChange={(e) => this.setState({currentText: e.target.value})}></TextArea>
                            <Segment className="centered" attached="bottom" style={{width: '100%'}}>
                                <Icon 
                                    circular
                                    name="check" 
                                    size="big"
                                    color="teal" 
                                    onClick={() => { 
                                        this.state.currentText && this.state.currentText.length && this.sketch.addText(this.state.currentText); 
                                        this.setState({addText: false, currentText: undefined}) 
                                    }}
                                />
                            </Segment>
                        </div>}
                    />
                </Modal>
                {/* Canvas Resizer */}          
                {/* <Modal open={resize} size="large" style={{backgroundColor: 'transparent'}}>
                    <ImageResizer source={this.sketch ? this.sketch.toDataURL() : undefined} />
                </Modal> */}
                {/* drawer controller */}
                <Grid.Column width={4} className='silverGradient'>
                    <div className="flexy flexCenter" style={{width: '100%', height: '100%', flexDirection: 'column'}}>
                        
                        {/* Background Image Drop/Click Zone */}
                        <Dropzone onDrop={this._onBackgroundImageDrop}>
                            {({getRootProps, getInputProps}) => (
                                <section>
                                    <div {...getRootProps({multiple: false})}>
                                        <input {...getInputProps({multiple: false})} />
                                        {/* <p><Icon name="download" /> Ajouter une image de fond</p> */}
                                        <Head
                                            size="medium"
                                            title={this.props.source ? "Changer l'image" : "Ajouter une image de fond"}
                                            className="tekoAll"
                                            content="glissez ou cliquez"
                                            icon={<Icon name="download" />}
                                        />
                                    </div>
                                </section>
                            )}
                        </Dropzone>

                        <MenuDivider />

                        {/* Color Selection Controllers */}
                        <div className="flexy flexCenter" style={{justifyContent: 'space-evenly', width: '100%'}}>
                            {
                                sketchColors.map((c) => <Icon key={Utils.keyExtractor()} name="circle" size="large" circular={c===color} color={c} onClick={() => this.setColor(c)} />)
                            }
                        </div>

                        <MenuDivider />

                        {/* Line Width Controller */}
                        {/* <div className="flexy flexCenter" style={{justifyContent: 'space-evenly', width: '100%'}}>
                            <span><Icon className="overable" name="minus" circular onClick={this.decrementLineWidth}/><Input onChange={(__, {value}) => this.setState({lineWidth: (value < 1) ? 1 : value })} style={styles.squareBoxInput} value={lineWidth} className="marginHorizontal" /><Icon className="overable" name="plus" circular onClick={this.incrementLineWidth} /></span>
                        </div> */}
                        <div className="flexy flexCenter" style={{justifyContent: 'space-evenly', width: '100%'}}>
                            <span>
                                <LongPressable  //this.setState({lineWidth: (value < 1) ? 1 : value })
                                    content={<Icon className="overable" name="minus" circular />}
                                    whilePressed={this.decrementLineWidth}
                                    intervalInMs={100}
                                />
                                <Input onChange={(__, {value}) => this.setState({lineWidth: (value < 1) ? 1 : value })} style={styles.squareBoxInput} value={lineWidth} className="marginHorizontal" />
                                <LongPressable  //this.setState({lineWidth: (value < 1) ? 1 : value })
                                    content={<Icon className="overable" name="add" circular />}
                                    whilePressed={this.incrementLineWidth}
                                    intervalInMs={100}
                                />
                            </span>
                            
                            
                        </div>

                        <MenuDivider />
                        
                        {/* Tool Selection Controllers */}
                        <div className="flexy flexCenter" style={{justifyContent: 'space-evenly', width: '100%'}}>
                            {
                                tools.map((t) => <Icon color={(t.set !== 'Pan' && t.set !== 'Select') ? color : 'black'} circular={(tool === t.set) && !isEraser} key={Utils.keyExtractor()} name={t.icon} size="large" onClick={() => this.setTool(t.set)}/>)
                            }
                            {/* <Icon circular={resize} name="expand" size="large" onClick={() => this.setState({resize: true})} /> */}
                            <Icon circular={addText} size="large" onClick={() => this.setState({addText: true})}><b>A</b></Icon>
                            <Icon circular={isEraser} name="eraser" size="large" onClick={() => this.setState({isEraser: true})} />
                        </div>

                        <MenuDivider />
                        {/* Undo / Redo / Close / Save Controllers */}
                        <div className="flexy flexCenter" style={{justifyContent: 'space-evenly', width: '100%'}}>
                            <Icon disabled={!canUndo} size="large" name="undo" onClick={this.undo}/>
                            <Icon disabled={!canRedo} size="large" name="redo" onClick={this.redo}/>
                            <Icon name="close" size="large" color="red" onClick={this.closeDrawer} />
                            <Icon name="check circle outline" size="large" color="teal" onClick={this.download}/>
                        </div>
                    </div>
                </Grid.Column>

                {/* drawing space*/}
                <Grid.Column width={12}>
                    <SketchField 
                        ref={c => (this.sketch = c)}
                        width="100%" 
                        height={Layout.height * 0.6}
                        tool={isEraser ? Tools.Pencil : Tools[tool]} 
                        lineColor={isEraser ? 'white' : color}
                        lineWidth={lineWidth}
                        onChange={this._onSketchChange}
                    />
                </Grid.Column>
            </Grid>
            
        )
    }
}

const styles = {
    squareBoxInput: {
        height: '2em',
        width: '4em',
       
    }
}