import React from 'react'
import { Icon } from 'semantic-ui-react';
import CornerIcons from '../components/CornerIcons'

const Menu = [
  
    {
        title:"Gestion des utilisateurs",
        view:"team",
        subHead:"Créer, Consulter, Modifier un utilisateur", 
        icon:<Icon size="big" name="users" />
    },
    {
        title:"Fichier Client",
        view:"crm",
        subHead:"Accéder à la liste de mes prospects / clients",
        icon:<Icon size="big" name="address book outline" />
    },
    {
        title:"Agenda",
        view:"agenda",
        subHead:"Consulter / éditer mes rendez-vous",
        icon:<Icon size="big" name="calendar alternate outline" />
    },

    /* RESTRICTED TO MANAGERS ROUTES */
    // {
    //     title:"Ma Flotte",
    //     view:"vehicules",
    //     subHead:"Gérer ma flotte de véhicule",
    //     icon:<Icon size="big" name="truck" />,
    //     restriction: ['isManager']
    // },

    {
        title:"Base de données",
        view:"database",
        subHead:"Gestion des données partagées / personnelles",
        icon:<Icon size="big" name="database" />,
        restriction: ['isManager']
    },

    {
        title:"Interventions", 
        view:"activities",
        subHead:"Créer, Consulter, Modifier une Intervention",
        icon:<Icon size="big" name="shipping fast" />,
        restriction: ['isManager', 'isSalePerson']
    },
    // {
    //     title:"Site Web",
    //     view:"settings",
    //     subHead:"Gestion du site internet",
    //     icon:<CornerIcons one="tv" size="big" two="wrench" corner="top left" />,
    //     restriction: ['isManager']
    // },
    {
        title:"Articles / Services",
        view:"products",
        subHead:"Créer, Consulter, Modifier mes articles / services",
        icon:<Icon size="big" name="boxes" />,
        restriction: ['isManager', 'isSalePerson']
    },
    {
        title:"Statistiques",
        view:"statistics",
        subHead:"La société en quelques chiffres",
        icon:<Icon size="big" name="chart pie" />,
        restriction: ['isManager', 'isSalePerson']
    },
    /* Comptabilité */
    {
        title:"Devis",
        view:"quotations",
        subHead:"Créer, consulter, éditer vos Devis",
        icon:<CornerIcons 
            size="big" 
            corner={false} 
            one="file outline" 
            two={<Icon 
                style={{padding: '2px 4px 0px 0px'}} 
                size="tiny">
                    D
                </Icon>
            }
        />,
        restriction: ['isManager', 'isSalePerson']
    },
    {
        title:"Factures",
        view:"bills",
        subHead:"Créer, consulter, éditer vos Factures",
        icon:<CornerIcons 
            size="big" 
            corner={false} 
            one="file outline" 
            two={<Icon 
                style={{padding: '2px 4px 0px 0px'}} 
                size="tiny">
                    F
                </Icon>
            }
        />,
        restriction: ['isManager', 'isSalePerson']
    },
    /* unrestricted */
    {
        title:"Réalisations",
        view:"realisations",
        subHead:"Liste des réalisations achevées",
        icon:<Icon size="big" name="images outline" />       
    },

    {
        title:"Configurations",
        view:"settings",
        subHead:"Configuration de votre compte et de l'application",
        icon:<Icon size="big" name="cogs" />
    },
    
]
export default Menu;