import React from 'react'
import { Modal, Grid, Segment, Icon, Table, Input, Dropdown, Sidebar } from 'semantic-ui-react'
import AppLogo from './AppLogo'
import moment from 'moment'
import TwoButtons from './TwoButtons'
import Utils from '../constants/Utils'
import ModifiableItem from './ModifiableItem'
import Head from './Head'
import TableRows from './TableRows'
import {connect} from 'react-redux'

const validityOptions = [
    {key: '30', text: '30 jours', value: '30'},
    {key: '45', text: '45 jours', value: '45'},
    {key: '60', text: '60 jours', value: '60'},
    {key: '90', text: '90 jours', value: '90'},
]

const documentTypes = {
    "quotation" : "devis",
    "bill" : "facture"
}
/**
 * ##PROPS
 *  - type (string) *required* : 'quotation' || 'bill'
 *  - onClose (function) : modal closer
 *  - activity {object} : the activity in relation to the accountingDocument
 *  - updateActivities (function) : activities re-fetcher / updater
 *  - accountingDocument {object} : replace state.accountingDocument on mount (implicit edition mode)
 */
class AccountingDocument extends React.Component {
    constructor(props) {
        super(props);
        const now = Date.now()
        this.state = {
            type: documentTypes[props.type],
            addOne: true,
            products: undefined,
            accountingDocument: props.accountingDocument || { 
                type: props.type,
                status: "awaitingTransmission",
                customerName: props.activity.customerName,
                timestamp: now,                                               /* accountingDocument object */
                activityId: props.activity.activityId,
                activityName: props.activity.name,
                //reference,    // is async defined onMount 
                fileName: now, // fileName will be used and changed onMount //`devis-${new moment(now).format('YYYY')}-${Utils.pad(reference)}`,                                                                             
                date : new moment(now).format('LL'),                            // accountingDocument date
                validity: '30',                                         // number of days the accountingDocument remains valid
                address : undefined,                                    // accountingDocument address to
                tva: 20,                                                // default TVA (20%)
                lines: {}                                           // accountingDocument lines ranked by rowId
            }
        }

        this.lastRowsStates = {}


        this.setAccountingDocument = this.setAccountingDocument.bind(this)
    }
    
    setAccountingDocument = (mergeObj) => mergeObj && this.setState({accountingDocument: {...this.state.accountingDocument, ...mergeObj}})

    async componentDidMount() {
        // check presence of required fields and revert document creation action with error msg if absent 
        if (!this.props.activity.customerName) {
            global.fireMsg({error: `Vous n'avez pas lié de client au dossier. Le destinataire doit apparaitre sur le document, Veuillez lier un client au dossier via l'onglet "infos" avant de créer ${this.props.type === 'quotation' ? 'un nouveau devis' : 'une nouvelle facture'}`})
            this.props.onClose(true)
        }
        else { 
            // set the initial rowsStates
            let initialRowsStates = await new Promise(async(_irs) => {
                if(!this.props.accountingDocument) _irs(undefined)
                else {
                    let keys = await Promise.resolve(Object.keys(this.props.accountingDocument.lines))
                    let irs = {}

                    for (let _k of keys) {
                        (async(k, line, last) => {
                            irs[k] = {docLine: line, displayMode: true}

                            if (last) _irs(irs)
                        })(_k, this.props.accountingDocument.lines[_k], _k === keys[keys.length-1])
                    }
                }
            })
            if (!this.props.type) {
                console.error('AccountingDocument mounting Error: missing "type" prop')
                global.fireMsg({error: 'Il y a eu une erreur lors du chargement du générateur de document. Veuillez recharger la page.'})
            }

            // call server ad loader with this.props.type
            const {error, response: adLoader} = await Utils.fetchApi({
                request: "accountingDocumentLoader",
                method: 'GET',
                client: this.props.fetchCredentials
            })
            if (error) {
                console.error(`Server ${this.props.type}Loader ERROR`, error)
                global.fireMsg({error: 'Il y a eu une erreur lors du chargement du document, veuillez réessayer'})
            } else {
                const { documentReference: dRef, accounting, products } = adLoader
                const references = await Promise.resolve(    // use props.accountingDocument.reference is provided
                    this.props.accountingDocument
                    ? {[this.props.accountingDocument.type+'s']: this.props.accountingDocument.reference} 
                    : dRef
                )
                this.setState({products, accounting, initialRowsStates, accountingDocument: {...this.state.accountingDocument, reference: references[this.props.type+"s"], fileName:this.props.accountingDocument ? this.props.accountingDocument.file.originalname : `${this.state.type}-${new moment(this.state.accountingDocument.fileName).format('YYYY')}-${Utils.pad(references[this.props.type+"s"])}`}})

            }
        }
    }

    // NEW setAddress (sets a "setDeliveryAddress" key within state)
   setAddress = async({workplaceAddress, customerAddress}) => {
        // address is the address to use and set accountingDocument with
        // customer is the customer address of the related activity
        
        let customer = await Utils.customerFromName(this.props.activity.customerName, this.props.customers)
        let address = customerAddress ? customer.address : this.props.activity.location     
        
        // parsed address
        const addressParsed = await Promise.resolve(Utils.parseAddress(address))
        const {street, cp} = addressParsed || {}

        //this.setAccountingDocument({address, street, cp})
        const setDeliveryAddress = customerAddress && (this.props.activity.location !== customer.address)
        
        // set accounting document with address and indicate if a delivery address needs to be display
        this.setAccountingDocument({
            
            address, street, cp,
            setDeliveryAddress,
            deliveryAddress: setDeliveryAddress && Utils.parseAddress(this.props.activity.location)
            
        })
    }

    productFromId = (productId) => {
        const {products} = this.state
        return new Promise((resolve) => {
            if(!products) resolve(undefined)
            else {
                products.map(async(p, pi) => {
                    if (p.productId === productId)
                        resolve(p)
                    else if(pi === products.length-1)
                        resolve(undefined)
                })
            }
        })
    }

    selectProduct = async(_, {value}) => {
        const {productSetter} = this.state
        
        if (value) {
            let pickedProduct = await this.productFromId(value)
            productSetter(pickedProduct)
            this.setState({productSetter: undefined, pickProduct: false, addOne: false})
        }
    }


    pickProduct = (productSetter) => {
        this.setState({pickProduct: true, productSetter})
    }

    toggleAddOne = () => {
        this.setState({addOne: !this.state.addOne})
    }

    /* Set the totals and update rowsStates docLine within this.state.accountingDocument.lines*/
    setTotals = async(rowsStates) => {
        this.lastRowsStates = rowsStates || this.lastRowsStates
        //update accountingDocument.lines
        let lines = {}
        let rows = Object.keys(rowsStates)

        let ht = await new Promise(async(resHT) => {
            
            if (!rows.length) resHT(0)
            else {
                //
                let rowsPrices = await Promise.resolve(rows.map((r) => {
                    let { unitPrice, quantity} = rowsStates[r].docLine || rowsStates[r] || {}
                    lines[r] = rowsStates[r].docLine || rowsStates[r] || {}
                    
                    return(parseFloat(unitPrice||"0") * parseFloat(quantity||"0"))
                    
                }))


                const reducer = (accumulator, currentValue) => accumulator + currentValue;
                const HT = await rowsPrices.reduce(reducer)
                resHT(HT)
                //
            }
        })

         /* 
            - calcul de la tva -
            j'ai : [{tva: 10, unitPrice, qty}]
            je veux : [{percentage: 10, amount: 435.3}, {percentage: 20, amount: 342.7}]
        */
       let tvas = await new Promise((_tva) => {
            let _tvas = {}  // {"10" : 435.3, "20": 342.7} 
            for (let r of rows) {
                let {tva, unitPrice, quantity} = rowsStates[r].docLine || rowsStates[r]

                let rowTva = (_tvas[tva] || 0) + (parseFloat(unitPrice||"0") * parseFloat(quantity||"0")) * ((tva || 20)/100)
                _tvas[tva || 20] = rowTva
            }

            setTimeout(() => _tva(_tvas))
       })

        /* get total ht from tva percentage and total tva */
        const tvaTotalHT = (ttlTva, percentage) => (
            (( (ttlTva * percentage) / (percentage*percentage) ) * 100)
        )

        // [ {percentage: x, amount: y}, ...]
        let tva = await new Promise(async(tva_) => {
            let tvak = Object.keys(tvas)
            if (!(tvak && tvak.length)) tva_(0)
            let TVA = await Promise.resolve(tvak.map((t) => ({
                percentage: parseFloat(t),
                amount: tvas[t],
                totalHT: tvaTotalHT(tvas[t], parseFloat(t))
            })))

            tva_(TVA)
        })


        let tvaReducer = (a, b) => a.amount + b.amount
        
        let tvaAmount = tva.length > 1 ? await Promise.resolve(tva.reduce(tvaReducer)) : (tva.length ? tva[0].amount : 0)

        let ttc = await Promise.resolve(ht + tvaAmount)
        //let leftToPay = await Promise.resolve(ttc - parseFloat(this.state.accountingDocument.deposit || "0"))      

        let totals = await Promise.resolve({ht, tva, ttc/*, leftToPay*/}) 

        //set Accounting Document totals and lines
        this.setState({accountingDocument: {...this.state.accountingDocument, totals, lines}, addOne: false})
        // set deposit Percentage if this.state.accountingDocument.depositPercentage is set
        this.state.accountingDocument.depositPercentage
        && this.depositPercentage(null, {value: this.state.accountingDocument.depositPercentage}) 
    }

    docAddress = () => {
        const pAddress = Utils.parseAddress(this.state.accountingDocument.address)
        // if parsing failed, use state.ad.address
        const {street, cp} = pAddress || {street: this.state.accountingDocument.address}
        return <div className="flexy" style={{flexDirection: 'column', justifyContent: 'left'}}><p>{street}</p><p>{cp}</p></div>
    }

    updateActivities = () => {
        this.props.updateActivities()
        this.props.onClose(true)    // true: noConfirmation
    }
    
    createAccountingDocument = async() => {
        const validityDate = await Promise.resolve(this.state.accountingDocument.validityDate || moment(this.state.accountingDocument.timestamp).add(this.state.accountingDocument.validity, 'days').format('LL'))
        const payments = await Promise.resolve(
            this.state.accountingDocument.deposit
            ? [{ date: this.state.accountingDocument.timestamp, amount: this.state.accountingDocument.deposit, mode: undefined }]
            : []
        )
        const {error, response} = await Utils.fetchApi({
            body: {document: {...this.state.accountingDocument, validityDate, payments}},
            request: 'createAccountingDocument',
            client: this.props.fetchCredentials

        })
        if (error) {
            console.error(`Erreur lors de la création du ${this.state.type}: `, error)
        } else {
            global.fireMsg({positive: `${Utils.majFirstChar(this.state.accountingDocument.fileName)} enregistré${this.props.type === 'bill' ? 'e' : ''}`}) //todo : add activityId to accountingDocument
            //this.setState({})
            
            this.updateActivities()
            // MAYBE USE A PROP METHOD TO UPDATE ACTIVITYFILE INSTEAD...
        }
    }

    updateAccountingDocument = async() => {
        //console.log(`updating accountingDocument ${this.state.accountingDocument.fileName}`, this.state.accountingDocument)
        const {error, response} = await Utils.fetchApi({
            request: "setAccountingDocument",
            body: {
                replacePDF: true, 
                type: this.state.accountingDocument.type, 
                year: new Date(this.state.accountingDocument.timestamp).getFullYear(), 
                reference: this.state.accountingDocument.reference, 
                mergeObj: {
                    ...this.state.accountingDocument
                }
            },
            client: this.props.fetchCredentials

        })
        if (error) console.error('updateAccountingDocument ERROR:', error)
        else {
            this.updateActivities()

        }
        global.fireMsg({error, positive: !error && "Le document a été mis a jour"})

    }

    depositPercentage = async(e, {value}) => {
        let deposit = await Promise.resolve(
            (this.state.accountingDocument && this.state.accountingDocument.totals) 
            ? ((this.state.accountingDocument.totals.ttc || 0) / 100) * value 
            : 0
        )

       this.setAccountingDocument({deposit, depositPercentage: value})
    }

    setReferencedDocument = async(referencedDocument) => {
        // if type === bill 
        if (/*this.props.type === 'bill' && */referencedDocument && referencedDocument.originalname && referencedDocument.originalname.indexOf('devis') >= 0) {
            const {accounting} = this.state
            let _document = await Promise.resolve(
                accounting.quotations ? accounting.quotations.filter((q) => q.file.originalname === referencedDocument.originalname) : []
            )

            console.log('this.props.activity.quotations', this.props.activity.quotations)
            const document = _document[0]

            // set accountingDocument and load quotation lines within bill SetRowsStates
            this.setState({initialRowsStates: document.lines})
            // pass selected document props to this.state.accountingDocument
            this.setAccountingDocument({deposit: document.deposit})
            
        } 
        //
        else this.setAccountingDocument({referencedDocument})
        //
    }

    render () {
        //console.log('AccountingDocument state', this.state)
        const {ht, tva, ttc} = this.state.accountingDocument.totals || {}
        const isQuot = this.props.type === 'quotation'
        const leftToPay = ((ttc||0) - (this.state.accountingDocument.deposit || 0)).toFixed(2)
        const hasDeposit = typeof this.state.accountingDocument.deposit !== 'undefined'
        const lowerColsWidths = //isQuot ? [8,8] : (
            this.state.accountingDocument.setDeliveryAddress
            ? [7, 3, 6]
            : [8, 8]
        //)
        //const hasActivity = typeof this.props.activity === 'object' && Object.keys(this.props.activity).length
        return(
            <Modal onClose={this.props.onClose} size="fullscreen" open={true} className="fullscreenModal">
                <Sidebar.Pushable as={Segment}>
                    <Sidebar
                        className="centered padded3"
                        direction="right"
                        animation='push'
                        icon='labeled'
                        inverted='true'
                        onHide={() => this.setState({pickProduct: false})}
                        vertical='true'
                        visible={this.state.pickProduct}
                        width='very wide'
                    >
                        <Head
                            title="Liste de vos produits"
                            content={this.state.products 
                                ? <Dropdown
                                    noResultsMessage={"Pas de résultat"}
                                    floating
                                    labeled
                                    icon='search'
                                    options={this.state.products.map((p) => ({key:p.productId, text: p.name, value: p.productId}))}
                                    search
                                    text='Sélectionnez un produit  '
                                    onChange={this.selectProduct}
                                    value={this.state.pickedProduct ? this.state.pickedProduct.productId : undefined}
                                />
                                : (
                                    this.state.pickProductError
                                    ? <p className="negativeText">{this.state.pickProductError}</p>
                                    : <div className="centered"><Icon loading name="box"/>importation en cours</div>
                                )
                            }
                            icon="cubes"
                        />
                    </Sidebar>
                    <Sidebar.Pusher>
                        <div style={{width: '100%', height: '90vh', overflow: 'scroll', padding: '0 2.5em 0 2.5em'}}>

                            <Grid>
                                <Grid.Column centered="true" width={6}>
                                    <AppLogo size="small" />
                                </Grid.Column>

                                <Grid.Column centered="true" width={10} style={{display: "flex", alignItems: 'center'}}>
                                    <div className="allWidth flexy" style={{flexDirection: 'column', textAlign: "right", justifyContent: "right"}}>
                                        <h3>AMC LEBLANC</h3>
                                        <h5>COUVERTURE - MAÇONNERIE - RÉNOVATION</h5>
                                    </div>
                                </Grid.Column>

                                <Grid.Column floated="left" width={7}>
                                    <Segment className="leftAligned">
                                        <h2 className="centered">
                                            {this.state.type.toUpperCase()/*"DEVIS"*/} N° {this.state.accountingDocument.reference}
                                            {
                                                (
                                                    this.state.accountingDocument.totals 
                                                    && this.state.accountingDocument.address
                                                    && this.state.accountingDocument.object
                                                ) 
                                                && <span data-tooltip={`Valider le ${this.state.type}`}><Icon className="margedLeft overable" name="check circle outline" color="teal" size="large" onClick={this[this.props.accountingDocument ? "updateAccountingDocument" : "createAccountingDocument"].bind(this)} /></span>
                                            }
                                        </h2>
                                        <p>Le {this.state.accountingDocument.date}</p>
                                        <p><b>Référence : </b> {this.state.accountingDocument.fileName || "< Aucune >"}</p>
                                        {
                                            (
                                                this.props.activity.quotations 
                                                && this.props.activity.quotations.length 
                                            )
                                            && <span>
                                                <b>Document en référence : </b>
                                                {" "}
                                                <Dropdown 
                                                    inline 
                                                    defaultValue={this.state.accountingDocument.referencedDocument} 
                                                    onChange={(e, {value: referencedDocument}) => this.setReferencedDocument(referencedDocument)} //this.setAccountingDocument({referencedDocument})} 
                                                    options={
                                                        this.props.activity.quotations
                                                            .filter((_q) => this.props.type === 'bill' ? this.props.activity.transmittedDocs[_q.originalname].signed : !this.props.activity.transmittedDocs[_q.originalname].signed)
                                                            .concat([{C4NC3L: true, originalname: 'aucun'}])
                                                            .map((aq, aqi) => ({
                                                                text: Utils.majFirstChar(aq.originalname), 
                                                                key: `aq${aqi}`, 
                                                                value: aq.C4NC3L ? undefined : aq
                                                            }))
                                                    }
                                                />
                                            </span>
                                        }
                                        <p>
                                            <b>Chantier : </b> 
                                            {
                                                this.props.activity.name
                                                // (hasActivity)
                                                // ? this.props.activity.name
                                                // : <Input className="accountingDocumentInput" value={this.state.accountingDocument.activityName} onChange={(e) => this.setAccountingDocument({activityName: e.target.value, isUnkownActivity: true})} />
                                            } 
                                        </p>
                                        
                                    </Segment>
                                </Grid.Column>
                                <Grid.Column floated="right" className="rightAligned" width={7}>
                                    <Segment className="bold allHeight" color={this.state.accountingDocument.address ? "teal" : "red"}>
                                        
                                           <div>
                                                <h3 className="black leftAligned">{ this.props.activity.customerName && this.props.activity.customerName.toUpperCase() }</h3>
                                                {
                                                    this.state.accountingDocument.address
                                                    ? <h4 className="leftAligned">{this.docAddress()} <Icon circular color="grey" name="edit" size="small" onClick={() => this.setAccountingDocument({address: undefined})} /></h4>
                                                    : <TwoButtons 
                                                        buttonsClass="allWidth centered"
                                                        buttonOne={{
                                                            className: "greenAppButton",
                                                            title: "Adresse Chantier",
                                                            icon: "industry",
                                                            clickHandler: () => this.setAddress({workplaceAddress : true})
                                                        }}
                                                        buttonTwo={{
                                                            className: "greyAppButton",
                                                            title: "Adresse Client",
                                                            icon: "home",
                                                            clickHandler: () => this.setAddress({customerAddress: true})
                                                        }}
                                                    />
                                                }
                                            </div>
                                    </Segment>
                                </Grid.Column>
                                <Grid.Column width={16}>
                                    {/* Objet */}
                                    <Segment color={this.state.accountingDocument.object ? "teal" : "red"}>
                                        <b> 
                                            <ModifiableItem 
                                                valueFontSize={"1em"}
                                                titleClassName="black"
                                                title="Objet :"
                                                pair={{key: 'object', oldValue:this.state.accountingDocument.object}}
                                                changeMethodAsync={async({key, newValue}) => { this.setAccountingDocument({[key]: newValue}); return ({updated: true});}}
                                            />
                                        </b>
                                    </Segment>

                                    {/* Détails */}
                                    <Table celled>
                                        <Table.Header>
                                            <Table.Row className="centered">
                                                <Table.HeaderCell width={8}>LIBELLÉ <Icon circular className="overable whiteBkg margedLeft" color={this.state.addOne ? "red" : "green"} size="small" name={this.state.addOne ? 'minus' : 'add'} onClick={this.toggleAddOne} /></Table.HeaderCell>
                                                <Table.HeaderCell width={1}>QTÉ</Table.HeaderCell>
                                                <Table.HeaderCell width={1}>U</Table.HeaderCell>
                                                <Table.HeaderCell width={2}>P.U. HT</Table.HeaderCell>
                                                <Table.HeaderCell width={1}>TVA</Table.HeaderCell>
                                                <Table.HeaderCell width={2}>TOTAL HT</Table.HeaderCell>
                                                <Table.HeaderCell width={1} disabled={true}></Table.HeaderCell>

                                            </Table.Row>
                                        </Table.Header>
                                        <Table.Body style={{overflow: 'scroll'}}>

                                            {/* TableRows (Rows Manager) manages states and render of all TableRow components */}
                                            <TableRows 
                                                setTotals={this.setTotals.bind(this)}
                                                toggleAddOne={this.toggleAddOne} 
                                                addOne={this.state.addOne} 
                                                pickProduct={this.pickProduct.bind(this)}
                                                initialRowsStates={
                                                    this.state.initialRowsStates
                                                }

                                            />
                                            
                                        </Table.Body>
                                    </Table>
                                </Grid.Column>

                                {/* CONDITIONS */}
                                <Grid.Column width={lowerColsWidths[0]}>
                                    {
                                        this.props.type === 'bill' && tva && tva.length
                                        && <Segment>
                                            <Table fluid definition>
                                                <Table.Header>
                                                <Table.Row>
                                                    <Table.HeaderCell />
                                                    <Table.HeaderCell>TOTAL HT</Table.HeaderCell>
                                                    <Table.HeaderCell>TOTAL TVA</Table.HeaderCell>
                                                </Table.Row>
                                                </Table.Header>

                                                <Table.Body>
                                                    {
                                                        tva.map((t) => <Table.Row key={`tva_${t.percentage}`}>
                                                            <Table.Cell>{`TVA ${t.percentage}%`}</Table.Cell>
                                                            <Table.Cell>{t.totalHT.toFixed(2)}</Table.Cell>
                                                            <Table.Cell>{t.amount.toFixed(2)}</Table.Cell>
                                                        </Table.Row>)
                                                    }
                                                
                                               
                                                </Table.Body>
                                            </Table>
                                        </Segment>
                                    }
                                    <Segment className="leftAligned">
                                        {
                                            isQuot
                                            ? <div>
                                                <div><b><u>Conditions de règlement :</u></b></div>
                                                <div>Date de validité :&nbsp; 
                                                    <Dropdown 
                                                        text={moment(Date.now()).add(this.state.accountingDocument.validity, 'days').format('LL')} 
                                                        inline 
                                                        options={validityOptions} 
                                                        onChange={(e, {value}) => this.setAccountingDocument({validity: value, validityDate: moment(Date.now()).add(value, 'days').format('LL')})} 
                                                    />
                                                </div>
                                            </div>
                                            : (
                                                this.props.type === 'bill'
                                                &&  <div>Échéance le :&nbsp; 
                                                        <Dropdown 
                                                            text={moment(Date.now()).add(this.state.accountingDocument.validity, 'days').format('LL')} 
                                                            inline 
                                                            options={validityOptions} 
                                                            onChange={(e, {value}) => this.setAccountingDocument({validity: value, validityDate: moment(Date.now()).add(value, 'days').format('LL')})} 
                                                        />
                                                    </div>

                                            )
                                        }
                                        
                                        {/* <div>Date de validité : {moment(Date.now()).add('30', 'days').format('LL')}</div> */}
                                    </Segment>
                                </Grid.Column>

                                {/* DELIVERY ADDRESS *opt* */}
                                {
                                    this.state.accountingDocument.setDeliveryAddress
                                    && <Grid.Column centered width={lowerColsWidths[1]}>
                                        <Segment className="leftAligned">
                                            <h4>Adresse de chantier</h4>
                                            <div>{this.state.accountingDocument.deliveryAddress.street}</div>
                                            <div>{this.state.accountingDocument.deliveryAddress.cp}</div>
                                            
                                        </Segment>
                                    </Grid.Column>
                                }

                                {/* TOTALS */}
                                <Grid.Column width={lowerColsWidths[this.state.accountingDocument.setDeliveryAddress ? 2 : 1]}>
                                    <Segment>
                                        <Grid className="controlledPaddedGrid">
                                            <Grid.Column textAlign="left" width={8}>
                                                TOTAL HT
                                            </Grid.Column>
                                            <Grid.Column textAlign="right" width={8}>
                                                {(ht || 0).toFixed(2)} <Icon name="euro" size="small" />
                                            </Grid.Column>

                                            <Grid.Column textAlign="left" width={8}>
                                                {
                                                    tva && tva.length
                                                    ? tva.map((_t) => <div key={`tva_per_${_t.percentage}`}>{`TVA (${_t.percentage.toFixed(1)}%)`}</div>)
                                                    : 'TVA'
                                                }
                                            </Grid.Column>
                                            <Grid.Column textAlign="right" width={8}>
                                            {
                                                    tva && tva.length
                                                    ? tva.map((_t) => <div key={`tva_result_${_t.percentage}`}>{`${(_t.amount || 0).toFixed(2)}`} <Icon name="euro" size="small" /></div>)
                                                    : <span>0.00 <Icon name="euro" size="small" /></span>
                                            }
                                            </Grid.Column>

                                            <Grid.Column className={(!hasDeposit) ? "anthraciteBkg" : ""} textAlign="left" width={8}>
                                                <span className="txtS">TOTAL TTC</span>
                                            </Grid.Column>
                                            <Grid.Column className={(!hasDeposit) ? "anthraciteBkg" : ""} textAlign="right" width={8}>
                                                <span className="txtS">{(ttc || 0).toFixed(2)} <Icon name="euro" size="small" /></span>
                                            </Grid.Column>

                                            {
                                                !hasDeposit
                                                && <Grid.Column textAlign="left" width={8}><Icon className="overable" name="pie chart" onClick={() => this.setAccountingDocument({deposit: 0})} /></Grid.Column>
                                            }

                                            {/* if 'deposit' only : ACOMPTE & SOLDE A PAYER */}
                                            {
                                                hasDeposit
                                                && <Grid.Column textAlign="left" width={8}>
                                                    <span className="txtS">
                                                        <span>{
                                                            isQuot
                                                            ? "Acompte"
                                                            : "Acompte versé"    
                                                        }</span> 
                                                        {
                                                            isQuot
                                                            && <Icon     // selection
                                                                className="overable" 
                                                                name={hasDeposit ? "sort up" : "sort descending"} 
                                                                onClick={() => 
                                                                    Object.keys(this.state.accountingDocument.lines).length || (!Object.keys(this.state.accountingDocument.lines).length && hasDeposit)
                                                                    ? this.setAccountingDocument({deposit: hasDeposit ? undefined : 0, depositPercentage: undefined}) 
                                                                    : alert(`${isQuot ? 'Le devis' : 'La facture'} doit contenir au moins une ligne avant de pouvoir ajouter un acompte`)
                                                                } 
                                                            />
                                                            
                                                        }
                                                    </span>
                                                </Grid.Column>
                                            }
                                            
                                            {
                                                hasDeposit 
                                                && <Grid.Column textAlign="right" width={8}>
                                                    {
                                                        this.props.type === "quotation"
                                                        ? <span className="txtS">  
                                                            <Dropdown inline value={this.state.accountingDocument.depositPercentage} options={new Array(1,2,3,4,5).map((e) => ({text: `${e*10}%`, value: e*10, key: e*10}))} onChange={this.depositPercentage}/>
                                                            <Input 
                                                                //icon="euro"
                                                                onFocus={() => this.setState({unfixDeposit: true})}
                                                                onBlur={() => this.setState({unfixDeposit: false})}
                                                                value={
                                                                    hasDeposit 
                                                                    ? (
                                                                        this.state.unfixDeposit
                                                                        ? (this.state.accountingDocument.deposit)
                                                                        : (parseFloat(this.state.accountingDocument.deposit) || 0).toFixed(2) 
                                                                    )
                                                                    : ""
                                                                } 
                                                                className="accountingDocumentDepositInput" 
                                                                onChange={(e) => {
                                                                    this.setAccountingDocument({deposit: e.target.value, depositPercentage: 0})
                                                                }}
                                                            />
                                                            <Icon name="euro" size="small" />
                                                        </span>
                                                        : <span>{this.state.accountingDocument.deposit.toFixed(2)} <Icon name="euro" size="small" /></span>
                                                    }
                                                </Grid.Column>
                                            }

                                            {
                                                hasDeposit 
                                                && <Grid.Column className="anthraciteBkg" textAlign="left" width={8}>
                                                    <span className="txtS">Solde à payer</span>
                                                </Grid.Column>
                                            }
                                            {
                                                hasDeposit 
                                                && <Grid.Column className="anthraciteBkg" textAlign="right" width={8}>
                                                    <span className="txtS">{leftToPay} <Icon name="euro" size="small" /></span>
                                                </Grid.Column>
                                            }
                                        </Grid>
                                        {/* TODO :: mention : TVA non applicable, article 293 B du CGI pour les autoentrepreneurs */}
                                    </Segment>
                                </Grid.Column>
                                
                                {/* CLIENT SIGNATURE */}
                                {
                                    isQuot
                                    && <Grid.Column width={16}>
                                        <Segment className="leftAligned accountingDocumentInput">
                                            <p>Faire précéder votre signature de la mention "devis reçu avant exécution des travaux"</p>
                                            <p>Bon pour accord le :</p>
                                            <p style={{padding: '1em 0 2em 0'}}>Client :</p>
                                        </Segment>
                                    </Grid.Column>
                                }
                            </Grid>
                            <div className="flexy flexCenter paddingHorizontal" style={{flexDirection: 'column', marginTop: '5vh'}}>
                                <b>AMC LEBLANC - 6 bis rue de Cremery - 80700 LIANCOURT FOSSE - Tél : 0631706788 - 0322372856</b>
                                <b>amc.leblanc@gmail.com - www.amcleblanc.fr</b>
                                <p className="marged1 txtS">Siret : 51067398100013 / Code APE : 4399C / TVA : FR51510673981</p>
                            </div>
                        </div>
                    </Sidebar.Pusher>
                </Sidebar.Pushable>
            </Modal>
        )
    }

}

const mapClientAndFetchCredentialsToProp = (state) => {
    return({
        customers: state.session.Client.customers,
        fetchCredentials: state.session.fetchCredentials
    })
}

export default connect(mapClientAndFetchCredentialsToProp)(AccountingDocument)