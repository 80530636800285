import React from 'react'
import {Button, Icon} from 'semantic-ui-react'

/**
 * @title Two Buttons
 * @dev Display semantic ui's Button.Group with two Buttons and a middle choice text
 * @notice
 * ## PROPS 
 *  - buttonOne {Object} 
 *      *keys*
 *      - 'color' : button color
 *      - 'className' : button className
 *      - 'title' : button title 
 *      - 'inverted' (bool): (default:false)
 *      - 'clickHandler' (function): button click handler 
 *      - 'icon' (component or enum)
 *  - buttonTwo (idem "buttonOne")
 *  - choiceText (string) : middle choiceText :: default: "ou"
 *  - buttonsClass (string) : class of both buttons (Button.Group)
 * ##
 */
class TwoButtons extends React.Component {

    render() {
        const { buttonOne, buttonTwo, choiceText, buttonsClass, inverted } = this.props

        return(
            <Button.Group className={buttonsClass}>
                <Button onClick={buttonOne.clickHandler} style={{backgroundColor: buttonOne.color || 'green', color: buttonOne.textColor}} className={buttonOne.className+(inverted ? ' ui inverted button' : '')}>{ buttonOne.title || 'Ok' }<span>{buttonOne.icon ? (typeof buttonOne.icon === 'string' ? <Icon name={buttonOne.icon} /> : buttonOne.icon) : ''}</span></Button>
                <Button.Or text={choiceText || 'ou'} />
                <Button inverted={buttonTwo.regular ? false : true} onClick={buttonTwo.clickHandler} style={{backgroundColor: buttonTwo.color || 'red', color: buttonTwo.textColor}} className={buttonTwo.className+(inverted ? ' ui inverted button' : '')}>{ buttonTwo.title || 'Annuler' }<span>{buttonTwo.icon ? (typeof buttonTwo.icon === 'string' ? <Icon name={buttonTwo.icon} /> : buttonTwo.icon) : ''}</span></Button>
            </Button.Group>
        )
    }
}

export default TwoButtons

