import React from 'react';
import Head from './Head'
import { Input, Icon, Button, Grid } from 'semantic-ui-react';
import Utils from '../constants/Utils';
import {connect} from 'react-redux'

/**
 * ##PROPS
 *  - customerName (string) client.customer.name is the name of the customer to link to the event
 *  - onFormSubmitionSuccess (func) called each time server returns a status of 200 after form has been submitted
 *  - onFormSubmitionError (func) called each time server returns a status different from 200 after form has been submitted
 */
class NewCustomerEventForm extends React.Component {
    state = {
        start: undefined,       // days
        end: undefined,
        startTime: undefined,   // hours
        endTime: undefined
    }

    /**
     * @dev computes an agenda/calendar readable object from component state 
     * @notice 
     *  - {start, end, startTime, endTime} is computed as {end, start}
     *  - dates format MUST be : "YYYY-MM-DD HH:MM:SS" 
     * @return {calendar_event} respecting schema and ready to be stored
     */
    computeEventFromState = async() => {
        const {start: _start, end: _end, startTime, endTime, title} = this.state
        let event = await new Promise(async(_event) => {
            let start = `${_start} ${startTime}`
            let end = `${_end} ${endTime}`
            _event({
                title,
                eventId: Utils.keyExtractor(),
                resourceId: 'r1',   // clientèle
                linkedCustomer: this.props.customerName,
                start,
                end,
                addedBy: this.props.client.id,
                resizable: true,
                movable: true,
            })
        })
        return event;
    }

    aknowledgeTitle = (e) => {
        let title = e.target.value
        this.setState({title})
    }
    aknowledgeStart = (e) => {
        let start = e.target.value
        this.setState({start})
    }
    aknowledgeEnd = (e) => {
        let end = e.target.value
        this.setState({end})
    }
    aknowledgeStartTime = (e) => {
        let startTime = e.target.value
        this.setState({startTime})
    }
    aknowledgeEndTime = (e) => {
        let endTime = e.target.value
        this.setState({endTime})
    }

    addEventToClient = async() => {
        let event = await this.computeEventFromState()
        let {error} = await Utils.fetchApi({
            request: "setEvent",
            body: {merge: event},
            client: {
                email: this.props.client.email,
                hash: this.props.client.hash,
                id: this.props.client.id
            }
        })
        
        if (!error) {
            let newEvents = await new Promise((_nE) => {
                let events = this.props.client.events
                if (!(events && events.length)) _nE([])
                else {
                    events.push(event)
                    _nE(events)
                }
            })
            this.props.onFormSubmitionSuccess && this.props.onFormSubmitionSuccess({events: newEvents})
        } else this.props.onFormSubmitionError && this.props.onFormSubmitionError()
    }

    render () {
        const {customerName} = this.props
        return(<div>
                <Head 
                    className="centered"
                    style={{marginTop: '1vh'}}
                    icon="calendar plus outline"
                    title="Nouvel Évènement Client"
                    content={`concernant ${customerName}`}
                />   
                <Grid>
                    <Grid.Column width={16}>
                        
                        <Input
                            icon={<Icon name='tag' />}
                            placeholder="Titre de l'évènement"
                            iconPosition="left"
                            className="marged1"
                            style={{width: '50%'}}
                            onChange={this.aknowledgeTitle}
                            />
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <Head
                            icon="clock outline"
                            title="Du"
                            content="Début de l'évènement"
                        />
                        <div className="flexy flexCenter">
                            <Input
                                type="date"
                                icon={<Icon name='calendar alternate outline' />}
                                iconPosition="left"
                                className="marged1"
                                style={{width: '50%'}}
                                onChange={this.aknowledgeStart}
                            />
                            <Input
                                type="time"
                                placeholder=' Heure de Début'
                                icon={<Icon name='clock' />}
                                iconPosition="left"
                                className="marged1"
                                style={{width: '50%'}}
                                onChange={this.aknowledgeStartTime}
                            />
                        </div>
                        
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <Head
                            icon={<Icon name="clock outline" rotated="clockwise" />}
                            title="Au"
                            content="Fin de l'évènement"
                        />
                        <div className="flexy flexCenter">
                            <Input
                                type="date"
                                icon={<Icon name='calendar alternate outline' />}
                                iconPosition="left"
                                className="marged1"
                                style={{width: '50%'}}
                                onChange={this.aknowledgeEnd}
                            />
                            <Input
                                type="time"
                                placeholder=' Heure de Début'
                                icon={<Icon name='clock' />}
                                iconPosition="left"
                                className="marged1"
                                style={{width: '50%'}}
                                onChange={this.aknowledgeEndTime}
                            />
                        </div>
                    </Grid.Column>
                    <Grid.Row className="centered"><Button onClick={this.addEventToClient}>Ajouter l'évènement</Button></Grid.Row>
                </Grid>

                

                
            </div>
        )
    }

}

const mapClientToProps = (state) => {
    return({
        client: state.session.Client
    })
}

export default connect(mapClientToProps)(NewCustomerEventForm)