const initialState = {
    appBarSetter: undefined,
    session: {
        validity: 0
    }
}

const _globalState = (_state, action) => {
    const state = _state || initialState
    let nextState

    switch(action.type) {

        case 'SET_SESSION' :
            nextState = {
                ...state,
                session: action.value
            }
            return nextState

        case 'SET_CLIENT' : // merge session.Client with action.value {object}
            nextState = {
                ...state, 
                session: {
                    ...state.session, 
                    Client: {
                        ...state.session.Client,
                        ...action.value
                    }
                }
            }
            return nextState

        case 'APP_BAR_SETTER':
            // console.log('app bar setter globalState action', action)
            nextState = {
                ...state, 
                appBarSetter: action.value
            }

            return nextState
        
        case 'DESTROY_SESSION':
            // console.log('destroying session')
            document.cookie = undefined
            nextState = {
                ...state,
                session: {
                    validity: 0
                }
                
            }
            return nextState

        case 'MERGE_SESSION':
            nextState = {
                ...state, 
                session: {
                    ...state.session, 
                    ...action.value
                }
            }
            return nextState
    
        default:
        return state
    }
}

export default _globalState