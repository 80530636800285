import React, {useState} from 'react';
import { Pie } from 'react-chartjs-2';
import Utils from '../constants/Utils'
import Head from '../components/Head';
import AppLoader from '../components/AppLoader';
import { Grid, Segment, Statistic } from 'semantic-ui-react';

import {connect} from 'react-redux'
const Charts = {
    Pie : (props) => <Pie {...props} />
}

const Statistics = (props) => {
    let [state, _setState] = useState({})
    let [loading, setLoading] = useState(false)
    

    const setState = (m, setL) => {
        _setState({...state, ...m})
        typeof setL !== "undefined" && setLoading(setL)
    }

    if (!state.loaded && !loading) {
        (async() => {
            setLoading(true)
            // set App Bar
            props.appBarSetter({
                viewName: "Statistiques",
                searcher: "",
                controllers: "",
                centerController: "",
                bottomController: ""
            })

            // set statistics
            let {error, response: statistics} = await Utils.fetchApi({
                method: 'GET',
                request: 'statistics',
                client: props.session.fetchCredentials

            })
            if (error) {
                console.error(error)
                global.fireMsg({error: 'Il y a eu une erreur lors de la récupération des données, veuillez recharger la page'})
                setState({statistics: [], loaded: true})
            } 
            else {
                
                setState({statistics, loaded: true}, false) // 2nd param false : set loading to false in one operation to avoid double loading of charts's animations
            }           
        })()
    }


    // console.log('state', state, loading)
    return(
        state.loaded
        ? <Grid>
            {
                state.statistics && state.statistics.length && state.statistics.map((d) => {
                    
                    return <Grid.Column key={Utils.keyExtractor()} width={8}>
                        <Segment attached="top" className="centered chartTitle teko">{d.title}</Segment>
                        <Head
                            title={d.subTitle}
                            titleStyle={{fontSize: ".7em"}}
                            className="nomargin"
                            content={<Grid>
                                <Grid.Column width={10}><div>{Charts[d.component]({data:d.data})}</div></Grid.Column>
                                <Grid.Column width={6} textAlign="left">{
                                    typeof d.text === 'object'
                                    ? <ul>{
                                        d.text.map((dt) => <li key={Utils.keyExtractor()}>{dt}</li>)
                                    }</ul>
                                    : <p>{d.text}</p>
                                }</Grid.Column>
                            </Grid>}
                        />
                    </Grid.Column>
                })
            }

            <Grid.Column width={8}>
                <Segment attached="top" className="centered chartTitle teko">L'Équipe AMC Leblanc</Segment>
                <Statistic className="marged5">
                    <Statistic.Label>Membres</Statistic.Label>
                    <Statistic.Value>{props.session.TML ? props.session.TML.length : 'n/c'}</Statistic.Value>
                </Statistic>
            </Grid.Column>
            
           
        </Grid>
        : <AppLoader open={!state.statistics} title="Chargement des statistiques" />
    )
}

export default connect(Utils.mapStateToProps)(Statistics)



    // set datas
    // if (!datas) {
    //     (async() => {
    //         let dtas = await Promise.resolve([
    //             /* RATIO DEVIS SIGNEES / REFUSEES */
    //             {
    //                 title: "Charte One",
    //                 subTitle: "La charte numéro un",
    //                 text: "Cette année vous avez dépensé X€",
    //                 component: "Pie",
    //                 data : {
    //                     labels: [
    //                         'Red',
    //                         'Blue',
    //                         'Yellow'
    //                     ],
    //                     datasets: [{
    //                         data: [300, 50, 100],
    //                         backgroundColor: [
    //                         '#FF6384',
    //                         '#36A2EB',
    //                         '#FFCE56'
    //                         ],
    //                         hoverBackgroundColor: [
    //                         '#FF6384',
    //                         '#36A2EB',
    //                         '#FFCE56'
    //                         ]
    //                     }]
    //                 }
    //             }
    //         ])
    //         setDatas(dtas)
    //     })()
    // }