import React from 'react';
import { Link } from 'react-router-dom'
import {Input, Loader, Button, Icon, Message} from 'semantic-ui-react';
import Head from './Head';
import Utils from '../constants/Utils'
import {connect} from 'react-redux'

/**
 * ##PROPS
 *  - relatedActivity : activityId related to new customer
 *  - close: close newCustomerForm modal
 */
class CrmNewCustomer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isStoring:false,
            missingFields: {
                tel: {missing: true, msg: 'Numéro de téléphone'},
                name: {missing: true, msg: 'Nom Prénom'},
                email: {missing: true, msg: 'Adresse email'},
                address: {missing: true, msg: 'Adresse postale'},
            },
            newContact: {
                activityId: props.relatedActivity
            }
        }
    }


    storeNewCustomer = async() => {
        let clientId = await Promise.resolve(this.props.session.Client.id)

        this.setState({isStoring:true})
        //console.log('storing new contact', this.state.newContact);
        const body = await Promise.resolve({
            ...this.state.newContact, 
            addedOn:Date.now(), 
            addedBy: clientId
        })
        let {error} = await Utils.fetchApi({
            request: "newCustomer",
            body: {...body},
            client: this.props.session.fetchCredentials
        });

        this.setState({isStoring:false});

        global.fireMsg({
            error: error && 'Il y a eu un problème avec l\'enregistrement du nouveau contact, veuillez réessayer.',
            positive: !error && 'Le nouveau client a été ajouté à votre fichier client'
        })
        //alert(!error ? 'Le nouveau client a été ajouté à votre fichier client' : 'Il y a eu un problème avec l\'enregistrement du nouveau contact, veuillez réessayer.')
        // close 
        if(!error) {
            this.props.dispatch({
                type: 'SET_CLIENT',
                value: {customers: [...this.props.session.Client.customers, body]}
            })
            //global.Session.Client.customers = [...global.Session.Client.customers, body]
            this.props.onSave()
            this.props.close()
        }
    }

    aknowledgeName = (name) => { 
        const {missingFields: MFS, newContact} = this.state
        this.setState({newContact: {...newContact, name: name.target.value}, missingFields: {...MFS, name:{missing: !name.target.value, msg: MFS.name.msg} }}) 
    };
    aknowledgeAddress = (address) => {
        const {missingFields: MFS, newContact} = this.state
        this.setState({newContact: {...newContact, address: address.target.value}, missingFields: {...MFS, address:{missing: !address.target.value, msg: MFS.address.msg} }});
    }
    aknowledgeTel = (tel) => {
        const {missingFields: MFS, newContact} = this.state
        this.setState({newContact: {...newContact, tel: tel.target.value}, missingFields: {...MFS, tel:{missing: !tel.target.value, msg: MFS.tel.msg} }});
    }

    aknowledgeEmail = (email) => {
        const {missingFields: MFS, newContact} = this.state
        this.setState({newContact: {...newContact, email: email.target.value}, missingFields: {...MFS, email:{missing: !email.target.value, msg: MFS.email.msg} }});
    }
    // no need for missing fields : notes are not required for contact creation
    aknowledgeNotes = notes => this.setState({newContact: {...this.state.newContact, notes: notes.target.value}});

  
    render() {
        const {newContact, isStoring, missingFields} = this.state
        const {name, address, tel, email, notes} = newContact

        const mfKeys = missingFields && Object.keys(missingFields)
        const fulfilled = (name && address && tel && email) ? true : false
        return (
            isStoring
            ? <div className="flexy flexCenter"><Loader><p className="centered">Enregistrement du nouveau contact en cours, veuillez patienter.</p></Loader></div>
            :
            <div className="flexy flexCenter allWidth margedTop" style={{flexDirection: 'column', width: '100%'}}>
                <Head 
                    icon="user plus"
                    title="Enregistrer un nouveau contact"
                    content={
                        missingFields && mfKeys.length 
                        ? <Message success={fulfilled} warning={!fulfilled}>
                            <Message.Header>{fulfilled ? <div className="positiveText">Prêt à l'envoi <Icon name="check" color="green"/></div> : 'Champs Manquants'}</Message.Header>
                            <Message.List>{
                                Object.keys(missingFields).map((k, ki) => missingFields[k].missing && <Message.Item key={`mis_${ki}`}>{missingFields[k].msg}</Message.Item>)
                            }</Message.List>
                        </Message>
                        : <div className="positiveText">Formulaire prêt à l'envoi <Icon name="check" color="green" /></div>
                    }
                   
                />   
                <Input
                placeholder=' NOM Prénom'
                icon={<Icon name='user plus' />}
                iconPosition="left"
                className="marged1"
                style={{width: '80%'}}
                value={name}
                onChange={this.aknowledgeName}
                />
                <Input
                style={{width: '80%'}}
                placeholder=' Adresse'
                icon={<Icon name='home' />}
                iconPosition="left"
                className="marged1"
                value={address}
                onChange={this.aknowledgeAddress}
                />
                <Input
                style={{width: '80%'}}
                placeholder=' Téléphone'
                icon={<Icon name='phone' />}
                iconPosition="left"
                className="marged1"
                value={tel}
                onChange={this.aknowledgeTel}
                />
                <Input
                style={{width: '80%'}}
                placeholder=' Email'
                icon={<Icon name='at' />}
                iconPosition="left"
                className="marged1"
                value={email}
                onChange={this.aknowledgeEmail}
                />

                <Input
                placeholder=' Notes'
                icon={<Icon name='comments' />}
                iconPosition="left"
                className="marged1"
                style={{height: 100, width: '80%'}}
                value={notes}
                onChange={this.aknowledgeNotes}
                />

                {/* addedBy, addedOn, priority*/}

                {
                    this.props.useLink
                    ? <Link 
                        className="marged5"
                        to={{
                            pathname: this.props.useLink,
                            state: {
                            newCustomer: this.state.newContact
                        }
                    }}>
                        Enregistrer nouveau client
                    </Link>
                    : <Button 
                        raised="true"
                        className="marged5" 
                        onClick={this.storeNewCustomer}
                    >
                        Enregistrer nouveau client
                    </Button>    
                }
            </div>
        )
    }
}

export default connect(Utils.mapStateToProps)(CrmNewCustomer)