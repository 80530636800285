import React, {useState} from 'react'
import { Provider } from 'react-redux'
import Utils from './constants/Utils'
import Navigator from './Navigator'

import Store from './store/configureStore'

/* Stylesheets */
import './styles/global.css';
import 'semantic-ui-css/semantic.min.css'

const App = () => {
    let [awake, setAwake] = useState(false)

   
    if (!awake) {
        Utils.fetchApi({
            method: 'get',
            request: "wakeUp"
        }, {
        mockGlobal: {session:{Client:{
            email: process.env.REACT_APP_WEBSITE_MAGIC,
            hash: process.env.REACT_APP_WORK_SECRET
        }}},
        caseSensitive: true
        })
        .then(({error, response: awoke}) => {
            //awoke ? console.log('* server is on *') : console.error('* server did not wake up *')

            // try only once
            setAwake(true)
        })
        
        
    }
    

    return <Provider store={Store}>
        <Navigator />
    </Provider>
}
            
export default App