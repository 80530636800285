import React, {useState} from 'react';
import { Link } from 'react-router-dom'
import {Icon, Dropdown, Grid, Input, Table, Button, Divider} from 'semantic-ui-react'
import Utils from '../constants/Utils'
import Searcher from '../components/Searcher'
import CornerIcons from '../components/CornerIcons'
import AccountingDocument from '../components/AccountingDocument';
import Head from '../components/Head';
import moment from 'moment';
import ModifiableItem from '../components/ModifiableItem';
import { connect } from 'react-redux'

const depositModeSelectorOptions = [
    {text: "espèces", value: "cash"},
    {text: "chèque", value: "bankCheck"},
    {text: "virement sepa", value: "sepa"},
    {text: "virement bancaire", value: "transfer"},
]

const BillIcon = (props) => <CornerIcons 
    {...props}
    size="large"  
    one="file alternate outline" 
    two="edit"
    colorTwo="yellow"
/>
//const addNewIcon = ({onClick}) => <CornerIcons corner="top right" size="large" key={Utils.keyExtractor()} className="overable margedLeft" one={billIcon} two="add" colorTwo="teal" onClick={onClick}/>


const Bills = (props) => {

    let [state, _setState] = useState({})
    let [bills, setBills] = useState([])
    let [urlRequestTreated, setUrlRequestTreated] = useState(false)
    let [settingRequest, setSettingRequest] = useState(false)
    let [byStatusSortingOption, setByStatusSortingOption] = useState(undefined)

    const setState = (st) => _setState({...state, ...st})
    ///// SEARCHER
    const editProductsList = () => {

    }

    const onUpdateSearch = () => {

    }
    /////

    const BillStatus = {
        awaitingTransmission: {icon: "envelope", color: "red", title: "Non transmis", text: "En attente de transmission"},
        transmitted: {icon: "half hourglass", color: "yellow", title: "En attente", text: "En attente de paiement"},
        paid: {icon: "check", color: "green", title: "Acquittée", text: "Acquittée"},
    }

    const bilStatus = (status) => {
        let qs = BillStatus[status] || {}
        return <Head
            size="small"
            contentStyle={{width: '100%'}}
            titleClassName="teko silver"
            title={qs.title}
            content={<Icon style={{margin: 0}} name={qs.icon} color={qs.color} />}
        />
    }
    
    ///// SORTING
    const sortByDate = async() => {
        const {dateFrom, dateTo} = state
        let sorted = await Promise.resolve(
            bills.filter((b) => ( (b.timestamp >= new Date(dateFrom).getTime()) && (b.timestamp <= new Date(dateTo).getTime()) ) )
        )
        //console.log('sorted', sorted)
        setBills(sorted)
    }

    const sortByStatus = async(byStatusSorting) => {
        //console.log(`sorting bills by status ${byStatusSorting}`)
        let sorted = await Promise.resolve(
            bills.filter((b) => ( b.status === byStatusSorting ))
        )
        //console.log('sorted', sorted)
        setBills(sorted)
    }
    /////

    /* INITIALIZATION */
    ///// SET APP BAR
    const sortOptions = [
        {text: "Date", value: 'date', key: 'sb_date'},
        {text: "Status", value: 'status', key: 'sb_status'},
    ];

    const sorter = {
        date: <div key={Utils.keyExtractor()} className="margedLeft">
            Du <Input value={state.dateFrom} type="date" onChange={(e) => setState({dateFrom: e.target.value})} /> Au <Input value={state.dateTo} type="date" onChange={(e) => setState({dateTo: e.target.value})}/>
            {
                (state.dateFrom && state.dateTo)
                && <span><Icon style={{marginLeft: 3}} size="large" name="circle check outline" color="teal" onClick={sortByDate} /></span>
            }
        </div>,
        status: <div key={Utils.keyExtractor()} className="margedLeft">
            <Dropdown
                options={
                    Object.keys(BillStatus).map((bs) => ({
                        key: bs,
                        text: BillStatus[bs].text,
                        value: bs,
                        content: <span><Icon name={BillStatus[bs].icon} color={BillStatus[bs].color} size="tiny"/> {BillStatus[bs].text}</span>
                    }))
                }
                trigger={byStatusSortingOption && <span><Icon color={BillStatus[byStatusSortingOption].color} name={BillStatus[byStatusSortingOption].icon} size="small" />{ BillStatus[byStatusSortingOption].title}</span>}
                onChange={(e, {value}) => {
                    sortByStatus(value)
                    setByStatusSortingOption(value)

                }}
            />
        </div>
    }

    const editDocument = async() => {
        let openedDocumentActivity = await Utils.activityFromId(state.openedBill.activityId); 
        setState({editOpenedDocument: true, openedDocumentActivity})
    }

    !state.appBarSet && (async() => {
        // set app bar
        props.appBarSetter(
            {
                centerController: "",
                bottomController: "",
                viewName: "Vos factures",
                controllers: [
                    <Icon key={Utils.keyExtractor()} className="overable" size="large" name="arrow left" onClick={() => setState({openedBill: undefined, sorting: undefined/*, createNew: false*/})} />,
                    !state.openedBill && <span key={Utils.keyExtractor()} className="margedLeft txtM">Trier par {' '} <Dropdown inline options={sortOptions} value={state.sorting} onChange={(e, {value: sorting}) => setState({sorting})}/></span>,
                    (
                        state.sorting && !state.openedBill
                        ? sorter[state.sorting]
                        : ""
                    ),
                    state.openedBill && <BillIcon key={Utils.keyExtractor()} className="margedLeft overable" onClick={editDocument} />
                    //addNewIcon({onClick: () => setState({createNew: true})}),
                    //deleteIcon({onClick: () => this.removeOpenedProduct()})
                ],
                searcher: <Searcher 
                    data={bills}
                    dataLen={bills && bills.length}
                    dataName="facture"
                    editList={editProductsList.bind(this)}
                    onUpdateSearch={onUpdateSearch.bind(this)}
                />
                
            }
        );
        setState({appBarSet: true})

        // set bills
        const {error, response: accounting} = await Utils.fetchApi({
            request: "accounting",
            method: 'GET',
            client: props.session.fetchCredentials
        })

        if (error) global.fireMsg({error})
        else {
            setBills(accounting.bills)
        }
    })()
    /////
    ///// GET BILLS
    if (typeof bills === 'undefined') {
        (async() => {
            let {error, response: actg} = await Utils.fetchApi({
                request: 'accounting',
                method: 'GET',
                client: props.session.fetchCredentials

            })
            if (error) {
                console.error('Bills : Erreur lors de la récupération de vos factures', error)
                global.fireMsg({error})
            }
            else {
                //console.log('BILLS', actg.bills)
                setBills(actg.bills)
                // reset openedBill (will display updated version of the bill)
                if (state.openedBill) {
                    if (actg.bills && actg.bills.length) {
                        for (let b_ of actg.bills) {
                            ((bil) => {
                                if (bil.timestamp === state.openedBill.timestamp)
                                    setState({openedBill: bil})
                            })(b_)
                        }
                    } else {
                        // bills have been emptied
                        setState({openedBill: false})
                    }
                }
            }
        })()
    } //else console.log('* bills loaded *', bills)

    /// NEW URL OPEN REQUEST (using <Link /> location prop)
    // Conditions to treat an 'open' request
    !settingRequest                     // component is not treating the request
    && !urlRequestTreated               // component has not treat the request yet
    && props.location                   // component received a location prop
    && props.location.state             // location prop has state 
    && props.location.state.open        // location prop state contains an 'open' request
    && bills.length                     // component has loaded some bills
    && !state.openedBill                // component is not displaying any bill at the moment
    && (async() => {

        setSettingRequest(true)

        let _bill = await Promise.resolve(bills.filter((b) => b.file.originalname === props.location.state.open))
        _bill.length && _bill[0] && openBill(_bill[0])

        setUrlRequestTreated(true)
    })()
    /////

    // /// DEPRECATED URL OPEN REQUEST 
    // !settingRequest && !urlRequestTreated && window.location.search && bills.length && !state.openedBill && (async() => {
    //     setSettingRequest(true)

    //     let request = await Promise.resolve(Utils.spliceFromTo({
    //         data: window.location.search,
    //         from: "?",
    //         to : "=",
    //         include: false
    //     }))
    //     let reqValue = await Promise.resolve((window.location.search.indexOf('=') >= 0) && window.location.search.split('=')[1].replace(/%20/g," ").replace(/%C3%A9/g,"é"))


    //     if (reqValue && (request==='open')) {

    //         let _bill = await Promise.resolve(bills.filter((b) => b.file.originalname === reqValue))
    //         _bill.length && _bill[0] && openBill(_bill[0])
    //         setUrlRequestTreated(true)

    //     }
    // })()
    // /////

                                    /* OPERATIONS */

    const validateEdition = () => {
        // validating edition....

        
        setState({isEditing: false})
    }

  

    const StatusSelection = ({value, onChange}) => <Dropdown 
        onChange={onChange}
        inline 
        options={Object.keys(BillStatus).map((sk) => ({text: BillStatus[sk].text, value: sk, key: sk}))}
        text={value}  
    />

    const openBill = (openedBill) => {
        setState({openedBill, appBarSet: false})
    }

    const addDeposit = async() => {
        const {newDepositAmount, newDepositMode, newDepositDate} = state
        const {openedBill: bill} = state
        //console.log(`---> adding new deposit of ${newDepositAmount}€ by ${newDepositMode} on bill "${bill.file.originalname}"`)
        // fetch api TODO
        const amount = await Promise.resolve(parseFloat(newDepositAmount))
        const {error, response} = await Utils.fetchApi({
            request: "addDeposit",
            body: {
                fileName: bill.file.originalname,
                deposit: {
                    date: newDepositDate || Date.now(),
                    amount,
                    mode: newDepositMode,
                }
            },
            client: props.session.fetchCredentials

        })
        if (error) console.error('addDeposit ERROR', error)
        else 
            setBills(undefined) // reset updated bills list

        global.fireMsg({error: error && `Erreur lors de l'enregistrement du règlement sur la ${bill.file.originalname} : ${error}`})
        
    }

    const DepositModeSelector = ({onChange, value}) => 
        <Dropdown 
            onChange={onChange}
            inline 
            options={depositModeSelectorOptions.map((e) => ({...e, key: e.value}))}  // {text, key, value}
            text={value ? depositModeSelectorOptions.filter((o) => o.value === value)[0].text : ""}
        />
    

    /* CONTENT */
    ///// BILL TABLE LINE
    const billLine = (bil) => 
        <Table.Row key={Utils.keyExtractor()} className="zoomOnHoverLight" onClick={() => openBill(bil)}>
            {/* RÉFÉRENCE */}
            <Table.Cell>
                {bil.file.originalname}
            </Table.Cell>
            
            {/* DATE */}
            <Table.Cell>
                {bil.date}
            </Table.Cell>

            {/* CLIENT */}
            <Table.Cell>
                {bil.customerName}
            </Table.Cell>
            
            {/* ACTIVITY */}
            <Table.Cell>
                {bil.activityName}
            </Table.Cell>

            {/* TOTAL HT */}
            <Table.Cell textAlign="right">
                {(bil.totals.ht).toFixed(2)}
            </Table.Cell>

            {/* STATUS */}
            <Table.Cell textAlign="center">
                {
                    bilStatus(bil.status)
                }
            </Table.Cell>

             {/* TRANSMIS */}
             <Table.Cell textAlign="center">
                
                <Icon name="send" color={(bil.status === "paid" || bil.status === "transmitted") ? "teal" : "red"} />
                
            </Table.Cell> 
        </Table.Row>
    
    /////
    
    const toTimestamp = (date, _then) => {
        _then({target:{value: new Date(date).getTime()}})
    }
    

    //// OPENED BILL RENDERER ////
    const billFile = (bil) => <Grid key={bil.file.originalname}>
        <Grid.Column width={6}>
            <iframe src={bil.file.location} className="documentFrame" />
        </Grid.Column>

        <Grid.Column width={10}>
            <Head
                title={bil.file.originalname}
                icon="file alternate outline"
                contentStyle={{textAlign: 'left'}} 
                content={
                    <div style={{fontSize:'.7em'}}>
                        <div><b>Établi le</b> { bil.date } <span> par { Utils.teamMemberFromId(bil.addedBy, {returnKey:"username"}, props.session.TML) }</span></div>
                        <div><b>Modifié le</b> { bil.modifiedOn ? moment(bil.modifiedOn).format('LL') : 'n/c' } <span> par { Utils.teamMemberFromId(bil.modifiedBy, {returnKey:"username"}, props.session.TML) }</span></div>
                    </div>
                }
            />

            
            <ul className="margedLeft">
              
                <li>
                    {/**/}
                    <Head
                        titleClassName="allWidth block leftAligned"
                        size="small"
                        icon={<Icon name={BillStatus[bil.status].icon} color={BillStatus[bil.status || "processing"].color} />}
                        title="Status"
                        content={
                            <StatusSelection 
                                value={BillStatus[bil.status].text}
                                onChange={async(e, {value: status}) => {
                                    if (status !== bil.status) {
                                        const {error, response} = await Utils.fetchApi({
                                            request: "setAccountingDocumentStatus",
                                            body: {
                                                type: 'bill',
                                                year: new Date(bil.timestamp).getFullYear(),
                                                reference: bil.reference,
                                                status
                                            },
                                            client: props.session.fetchCredentials

                                        })
                                        global.fireMsg({error, positive: !error && response && "Le status du document a été mis à jour"})
                                        if (!error) {
                                            // re-upload bills list
                                            setBills(undefined)
                                            // reset openedBill
                                            if (state.openedBill) {
                                                //console.log('---> *** resetting openedBill ***', response)
                                                setState({openedBill: response/*{...state.openedBill, status}*/})
                                            }
                                        } else console.error(error)
                                    }
                                }}
                            />
                        }
                    />
                   
                </li>

                {
                    bil.referencedDocument
                    && <Divider />
                }

                {
                    bil.referencedDocument
                    && <li>
                        <Head
                            titleClassName="allWidth block leftAligned"
                            size="small"
                            title="Document en référence"
                            content={
                                // <Button onClick={() => window.open(`/quotations?open=${bil.referencedDocument}`, '_blank')} className="appButton">Ouvrir {Utils.majFirstChar(bil.referencedDocument)}</Button>
                                <Link to={{
                                    pathname: "/quotations",
                                    state: {
                                        open: bil.referencedDocument
                                    }
                                }}>
                                    <Button className="appButton">Ouvrir {Utils.majFirstChar(bil.referencedDocument)}</Button>
                                </Link>                            
                            }
                            icon="file"
                        />
                    </li>
                }

<Divider />

<li>
    
            <Head
                titleClassName="allWidth block leftAligned"
                size="small"
                title="Opérations comptable"
                contentClassName="centered padded3"
                content={
                    <Table celled>
                        <Table.Header>
                            <Table.Row className="centered">
                                <Table.HeaderCell>Date</Table.HeaderCell>
                                <Table.HeaderCell>Mode de règlement {state.newDepositAmount && state.newDepositMode && <Icon className="overable" size="large" name="check circle outline" color="teal" onClick={addDeposit}/>}</Table.HeaderCell>
                                <Table.HeaderCell>Montant réglé</Table.HeaderCell>
                                <Table.HeaderCell>Reste dû</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {
                                (bil.payments && bil.payments.length)
                                ? (
                                    bil.payments
                                    .map((p) => <Table.Row key={`payment_${p.date}`} style={{fontSize: '.9em'}}>
                                        <Table.Cell>{moment(p.date).format('DD/MM/YYYY')}</Table.Cell>
                                        <Table.Cell>{p.mode}</Table.Cell>
                                        <Table.Cell>{parseFloat(p.amount).toFixed(2)} €</Table.Cell>
                                        <Table.Cell>{parseFloat(p.leftToPay).toFixed(2)} €</Table.Cell>
                                    </Table.Row>)
                                )
                                : <Table.Row warning>
                                    <Table.Cell>{moment(Date.now()).format('DD/MM/YYYY')}</Table.Cell>
                                    <Table.Cell colSpan={3} className="centered">Aucun règlement n'a été enregistré à ce jour</Table.Cell>
                                </Table.Row>
                            }
                            {
                                <Table.Row key="newDeposit">
                                    <Table.Cell>
                                        <ModifiableItem
                                            valueFontSize={14}
                                            pair={{key: "newDepositDate", oldValue: Date.now()}}
                                            renderValue={(value) => moment(value).format('DD/MM/YYYY')}
                                            customEditionComponent={(changeHandler, value) => <Input type="date" onChange={(e) => toTimestamp(e.target.value, changeHandler)} />}
                                            changeMethodAsync={async({newValue}) => { setState({newDepositDate: new Date(newValue).getTime()}); return {response: true}}}
                                        />
                                    </Table.Cell>
                                    <Table.Cell><DepositModeSelector onChange={(e, {value}) => setState({newDepositMode: value})} value={state.newDepositMode} /></Table.Cell>
                                    <Table.Cell><Input icon="euro" value={state.newDepositAmount} onChange={(e) => setState({newDepositAmount: e.target.value})} /></Table.Cell>
                                    <Table.Cell positive={((bil.totals.ttc - (bil.deposit || 0)).toFixed(2) - (state.newDepositAmount || 0)) === 0} negative={((bil.totals.ttc - (bil.deposit || 0)).toFixed(2) - (state.newDepositAmount || 0)) < 0}>{(bil.totals.ttc - (bil.deposit || 0) - (state.newDepositAmount || 0)).toFixed(2)} €</Table.Cell>
                                </Table.Row> 

                            }
                            
                            
                        </Table.Body>
                    </Table>
                }
                icon="euro"
            />
      
    
</li>

                <Divider />

                <li>
                    <Head
                        titleClassName="allWidth block leftAligned"
                        size="small"
                        title="Dossier"
                        content={
                            // <Button onClick={() => window.open(`/activities?open=${bil.activityId}`, '_blank')} className="appButton">Ouvrir le dossier {bil.activityName}</Button>
                            <Link to={{
                                pathname: "/activities",
                                state: {
                                    open: bil.activityId
                                }
                            }}>
                                <Button className="appButton">ouvrir le dossier {bil.activityName}</Button>
                            </Link> 
                        }
                        icon="folder open"
                    />
                </li>
                
            </ul>
        </Grid.Column>
        

    </Grid>
    
    //console.log("Bills bills", bills)
    return(
        
            state.editOpenedDocument
            ? <AccountingDocument 
                type="bill" 
                onClose={() => window.confirm('Abandonner l\'édition de facture en cours ?') && setState({editOpenedDocument: false})}
                activity={state.openedDocumentActivity}
                updateActivities={() => setBills(undefined)}
                accountingDocument={state.openedBill}
            />
            : (
                state.openedBill
                ? billFile(state.openedBill)
                : <Table celled>
                    <Table.Header style={{textAlign: 'left'}}>
                        <Table.Row className="centered">
                            <Table.HeaderCell width={2}>Référence </Table.HeaderCell>
                            <Table.HeaderCell width={2}>Date</Table.HeaderCell> 
                            <Table.HeaderCell width={2}>Client</Table.HeaderCell> 
                            <Table.HeaderCell width={4}>Dossier</Table.HeaderCell> 
                            <Table.HeaderCell width={1}>Total HT</Table.HeaderCell>
                            <Table.HeaderCell width={2}>Status</Table.HeaderCell>
                            <Table.HeaderCell width={1}>Transmis</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body style={{overflow: 'scroll'}}>
                        {
                            bills && bills.length 
                            ? bills.map((b) => billLine(b))
                            : null
                        }
                    </Table.Body>
                    {
                        ((!bills || !bills.length))
                        && <Table.Footer className="flexy flexCenter">Aucune facture listée</Table.Footer>
                    }
                </Table>  
            )
         
       
    )
}
export default connect(Utils.mapStateToProps)(Bills);