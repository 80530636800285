
import React from 'react'
import Cropper from 'react-cropper'
import 'cropperjs/dist/cropper.css'; // see installation section above for versions of NPM older than 3.0.0

import { Segment, Icon } from 'semantic-ui-react';
/**
 * ##PROPS
 *  - src : image source
 *  - onCrop (function) : callback when crop operation ends (receives dataUrl string)
 */
export default class ImageCropper extends React.Component {
    state = {
        ref: React.createRef(null),
        dataUrl: undefined
    }
    
    _onCrop = () => {
        //console.log('this.state.ref', this.state.ref)
        const dataUrl = this.state.ref.current.cropper.getCroppedCanvas().toDataURL()
        //console.log('dataUrl', dataUrl)
        this.setState({dataUrl})
        //this.props.onCrop(dataUrl)
    }

    validateCropping = () => {
        const {onCrop} = this.props;
        if (typeof onCrop === 'function') {
            onCrop(this.state.dataUrl)
        } else console.error("ImageCropper Error: 'onCrop' prop is missing")
    }

    render() {
        return(<div>
            <Cropper
                //ready={(e) => console.log('CROPPER READY', e)}
                className="opacNestedImage"
                ref={this.state.ref}
                src={this.props.src}
                style={{width: '100%', height: 'auto'}}
                crop={this._onCrop.bind(this)}
            />
            <Segment attached="bottom">
                <Icon name="check" color="teal" onClick={this.validateCropping} />
            </Segment>
            </div>
        )
    }

}