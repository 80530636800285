import React from 'react'
import Utils from '../constants/Utils'
import Colors from '../constants/Colors'
import { Loader, Dimmer, Icon } from 'semantic-ui-react';
import ResponsiveDesktop from '../components/ResponsiveDesktop'
import AppLoader from '../components/AppLoader';
import Head from '../components/Head';

import { connect } from 'react-redux'

import Dropzone from 'react-dropzone'
import axios from 'axios';


class Database extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            //privateDb: false 
            privateDb: props.accreditations 
            ? (props.accreditations.publicDb ? false : true) // *
            : false  
            /* 
                *: access has been authorized, client must be one of
                    - a manager ()
                    - a non manager with access accreditations for : 
                        - publicDb or privateDb or both

            */
        }
    }

    // only used to determine 'mutualFolder' when receiveFiles is invoked
    openedFolder = undefined

    setAppBar = () => {
        //// console.log('ACCREDITATIONS', this.props.accreditations)
        const {appBarSetter, selectionMode} = this.props
        //let data = this.dbAssets()
        //// console.log('data', data)
        if (typeof appBarSetter === 'function' && !selectionMode) 
            appBarSetter({
                viewName:`Base de données ${this.state.privateDb ? 'privée' : 'entreprise'}`,
                controllers: (this.props.session.Client.licence === 'manager' || (this.props.accreditations && (this.props.accreditations.privateDb && this.props.accreditations.publicDb)))  
                ? [
                    <div key={Utils.keyExtractor()} /*onChange={this.toggleDatabase.bind(this)}*/ className="ui toggle checkbox">    
                        <input type="checkbox" className="database" onChange={this.toggleDatabase.bind(this)} checked={this.state.privateDb ? true : false} />
                        <label className="coloring red txtM"><span className="silver">Aller à </span>{this.state.privateDb ? 'Base entreprise' : 'Base privée'}</label>
                    </div>
                ]
                : "",
                centerController:<Dropzone onDrop={this.receiveFiles}>
                    {({getRootProps, getInputProps}) => (
                        <section>
                            <div {...getRootProps({multiple: true})}>
                                <input {...getInputProps({multiple: true})} />
                                {/* <p><Icon name="download" /> Ajouter une image de fond</p> */}
                                <Head
                                    size="large"
                                    title="Ajouter un fichier"
                                    className="flexCenter tekoAll"
                                    content="glissez un fichier ou un dossier ou cliquez"
                                    icon={<Icon name="upload" />}
                                />
                            </div>
                        </section>
                    )}
                </Dropzone>,
                searcher: '',
                bottomController:  ''
            })
    }

    async componentDidMount() {

        let AppMirrors = await this.getAppMirrors()
        let PersoMirrors = this.props.session.Client.mirrors
        this.setState({loading: false, AppMirrors, PersoMirrors})
        this.setAppBar()

    }

    componentDidUpdate() {
        const {resetAppBar} = this.state
        if (resetAppBar) {
            this.setState({resetAppBar: false})
            this.setAppBar()
        }
    }

    receiveFiles = async(files, rejected) => {
        const {email, hash} = this.props.session.Client
        // console.log(`this.openedFolder : ${this.openedFolder}`)
        if(!files && rejected) console.error('receiveFiles error', rejected)
        else {
            let hasS = files.length > 1 
            global.fireMsg({positive: <span><Icon name="cog" loading/> Enregistrement de {files.length} fichier{hasS ? "s" : ""}</span>})
            // console.log('---> received files', files)
            /* mutualFolder (will set all files within a mutualFolder) : 
                case 1- the uploaded item is a folder
                case 2- the uploaded item is a folder OR one or several files and it has been uploaded within an existing folder
            */
            // case 1  
            let isFolder = await Promise.resolve(files && files.length && files[0].path.split('/').length > 1)
            // console.log(`---> client uploaded a ${isFolder ? 'folder' : 'file'}`) 

            // case 1 or 2
            let mutualFolder = this.openedFolder || await Promise.resolve(isFolder ? files[0].path.split('/')[1] : undefined)
            // console.log(`---> store files within ${mutualFolder || 'root folder'}`)

            // prepare a form for axios
            let formData = new FormData();
            // distribute all files that multer will trigger
            let fdFiles = await new Promise((fdf) => {
                for (const file of files) {
                    formData.append('fileData', file);

                    if (file === files[files.length-1])
                    fdf(formData)
                }
            })
            // distribute keys: privateDb, options(mutualFolder, givenName(*notused*))
            let fdDb = await new Promise((fddb) => {
                this.state.privateDb && fdFiles.append('privateDb', true)
                setTimeout(() => fddb(fdFiles))
            })
            let fdFolder = await new Promise((fdfolder) => {
                mutualFolder && fdDb.append('options', JSON.stringify({mutualFolder}))
                setTimeout(() => fdfolder(fdDb))
            })
            // console.log('Sending form containing files to server : ', fdFolder)
            // give all files the folder name
            axios
            .post(`${process.env.REACT_APP_API_URL}api?credentials=${email.toLowerCase()}::${hash}&request=createMirrors`, fdFolder)
            //.post('https://amcleblanc.herokuapp.com/api?credentials='+email.toLowerCase()+'::'+hash+'&request=newRealisation', formData)
            .then(res => {
                // console.log('AXIOS response', res)
                this.propagateChangedDatas({
                    successMsg: `${files.length} nouveau${hasS ? 'x' : ''} fichier${hasS ? 's' : ''} enregistré${hasS ? 's' : ''}`
                })
            })
            .catch(err => {
                console.error('publish AXIOS error:', err)
                this.propagateChangedDatas({
                    error: `Il y a eu une erreur lors de l'enregistrement de ${files.length} nouveau${hasS ? 'x' : ''} fichier${hasS ? 's' : ''}`
                })
            });
        }
    }

    toggleDatabase = () => {
        const {privateDb} = this.state
        this.setState({privateDb : !privateDb, resetAppBar: true})
    }

    dbAssets = () => {
        const { AppMirrors, PersoMirrors, privateDb } = this.state;
 
        return (privateDb ? PersoMirrors : AppMirrors)
    }  

    getAppMirrors = async() => {
        let Mirrors = await Utils.fetchApi({
            method: 'GET',
            request: 'mirrors',
            client: this.props.session.fetchCredentials

        })

        if (Mirrors.error) {
            global.fireMsg({error:'Erreur : La récupération de vos données a échouée, veuillez réessayer'})
            return([])
        } 
        else return Mirrors.response
    }

    getPersoMirrors = async() => {
        let {response:client} = await Utils.fetchApi({
            method: 'GET',
            request: 'client',
            client: this.props.session.fetchCredentials

        })

        return client.mirrors
    }

    resetData = async () => {
 
        let AppMirrors = await this.getAppMirrors()
        let PersoMirrors = await this.getPersoMirrors()      
  
        this.setState({AppMirrors, PersoMirrors, forceDataChange: true, avoidReset: true})
    }

    propagateChangedDatas = async({error, response, successMsg}) => {
        !error && this.resetData()
        successMsg && global.fireMsg({positive: successMsg})
    }

    _onDeleteFile = async(file) => {
        const {privateDb} = this.state
        let msg = await Promise.resolve(`Souhaitez-vous réellement supprimer le fichier ${file.givenName || file.originalname} ${file.folder ? ('situé dans le dossier '+file.folder) : ''} de la base de donnée ${privateDb ? 'privée' : 'public'} ?`)
        if(window.confirm(msg)) {
            (async() => { 
                let deleted = await Utils.fetchApi({
                    body: {
                        key: file.key,
                        privateDb: privateDb // todo: check licence type on server and restrict method access if public database (!pdb)
                    },
                    request: 'deleteFile', 
                    method: 'POST',
                    client: this.props.session.fetchCredentials

                })

            
                this.propagateChangedDatas({...deleted, successMsg: `Le fichier ${file.givenName || file.originalname} a été supprimé`})
            })()
        }
    }

    /**
     * @param folder (string) is the folder name to erase
     */
    _onDeleteFolder = async(folder) => {
        let msg = `Souhaitez-vous réellement supprimer définitivement le dossier ${folder} ainsi que tout son contenu ?` 
        if(window.confirm(msg)) {
            (async() => { 
                const {privateDb} = this.state
                let deleted = await Utils.fetchApi({
                    body: {
                    folder,
                    privateDb
                    },
                    request: 'deleteFolder',
                    method: 'POST',
                    client: this.props.session.fetchCredentials

                })

                this.propagateChangedDatas({...deleted, successMsg: `Le dossier ${folder} a été supprimé`})
            })()
        }
    }

    setFolderOpened = (openedFolder) => { 
        // console.log(`Database.js : setFolderOpened : ${openedFolder}`)
        this.openedFolder = openedFolder 
    }

    render() {
        const { privateDb, forceDataChange } = this.state;
        const _borderColor = privateDb ? Colors.quetzalGreen : Colors.amcred
        const usedMirrors = this.dbAssets() // array
        //// console.log('database state', this.state)
        return(
            this.state.loading 
            //? <Dimmer active><Loader>Chargement de la base de données en cours</Loader></Dimmer>
            ? <AppLoader
                open={this.state.loading}
                title="Chargement de la base de données en cours"
                subTitle="importation des documents"
            />
            : <ResponsiveDesktop 
                selectionMode={this.props.selectionMode}
                onFileSelected={this.props.onFileSelected}
                data={ usedMirrors }
                personalDb={privateDb}
                borderColor={ _borderColor }
                _onDeleteFile= { this._onDeleteFile.bind(this) }
                _onDeleteFolder = {this._onDeleteFolder.bind(this)}
                _resetforceDataChange= { () => { this.setState({forceDataChange: false}) } }
                forceDataChange={ forceDataChange }
                setFolderOpened={this.setFolderOpened.bind(this)}
                toggleDatabase={this.toggleDatabase.bind(this)}
            />
        )
    }

}

export default connect(Utils.mapStateToProps)(Database)
