import React from 'react'
import {Image} from 'semantic-ui-react'

const Icons = {
    application: require("../images/desktop/application.png"),
    audio: require("../images/desktop/audio.png"),  
    text: require("../images/desktop/text.png"),  
    video: require("../images/desktop/video.png"), 
    image: require("../images/desktop/image.png"), 
    css: require("../images/desktop/css.png"),  
    doc: require("../images/desktop/doc.png"),  
    html: require("../images/desktop/html.png"),  
    jpg: require("../images/desktop/jpg.png"),  
    mp3: require("../images/desktop/mp3.png"),  
    mp4: require("../images/desktop/mp4.png"),  
    pdf: require("../images/desktop/pdf.png"),  
    png: require("../images/desktop/png.png"),  
    xls: require("../images/desktop/xls.png"),
    default: require('../images/desktop/default.png')   
}

const FileIcon = (props) => <Image className="centered" style={{maxHeight:75, maxWidth:50}} size="tiny" src={Icons[props.extension]} />

//const FileIcon = (props) => <View style={Styles.desktopIcon}><ResponsiveImage source={Icons[props.extension]}/></View>
export default FileIcon



