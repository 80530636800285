import React, {useState} from 'react';
import { Grid, Label, Icon, Modal } from 'semantic-ui-react'
import Dropzone from 'react-dropzone'
import Head from './Head'
import FileSelector from './FileSelector'
import Utils from '../constants/Utils';
import Layout from '../constants/Layout'
import Drawer from './Drawer';
import AppLoader from './AppLoader'
import {connect} from 'react-redux'

/**
 * ##PROPS
 *  - onSelect (function) (required) : callback function called when a file is selected *({src, fileName, rejected})=>{}* MUST return an error msg {error} (msg is display)
 */
const ImageImport = (props) => {

    let [state, _setState] = useState({})
    const setState = (m) => _setState({...state, ...m})

    /* HANDLER */
    const indicateLoading = () => {
        
        global.fireMsg({positive: <span><Icon name="cog" loading/> Téléchargement de 1 fichier</span>})
    }

    const receiveFile = async(file, rejected, _originalname) => {
        
       console.log('* receiveFile *')
       console.log('file', file)
       console.log('rejected', rejected)
       console.log('_originalname', _originalname)

        if(!file && rejected) {
            console.error('receiveFile error', rejected)
            global.fireMsg({error: "Le téléchargement de fichier a échoué, veuillez recommencer"})
        }
        else {
            setState({loading: true})

            let src = typeof file === 'object'
                ? await Utils.storeFile({file}, props.fetchCredentials) // will return src
                : file
                
            const originalname = await Promise.resolve(
                typeof _originalname === 'object' 
                    ? src.split('/').filter((se, i) => i === src.split('/').length-1).join('')
                    : _originalname || file.name
            )
            console.log('originalname', originalname)
            // treatment
            await props.onSelect({src, fileName: originalname, rejected : !file || !src})
            setState({loading: false})
            return true
        }
    }

    

    /////////////

    /* COMPONENTS */

    const dropzone = <Dropzone onDrop={(files, rej) => receiveFile(files[0], rej)}>
        {({getRootProps, getInputProps}) => (
            <section>
                <div {...getRootProps({multiple: false})}>
                    <input {...getInputProps({multiple: false})} />
                    {/* <p><Icon name="download" /> Ajouter une image de fond</p> */}
                    <Head
                        size="small"
                        title="Ajouter un fichier"
                        className="flexCenter tekoAll"
                        content="glissez ou cliquez"
                        icon={<Icon name="upload" />}
                    />
                </div>
            </section>
        )}
    </Dropzone>

    const fileselector = <FileSelector
        onChange={(e, originalname) => receiveFile(e.target.value, !e.target.value, originalname)}
        headerProps={{size: "small", titleStyle: {fontSize: '1em'}}}
        showLabels={true}
    />

    /////////////// style={{fontSize: 15}}
    const label = (content) => <Label attached="top" className="centered" style={{fontSize: 17, margin: 0}} pointing="below" color="grey">{content}</Label>

    return(
        <div>
            <AppLoader
                open={state.loading}
                title={"Import de fichier en cours"}
                subTitle={"le temps de téléchargement dépend de votre connexion ainsi que de la taille du fichier"}
            />
            <Grid>
                <Grid.Column width={8}>
                    {
                        label(Layout.isTable ? "TABLETTE" : (Layout.isSmallDevice ? "TÉLÉPHONE" : "ORDINATEUR"))
                    }
                    {/* <Label attached="top" className="centered" style={{fontSize: 15}} pointing="below" color="grey">{Layout.isTable ? "TABLETTE" : (Layout.isSmallDevice ? "TÉLÉPHONE" : "ORDINATEUR")}</Label> */}
                                
                    {/* <Input type="file" multiple onChange={(e) => setState({files: e.target.files})} /> */}
                    <div className="padded1">{
                        dropzone
                    }</div>
                
                </Grid.Column>
                <Grid.Column width={8}>
                    {
                        label("DESSINER")
                    }
                    {/* <Label attached="top" className="centered" pointing="below" color="grey">DESSINER</Label> */}
                    <div className="cardField noBorder flexy flexCenter" onClick={() => setState({drawing: true})}>
                        <Icon size="huge" name="paint brush" />
                    </div>
                </Grid.Column>
                <Grid.Column width={16} className="noPadding flexy flexCenter">
                    {
                        fileselector
                    }
                </Grid.Column>
            </Grid>
            <Modal size="fullscreen" open={state.drawing}>
                <Drawer 
                    onSave={async(b64) => {
                        let file = await Utils.toFile(b64, `croquis${Date.now()}`)
                        await receiveFile(file, !b64 || !file)
                        setState({drawing: false})
                    }}
                />
            </Modal>
        </div>
    )

}

export default connect(Utils.mapFetchCredentialsToProp)(ImageImport)