import React, { useState } from 'react'
import { Grid, Input, Modal, Label } from 'semantic-ui-react'
import Head from './Head'
import CornerIcons from './CornerIcons'
import Database from '../views/Database'
import Utils from '../constants/Utils'
import Cropper from './ImageCropper'
import {connect} from 'react-redux'
/**
 * ##PROPS
 *  - onChange (function): method called each time a file is selected *(e)=>{e.target.value}* 
 *  - headerProps {object}: props object for component Header
 *  - showLabels (bool): show pointing down labels (default: false)
 */
const FileSelector = (props) => {
    console.log('FileSelector props', props)

    let [selectFile, setSelectFile] = useState(false)
    let [state, _setState] = useState({})

    const setState = (mergeObj) => _setState({...state, ...mergeObj})
    /**
     * @dev ResponsiveDesktop onFileSelected Handler 
     * @notice #FLOW: 
     *  - 1: Receives the file key of a file that is already stored within an S3 bucket (file with a mirror / restrictions applied)
     *  - 2: Compute the base64 url of the file
     *  - 3: Add it to a formData
     *  - 4: Send to server with axios
     *  - 5: Get back the unrestricted url 
     *  - 6: Store url within state.newTM.avatar
     * 
     * @param key The unique S3 file key
     */
    const storeFileGetLink = async(e) => {
        let file = e.target.value
        let throwErr = (err) => {
            console.error("readFile error", err)
            global.fireMsg({error: "Il y a eu une erreur lors de la récupération de votre image dans votre base de données. Veuillez recommencer l'opération"})
        } 

        // 1
        let {err1, response: limitedSource} = await Utils.fetchApi({
            request : 'readFile',
            body : file,
            client: props.fetchCredentials
        })

        if (err1) throwErr(err1)

        else if (limitedSource) {
            // get b64 from limited Url Source
            let canvas = document.createElement('canvas')
            let ctx = canvas.getContext('2d')

            let img = new Image()
            img.crossOrigin = "anonymous"
            img.onload = async(e) => {
                try {
                    canvas.width = img.width;
                    canvas.height = img.height;
        
                    ctx.drawImage(img, 0, 0);
                    let b64 = await Promise.resolve(canvas.toDataURL('image/png'))
        
        
                    // show image cropper to receive source, then keep going within "storeLimitedSource" below
                    setState({showCropper: true, limitedSource: b64, originalname: file.originalname})
                } catch(err) {
                    console.error('storeFileGetLink ERROR', err)
                }
                
            }
            img.src = limitedSource
        }
        
    }

    const toggleSelectFile = () => {
        setSelectFile(!selectFile)
    }

    //
    const storeLimitedSource = async(b64) => {
        const {originalname} = state
        let res = await Utils.storeFile({b64, originalname}, props.fetchCredentials)
        //hide database
        toggleSelectFile()
        // hide cropper
        setState({showCropper: false})
        // call onChange prop with data url base 64 cropped source 
        props.onChange( { target: { value: res } }, originalname )

        
    }
  

    const hasValue = props.value && props.value !== ""
    const label = (content) => <Label attached="top" className="centered" style={{fontSize: 17, margin: 0}} pointing="below" color="grey">{content}</Label>
    return (
        
        state.showCropper
        ? <Modal open={state.showCropper}><Cropper src={state.limitedSource} onCrop={storeLimitedSource} /></Modal>
        : (
            selectFile 
            ? <Database selectionMode={true} onFileSelected={storeFileGetLink} />
            : <Grid>
                {/* <Grid.Column width={8}>
                    {
                        props.showLabels && label("LIEN VERS UNE IMAGE")//<Label attached="top" className="centered" pointing="below" color="grey">LIEN VERS UNE IMAGE</Label>

                    }
                    <div className="padded1">
                        <Input className="allWidth" placeholder="Coller l'adresse de l'image ici" onChange={props.onChange} value={typeof props.value !== 'string' ? "" : props.value}/>
                    </div>
                </Grid.Column> */}
                <Grid.Row onClick={toggleSelectFile}>
                    {
                        props.showLabels && label("BASE DE DONNÉES")//<Label attached="top" className="centered" pointing="below" color="grey">BASE DE DONNÉES</Label>

                    }
                    <Head
                        className="flexCenter overable padded1"
                        size="medium"
                        title={!props.showLabels && "Importer une image depuis votre base de données"}
                        icon={hasValue ? <CornerIcons one="download" two="check" colorTwo="green" /> : "download"}
                        content={hasValue ? "1 fichier importé" : "cliquez ici"}
                        {...(props.headerProps || {})}
                    />
                </Grid.Row>
            </Grid>
        )
    )
}

export default connect(Utils.mapFetchCredentialsToProp)(FileSelector)