import React, {useState} from 'react';
import { Link } from 'react-router-dom'
import {Icon, Dropdown, Grid, Input, Table, Button, Divider} from 'semantic-ui-react'
import Utils from '../constants/Utils'
import Searcher from '../components/Searcher'
import CornerIcons from '../components/CornerIcons'
import AccountingDocument from '../components/AccountingDocument';
import Head from '../components/Head';
import moment from 'moment';

import {connect} from 'react-redux'

const QuotationIcon = (props) => <CornerIcons 
    {...props}
    size="large"  
    one="file alternate outline" 
    two="edit"
    colorTwo="yellow"
/>
//const addNewIcon = ({onClick}) => <CornerIcons corner="top right" size="large" key={Utils.keyExtractor()} className="overable margedLeft" one={quotationIcon} two="add" colorTwo="teal" onClick={onClick}/>


const Quotations = (props) => {

    let [state, _setState] = useState({})
    let [quotations, setQuotations] = useState([])
    let [remoteReqTreated, setRemoteReqTreated] = useState(false)

    const QuotStatus = {
        awaitingTransmission: {icon: "envelope", color: "red", title: "Non transmis", text: "En attente de transmission au client"},
        transmitted: {icon: "half hourglass", color: "yellow", title: "En attente", text: "Transmis, en attente d'une réponse client"},
        signed: {icon: "check", color: "green", title: "Accepté", text: "Le client a signé le devis"},
        denied: {icon: "close", color: "red", title: "Refusé", text: "Le client a répondu défavorablement au devis"}
    }

    const setState = (st) => _setState({...state, ...st})

    ///// SEARCHER
    const editProductsList = () => {

    }

    const onUpdateSearch = () => {

    }
    /////
    
    ///// SORTING
    const sortByDate = async() => {
        const {dateFrom, dateTo} = state
        // console.log(`sorting quotations from ${dateFrom} to ${dateTo}`)
        let sorted = await Promise.resolve(
            quotations.filter((q) => ( (q.timestamp >= new Date(dateFrom).getTime()) && (q.timestamp <= new Date(dateTo).getTime()) ) )
        )
        // console.log('sorted', sorted)
        setQuotations(sorted)
    }

    const sortByStatus = async(byStatusSorting) => {
        // console.log(`sorting quotations by status ${byStatusSorting}`)
        let sorted = await Promise.resolve(
            quotations.filter((q) => ( q.status === byStatusSorting ))
        )
        // console.log('sorted', sorted)
        setQuotations(sorted)
    }
    /////

    /* INITIALIZATION */
    ///// SET APP BAR
    const sortOptions = [
        {text: "Date", value: 'date', key: 'sb_date'},
        {text: "Status", value: 'status', key: 'sb_status'},
    ];

    const sorter = {
        date: <div key={Utils.keyExtractor()} className="margedLeft">
            Du <Input value={state.dateFrom} type="date" onChange={(e) => setState({dateFrom: e.target.value})} /> Au <Input type="date" value={state.dateTo} onChange={(e) => setState({dateTo: e.target.value})}/>
            {
                (state.dateFrom && state.dateTo)
                && <span><Icon style={{marginLeft: 3}} size="large" className="overable" name="circle check outline" color="teal" onClick={sortByDate} /></span>
            }
        </div>,
        status: <div key={Utils.keyExtractor()} className="margedLeft">

            <Dropdown
                options={
                    Object.keys(QuotStatus).map((qs) => ({
                        key: qs,
                        text: QuotStatus[qs].text,
                        value: qs,
                        content: <span><Icon name={QuotStatus[qs].icon} color={QuotStatus[qs].color} size="tiny"/> {QuotStatus[qs].text}</span>
                    }))
                }
                text={state.sorting}
                onChange={(e, {value}) => {
                    sortByStatus(value)
                }}
            />
           
        </div>
    }

    const editDocument = async() => {
        let openedDocumentActivity = await Utils.activityFromId(state.openedQuotation.activityId, null, props.session.fetchCredentials); 
        setState({editOpenedDocument: true, openedDocumentActivity})
    }

    const quotationFromFileName = async(fileName) => {
        let Q = await Promise.resolve(quotations.filter((q) => q.file.originalname === fileName))
        return ((Q && Q.length) ? Q[0] : {unfound : true})
    }

    !state.appBarSet && (async() => {
        // console.log('---> Quotations: setting app bar while state...', state)
        // set app bar
        props.appBarSetter(
            {
                viewName: "Vos devis",
                controllers: [
                    <Icon key={Utils.keyExtractor()} className="overable" size="large" name="arrow left" onClick={() => setState({openedQuotation: undefined/*, createNew: false*/})} />,
                    !state.openedQuotation && <span key={Utils.keyExtractor()} className="margedLeft txtM">Trier par {' '} <Dropdown inline options={sortOptions} value={state.sorting} onChange={(e, {value: sorting}) => setState({sorting})}/></span>,
                    (
                        state.sorting && !state.openedQuotation
                        ? sorter[state.sorting]
                        : ""
                    ),
                    state.openedQuotation && <QuotationIcon className="margedLeft overable" onClick={editDocument} />
                    //addNewIcon({onClick: () => setState({createNew: true})}),
                    //deleteIcon({onClick: () => this.removeOpenedProduct()})
                ],
                searcher: <Searcher 
                    data={quotations}
                    dataLen={quotations && quotations.length}
                    dataName="devis"
                    editList={editProductsList.bind(this)}
                    onUpdateSearch={onUpdateSearch.bind(this)}
                />,
                centerController: "",
                bottomController: ""
                
            }
        );
        setState({appBarSet: true})

        // set quotations
        // console.log('---> fetching and setting quotations')
        const {error, response:accounting} = await Utils.fetchApi({
            request: "accounting",
            method: 'GET',
            client: props.session.fetchCredentials

        })

        if (error) global.fireMsg({error})
        else {
            // console.log('*** QUOTATIONS ***', accounting.quotations)
            setQuotations(accounting.quotations)
        }
    })()
    /////
    ///// GET QUOTATIONS
    if (typeof quotations === 'undefined') {
        (async() => {
            // console.log('---> re / fetching quotations')
            let {error, response: actg} = await Utils.fetchApi({
                request: 'accounting',
                method: 'GET',
                client: props.session.fetchCredentials

            })
            if (error) {
                console.error('Quotations : Erreur lors de la récupération de vos devis', error)
                global.fireMsg({error})
            }
            else {
                //// console.log('QUOTATIONS', actg.quotations)
                setQuotations(actg.quotations)
                // reset openedQuotation (will display updated version of the quotation)
                if (state.openedQuotation) {
                    if (actg.quotations && actg.quotations.length) {
                        for (let q_ of actg.quotations) {
                            ((quot) => {
                                if (quot.timestamp === state.openedQuotation.timestamp)
                                    setState({openedQuotation: quot})
                            })(q_)
                        }
                    } else {
                        // quotations have been emptied
                        setState({openedQuotation: false})
                    }
                }
            }
        })()
    } //else // console.log('* quotations loaded *', quotations)


        // NEW look up for remote requests (using <Link /> location prop)
        !remoteReqTreated 
        && props.location 
        && props.location.state 
        && props.location.state.open 
        && !state.openedQuotation 
        && quotations.length 
        && (async() => {
            setRemoteReqTreated(true)
            let openedQuotation = await quotationFromFileName(props.location.state.open)// quotationFromFileName

            !openedQuotation.unfound
            && setState({openedQuotation, appBarSet: false})
        })()
        /////


    // // DEPRECATED look up for url search
    // (async() => {
    //     const {search} = window.location
    //     if (search && !remoteReqTreated) {
    //         quotations.length && setRemoteReqTreated(true)

    //         //// console.log('window.location.search', search)
    //         //
    //         let request = await Promise.resolve(Utils.spliceFromTo({
    //             data: search,
    //             from: "?",
    //             to : "=",
    //             include: false
    //         }))
    //         let reqValue = await Promise.resolve((search.indexOf('=') >= 0) && search.split('=')[1].replace(/%20/g," ").replace(/%C3%A9/g,"é"))


    //         if (reqValue && (request==='open') && !state.openedQuotation) {
    //             let openedQuotation = await quotationFromFileName(reqValue)// quotationFromFileName

    //             !openedQuotation.unfound
    //             && setState({openedQuotation})

                
    //         }
    //     }
    // })()
    // /////

                                    /* OPERATIONS */
 

    // const validateEdition = () => {
    //     // validating edition....

        
    //     setState({isEditing: false})
    // }

   

    const StatusSelection = ({value, onChange}) => <Dropdown 
        onChange={onChange}
        inline 
        options={Object.keys(QuotStatus).filter((k) => k!=="awaitingTransmission").map((sk) => ({text: QuotStatus[sk].text, value: sk, key: sk}))}
        text={value}  
    />

    const quotStatus = (status) => {
        let qs = QuotStatus[status] || {}
        return <Head
            size="small"
            contentStyle={{width: '100%'}}
            titleClassName="teko silver"
            title={qs.title}
            content={<Icon style={{margin: 0}} name={qs.icon} color={qs.color} />}
        />
    }

    const openQuotation = (openedQuotation) => {
        setState({openedQuotation, appBarSet: false})
    }


    /* CONTENT */
    ///// QUOTATION TABLE LINE
    const quotationLine = (quot) => 
        <Table.Row key={Utils.keyExtractor()} className="zoomOnHoverLight" onClick={() => openQuotation(quot)}>
            {/* RÉFÉRENCE */}
            <Table.Cell>
                {quot.file.originalname}
            </Table.Cell>
            
            {/* DATE */}
            <Table.Cell>
                {quot.date}
            </Table.Cell>

            {/* CLIENT */}
            <Table.Cell>
                {quot.customerName}
            </Table.Cell>
            
            {/* ACTIVITY */}
            <Table.Cell>
                {quot.activityName}
            </Table.Cell>

            {/* TOTAL HT */}
            <Table.Cell textAlign="right">
                {(quot.totals.ht).toFixed(2)}
            </Table.Cell>

            {/* STATUS */}
            <Table.Cell textAlign="center">
                {
                    quotStatus(quot.status)
                }
            </Table.Cell>

             {/* TRANSMIS */}
             <Table.Cell textAlign="center">
                
                <Icon name="send" color={(quot.status === "transmitted" || quot.status === "signed" || quot.status === "denied") ? "teal" : "red"} />
                
            </Table.Cell>

           
            
        </Table.Row>
    
    /////

    //// QUOTATION EDITION ////
    const quotationFile = (quot) => <Grid key={Utils.keyExtractor()}>
        <Grid.Column width={8}>
            <Head
                title={quot.file.originalname}
                icon="file alternate outline"
                contentStyle={{textAlign: 'left'}}
                content={
                    <ul>
                        <li><span className="bold">Etabli le</span><span className="silver"> {quot.date}</span></li>
                        <li><span className="bold">Etabli par</span><span className="silver"> {Utils.teamMemberFromId(quot.addedBy, {returnKey:"username"}, props.session.TML)}</span></li>

                        <li><span className="bold">Modifié le</span><span className="silver"> {quot.modifiedOn ? moment(quot.modifiedOn).format('LL') : 'n/c'}</span></li>
                        <li><span className="bold">Modifié par</span><span className="silver"> {Utils.teamMemberFromId(quot.modifiedBy, {returnKey:"username"}, props.session.TML)}</span></li>
                    </ul>
                }
            />

            
            <ul>
              
                <li>
                    {/**/}
                    <Head
                        titleClassName="allWidth block leftAligned"
                        size="small"
                        icon={<Icon name={QuotStatus[quot.status].icon} color={QuotStatus[quot.status].color} />}
                        title="Status"
                        content={
                            // is NOT the StatusSelection.js component
                            quot.status === 'denied'
                            ? <span>{QuotStatus.denied.text}</span>
                            : <StatusSelection 
                                type="quotation"
                                value={QuotStatus[quot.status].text}
                                onChange={async(e, {value: status}) => {
                                    if (status !== quot.status) {
                                      
                                        const {error, response} = await Utils.fetchApi({
                                            request: "setAccountingDocumentStatus",
                                            body: {
                                                type: 'quotation',
                                                year: new Date(quot.timestamp).getFullYear(),
                                                reference: quot.reference,
                                                status
                                            },
                                            client: props.session.fetchCredentials

                                        })
                                        // console.log('*** RESPONSE ***', response)
                                        
                                        global.fireMsg({error, positive: !error && response && "Le document a été mis à jour"})
                                        if (!error) {
                                            // re-upload quotations list
                                            setQuotations(undefined)
                                            // reset openedQuotation
                                            if (state.openedQuotation) {
                                                // console.log('---> *** resetting openedQuotation ***', response)
                                                setState({openedQuotation: response/*{...state.openedQuotation, status}*/})
                                            }
                                        } else console.error(error)
                                    }
                                }}
                            />
                        }
                    />
                   
                </li>

                <Divider />

                <li>
                    <Head
                        titleClassName="allWidth block leftAligned"
                        size="small"
                        title="Dossier"
                        // content={<Button onClick={() => window.open(`/activities?open=${quot.activityId}`, '_blank')} className="appButton">ouvrir le dossier {quot.activityName}</Button>}
                        content={
                            <Link to={{
                                pathname: "/activities",
                                state: {
                                    open: quot.activityId
                                }
                            }}>
                                <Button className="appButton">ouvrir le dossier {quot.activityName}</Button>
                            </Link>
                        }
                        icon="folder open"
                    />
                </li>
                
            </ul>
        </Grid.Column>
        <Grid.Column width={8}>
            <iframe src={quot.file.location} className="documentFrame" />
        </Grid.Column>

    </Grid>
    

    
    // console.log("state", state)

    return(
        // state.createNew 
        // ? <AccountingDocument
        //     type="quotation"
        //     onClose={() => setState({createNew: false})}
        //     activity={{}}
        //     updateActivities={() => alert('updateActivities')}
        // />
        //: (
            state.editOpenedDocument
            ? <AccountingDocument 
                type="quotation" 
                onClose={() => setState({editOpenedDocument: false})}
                activity={state.openedDocumentActivity}
                updateActivities={() => setQuotations(undefined)}
                accountingDocument={state.openedQuotation}
            />
            : (
                state.openedQuotation
                ? quotationFile(state.openedQuotation)
                : <Table celled>
                    <Table.Header style={{textAlign: 'left'}}>
                        <Table.Row className="centered">
                            <Table.HeaderCell width={2}>Référence </Table.HeaderCell>
                            <Table.HeaderCell width={2}>Date</Table.HeaderCell> 
                            <Table.HeaderCell width={2}>Client</Table.HeaderCell> 
                            <Table.HeaderCell width={4}>Dossier</Table.HeaderCell> 
                            <Table.HeaderCell width={1}>Total HT</Table.HeaderCell>
                            <Table.HeaderCell width={2}>Status</Table.HeaderCell>
                            <Table.HeaderCell width={1}>Transmis</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body style={{overflow: 'scroll'}}>
                        {
                            quotations && quotations.length 
                            ? quotations.map((q) => quotationLine(q))
                            : null
                        }
                    </Table.Body>

                    {
                        (!quotations || !quotations.length)
                        && <Table.Footer className="flexy flexCenter">Aucun devis listé</Table.Footer>
                    }
                </Table>  
            )
        //)  
       
    )
}
export default connect(Utils.mapStateToProps)(Quotations);