import React from 'react'
import { Icon, Grid, Button, Divider, Image, Label, Segment, Modal, Input, Menu } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import Utils from '../constants/Utils'
import Head from './Head'
import SimpleMap from './SimpleMap'
import ModifiableItem from './ModifiableItem'
import PhotoReport from './PhotoReport'
import AnimatedButton from './AnimatedButton'
import CornerIcons from './CornerIcons'
import Drawer from './Drawer'
import Axios from 'axios'
import CustomersSelection from './CustomersSelection'
import StatusSelection from './StatusSelection'
import AccountingDocument from './AccountingDocument'
import Database from '../views/Database'
import {connect} from 'react-redux'
import FileSelector from './FileSelector'

const quotationTab = [
    { name:'Devis', tabInView: 'quotations', icon: <CornerIcons circular={false} corner={false} one="file outline" two={<Icon style={{padding: '2px 4px 0px 0px'}} size="tiny">D</Icon>} />},
]

const billTab = [
    { name:'Factures', tabInView: 'bills', icon: <CornerIcons circular={false} corner={false} one="file outline" two={<Icon style={{padding: '2px 4px 0px 0px'}} size="tiny">F</Icon>} /> },   //icons: ["image outline", "paint brush"]
]

const RestrictedTabs = [
    ...quotationTab,
    ...billTab,
    // { name:'Devis', tabInView: 'quotations', icon: <CornerIcons circular={false} corner={false} one="file outline" two={<Icon style={{padding: '2px 4px 0px 0px'}} size="tiny">D</Icon>} />},
    // { name:'Factures', tabInView: 'bills', icon: <CornerIcons circular={false} corner={false} one="file outline" two={<Icon style={{padding: '2px 4px 0px 0px'}} size="tiny">F</Icon>} /> },   //icons: ["image outline", "paint brush"]
    { name: "Supprimer", tabInView: 'remove', icon:"x", className:"vibrate" }
]

const UnrestrictedTabs = [
    { name:'Infos', tabInView: 'infos', icon:"info" },                  //icons: ["clipboard outline", "info"]
    { name:"Fichiers", tabInView: 'files', icon:"file" },               //icons: ["folder open outline", "file"]
    { name:'Rapports Photos', tabInView: 'reports', icon:"photo" },     //icons: ["file alternate outline", "photo"]
    { name:'Historique', tabInView: 'historic', icon:"history" },

]

const billDerogation = (access) => [
    ...(access && access.billRight) 
    ? billTab
    : []
]
const quotationDerogation = (access) => [
    ...(access && access.quotationRight) 
    ? quotationTab
    : []
]

const Tabs = (licence, access) => licence && licence === 'manager'
? [
    ...UnrestrictedTabs,
    ...RestrictedTabs
]
: [
    ...UnrestrictedTabs,
    ...quotationDerogation(access),
    ...billDerogation(access)
]

const ColDivider = <Grid.Column width={16}><Divider /></Grid.Column>
    
/**
 * ##PROPS
 *  - activity {object} 
 *  - updateActivities (function): is the updateActivities method from Activities.js
 *  - currentStatusIcon (func)
 *  - removeActivity (func) : async method called to remove the opened activity (removeOpenedActivity method from activities.js)
 */
class ActivityFile extends React.Component {
    constructor(props) {
        super(props);
    
        this.state = {
            tabInView: 'infos'    // current opened tab
        }

        this.setTabInView = this.setTabInView.bind(this)
        this.closeNewReportView = this.closeNewReportView.bind(this)

        this.setAppBar()
    }

    componentWillUnmount() {

        this.setAppBar(true)
    }

    setAppBar = async(remove) => {
        const {licence, access} = this.props.session.Client

        const _Tabs = await Promise.resolve(Tabs(licence, access))
        let s = !remove ? {searcher: ""} : {}
        this.props.appBarSetter({
            bottomController: remove
            ? undefined
            : <div style={{marginTop: -35}}>
            <Menu className="margedTop" pointing secondary>
                {
                    _Tabs.map((t) => t 
                        ? <Menu.Item
                            key={`menu_${t.tabInView}`} 
                            style={{fontSize: 17}}
                            className={t.className}
                            // Account settings tab
                            name={t.name}
                            active={this.state.tabInView === t.tabInView}    
                            onClick={() => this.setTabInView(t.tabInView)}
                            icon={typeof t.icon !== "string" ? t.icon : <Icon name={t.icon} size="large" />}
                        />
                        : null
                    )
                }
             
            </Menu>
          </div>,
          ...s
        })
    }

    /* HELPERS */
    tabName = (tabInView) => new Promise((resolve) => {
        const _Tabs = Tabs(this.props.session.Client.licence, this.props.session.Client.access)
        _Tabs.forEach((t) => {
            if (t.tabInView === tabInView)
            resolve(t.name) 
        })
    })

    closeNewReportView = (noMsg) => {
        if (!noMsg || typeof noMsg !== 'boolean') {
            if (window.confirm('Abandonner le rapport en cours ?')) {
                this.setState({createNewReport: false})
            }
        } else this.setState({createNewReport: false})
    }

    /********************************************************* CRUDS *********************************************************/  
    removeReport = async(report) => {
        if (window.confirm(`Souhaitez-vous supprimer définitivement le rapport ${report.originalname || ""} ?`)) {
            let {error} = await Utils.fetchApi({
                request: 'activityOperations',
                body: {requestType: 'removeReport', key: report.key, activityId: this.props.activity.activityId},
                client: this.props.session.fetchCredentials
            })
            if (error) {
                let errMsg = "Le rapport n'a peut-être pas été supprimé"
                global.fireMsg({error: errMsg})
                //alert(errMsg)
            } else {
                let success = "Le rapport a été supprimé"
                this.props.updateActivities()
                // suppress locally (avoid fetching server)
                

                setTimeout(() => global.fireMsg({positive: success}));
                //alert(success)
            }
        }
    }

    deleteActivityFile = async(key) => {
        let {error} = await Utils.fetchApi({
            request: 'activityOperations', 
            body : {requestType: 'deleteFilesSelection', activityId: this.props.activity.activityId, deleteFilesList: [{Key:key}]},
            client: this.props.session.fetchCredentials
        })
        if (error) {
            console.error(error)
            alert("Il y a eu une erreur lors de l'opération, veuillez réessayer.")
        } else {
            this.props.updateActivities()
            setTimeout(() => global.fireMsg({positive: 'fichier supprimé'}));
        }
    }

    addFilesToActivity = (files) => {
        if (window.confirm(`Souhaitez-vous enregistrer ${files.length} fichier${files.length>1 ? 's' : ''} ?`)) {
            const {email, hash} = this.props.session.Client

            let formData = new FormData()
            formData.append('requestType', "uploadActivityFiles")
            formData.append('activityId', this.props.activity.activityId)
            for (const file of files) {
                formData.append('fileData', file);
            }
            
            setTimeout(() => {
                //console.log('formData', formData.entries())
                Axios
                .post(`${process.env.REACT_APP_API_URL}api?credentials=${email.toLowerCase()}::${hash}&request=activityOperations`, formData)
                //.post('https://amcleblanc.herokuapp.com/api?credentials='+email.toLowerCase()+'::'+hash+'&request=activityOperations', formData)
                .then(res => {
                    //console.log('axios response', res)
                    let s = files.length > 1 ? 's' : ''
                    let positive = `${files.length} fichier${s} ajouté${s}`;
                    this.setState({addFiles: false})          // close new files pop up
                    this.props.updateActivities()

                    setTimeout(() => global.fireMsg({positive}));      // indicate success

                })
                .catch(err => {
                    console.error(err)
                    alert("Il y a eu une erreur lors de l'enregistrement, veuillez réessayer")
                });
            })
        }
    }

    saveDrawing = async(drawing) => {
        let fileName = prompt('Nom du fichier')
       
        if (fileName && drawing) {
            const {error} = await Utils.fetchApi({
                request: 'activityOperations',
                body: {
                    drawing,
                    fileName,
                    requestType: 'uploadDrawing',
                    activityId: this.props.activity.activityId
                },
                client: this.props.session.fetchCredentials
            })

            if (error) {
                console.error(error)
                alert("Il y a eu une erreur lors de l'enregistrement du croquis, veuillez réessayer")
            } else {
                this.props.updateActivities()
                this.setState({createNewDrawing: false, showDrawer: false})
                setTimeout(() => global.fireMsg({positive: "Croquis ajouté"}));      // indicate success
            }
        }
    }

    saveQuotation = async(quotation) => {
        const {activity} = this.props
        if (quotation) {
            let fileName = `devis_${(activity.quotations || []).length + 1}`
            const {error} = await Utils.fetchApi({
                request: 'activityOperations',
                body: {
                    quotation,
                    fileName,
                    requestType: 'saveQuotation',
                    activityId: this.props.activity.activityId
                },
                client: this.props.session.fetchCredentials
            })

            if (error) {
                console.error(error)
                alert("Il y a eu une erreur lors de l'enregistrement du croquis, veuillez réessayer")
            } else {
                this.props.updateActivities()
                this.setState({createNewDrawing: false})
                setTimeout(() => global.fireMsg({positive: "Croquis ajouté"}));      // indicate success
            }
        }
    }

    removeQuotation = async(key, name) => {
        
        if (window.confirm(`Supprimer définitivement ${name} ?`)) {
            const {error} = await Utils.fetchApi({
                request: 'removeAccountingDocument', 
                body: {
                    type: 'quotation',
                    activityId: this.props.activity.activityId,
                    key
                },
                client: this.props.session.fetchCredentials
            })
            if (error) {
                console.error('removeAccountingDocument ERROR', error)
                global.fireMsg({error: `Erreur lors de la suppression du devis, veuillez recommencer`})
            } else {
                this.props.updateActivities()
                setTimeout(() => {global.fireMsg({positive: "Devis supprimé"})})
            }
        }
    }

    
    renderHistoric = ({title, addedBy, icon, timestamp}) => <span>
        <Head 
            size="small"   
            style={{width: '100%'}} 
            contentStyle={{textAlign: 'left'}}
            key={Utils.keyExtractor()} 
            icon={icon ? <Icon name={icon} /> : undefined} 
            title={title} 
            content={<div>
                <p style={{color: 'silver'}}>{`Le ${Utils.formatedDate(new Date(timestamp), true)}`}</p>
                <p style={{color: 'silver'}}>{`Par ${Utils.teamMemberFromId(addedBy, {returnKey: 'username'}, this.props.session.TML)}`}</p>
            </div>} 
        />
        <Divider />
    </span>

    confirmedStatusChange = async({type, name, status}) => {
        let {error} = await Utils.fetchApi({
            request: "setAccountingDocumentStatus",
            body: {
                type: type,        // "quotation" or "bill"
                year: parseInt(name.split('-')[1]),    // 2019
                reference: parseInt(name.split('-')[2]).toString(),
                historicRequest: `${status}${Utils.majFirstChar(type)}`,    //ex.:  quotationSigned, billTransmitted, ...
                status
            },
            client: this.props.session.fetchCredentials
        })

        if (error) 
            console.error("setAccountingDocumentStatus ERROR", error)
        else {
            this.props.updateActivities()
        }
        global.fireMsg({error: error && "Il y a eu une erreur lors de la mis à jour du status, veuillez recommencer l'opération", positive: !error && "Status du document et état du dossier à jour"})
        
    }

    /**
     * @dev sets an action button for each document that must be sent to customer.
     * @return a clickable button to execute next logic action depending on document state (send to customer, indicate signature, indicate denial)
     */
    nextActionIcon = ({type, status, name}) => {
        const actionItems = {
            aT: {
                'awaitingTransmission': {
                    icon: "send",
                    color: "yellow",
                    tooltip: "Envoyer le document au client",
                    confirmation: "Confirmer l'envoi du document au client ?",
                    nextStatus: "transmitted"
                }
            },
            T: {
                'transmitted': [
                    {
                        icon: "pencil alternate",
                        color: "green",
                        tooltip: "Le client a signé le devis",
                        confirmation: `Confirmer la signature du ${name}?`,
                        nextStatus: "signed"

                    },
                    {
                        icon: "pencil alternate",
                        color: "red",
                        tooltip: "Le client a refusé le devis",
                        confirmation: `Confirmer le refus du ${name} ?`,
                        nextStatus: "denied"

                    }
                ]
            }
        }
        const actions = {
            bill : {
                ...actionItems.aT
            },
            quotation : {
                ...actionItems.aT,
                ...actionItems.T
            }
        }

        const item = actions[type][status]
        const actIcon = (_item) => <Icon 
            circular 
            inverted 
            className="overable"
            name={_item.icon}
            color={_item.color}
            //data-tooltip={_item.tooltip}
            onClick={() => {
                if (window.confirm(_item.confirmation)) {
                    this.confirmedStatusChange({type, name, status: _item.nextStatus})
                }
            }}
        />
        return (
            item
            ? (
                Utils.ofType(item) === 'array'
                ? item.map((it) => actIcon(it))
                : actIcon(item)
            )
            : null
        )
    }
        // bill actions : create => transmit
        // quotation actions : create => transmit => accepted OR denied
    
    ///////////////
    infosTab = () => {
        const { lastModified, name, openedOn, location, activityId, status, notes, bills, customerName } = this.props.activity

        return <div className="padded1 tekoAll" style={{fontSize: '1.3em'}}>
            <Grid centered>
                <Grid.Column width={5}>
                    <b className="red">Informations</b>
                </Grid.Column>
                <Grid.Column width={1} textAlign="right">
                    <Icon name="info" size="large" color="grey" />
                </Grid.Column>
                <Grid.Column width={8}>
                    <Head
                        size="small"
                        title={<span><span style={{fontSize: "1em", color: "grey"}}>Nom du dossier : </span>{name}</span>}
                        content={
                            <ul>
                                {
                                    openedOn && <li>Créé le {Utils.formatedDate(new Date(Number(openedOn)), true)}</li>
                                }
                                {
                                    lastModified && <li>Modifié le {Utils.formatedDate(new Date(Number(lastModified)), true)}</li>
                                }
                            </ul>
                        }
                    />
                </Grid.Column>

                {ColDivider}

                <Grid.Column width={5}>
                    <b className="red">État du dossier</b>
                </Grid.Column>
                <Grid.Column width={1} textAlign="right">
                    <Icon name="star" size="large" color="grey" />
                </Grid.Column>
                <Grid.Column width={8}>
                <Head
                    size="small"
                    textAlign="left"
                    title={<span>État actuel</span>}
                    contentStyle={{marginLeft: '5%'}}
                    subHeaderStyle={{fontSize: "1.5em"}}
                    content={
                        <div>
                            {
                                /*
                                    case: (!status || noDatas) => if (files || notes || photoReports) => "en cours de traitement" else "en file d'attente" 
                                    case : (status && status === 'signed') && !(bills && bills.length) => 'signedNoBill' => "En attente de réalisation"
                                    else ActivityStatus[status]
                                */
                                this.props.currentStatusIcon 
                                && this.props.currentStatusIcon(this.props.activity, true, true)
                            }
                            {
                                (status === 'signed' && !(bills && bills.length))
                                && <span className="silver"><i>Aucune facture n'a été éditée</i></span>
                            }
                        </div>
                    }
                />
                </Grid.Column>

                {ColDivider}

                <Grid.Column width={5}>
                    <b className="red">Situation géographique</b>
                </Grid.Column>
                <Grid.Column width={1} textAlign="right">
                    <Icon name="map marker alternate" size="large" color="grey" />
                </Grid.Column>
                <Grid.Column width={8}>
                    {/* ADDRESS */}
                    <ModifiableItem
                        className="margedTop"
                        title="Adresse"
                        forceDisplayMode={this.props.session && this.props.session.Client && this.props.session.Client.licence !== 'manager'}
                        titleStyle={{color: 'grey'}}
                        subHeaderStyle={{fontSize: '1em'}}
                        pair={{key: 'location', oldValue: location}}
                        changeMethodAsync={async({key, newValue, oldValue}) => {
                            let {error} = await Utils.fetchApi({
                                request: 'activityOperations',
                                body: {requestType: "mergeActivity", mergeObject: {[key]: newValue}, activityId: activityId, historicRequest: "addressModified"},
                                client: this.props.session.fetchCredentials
                            })
                            
                            return ({error})
                        }}
                        // change location on map
                        onChangeMethodCalled={({error, newValue}) => {
                            if (!error)
                            this.setState({resetLocation: newValue})
                        }}
                    
                    />

                    <Divider />
                    
                    {/* MAP */}
                    <div style={{height:"35vh", paddingBottom: 10}}>
             
                        {
                            location
                            ? <SimpleMap
                                height="100%"
                                width='auto'
                                location={this.state.resetLocation || location}
                            />
                            : <div className="flexy flexCenter"><b className="negativeText bold">Vous n'avez pas renseigné de lieu pour ce dossier</b></div>
                        }
                        {
                            location 
                            && <span className="margedTop centered allWidth block">
                                <Button compact className="greyAppButton" href={`https://www.google.com/maps/dir/Roye,+80700/${location}`} target="_blank" rel="noopener, noreferrer">Trajet depuis le dépôt</Button>
                            </span>
                        }
                    </div>
                </Grid.Column>

                {ColDivider}

                <Grid.Column width={5}>
                    <b className="red">Client</b>
                    {
                        !customerName
                        ? <p>Vous n'avez pas encore lié de client à ce dossier, sélectionnez-en un parmi vos clients existants ou créez-en un nouveau</p>
                        : null
                    }
                </Grid.Column>
                <Grid.Column width={1} textAlign="right">
                    <Icon name="user outline" size="large" color="grey" />
                </Grid.Column>
                <Grid.Column width={8}>
                <Head 
                    size="small"
                    subHeaderStyle={{fontSize: "1.5em"}}
                    textAlign="left"
                    title={
                        <span>
                            Client 
                            {
                                this.props.session && this.props.session.Client && this.props.session.Client.id === this.props.activity.openedBy
                                && <Icon size="tiny" className="overable" name="edit" color="grey" circular onClick={() => this.setState({displayCustomersSelection: true})} />
                            }
                        </span>
                    }
                    contentStyle={{marginLeft: '5%'}}
                    content={     
                        customerName && !this.state.displayCustomersSelection
                        ? <div>
                            <div>{customerName}</div>
                            <Link 
                                to={{
                                    pathname: "/crm",
                                    state: {
                                        open: customerName
                                    }
                                }}
                            >
                                {
                                    this.props.session && this.props.session.Client && this.props.session.Client.id === this.props.activity.openedBy
                                    && <AnimatedButton /*onClick={() => { window.open(`/crm?open=${customerName}`, '_blank')}}*/ className="margedTop" visible="Fiche client" invisible={<Icon name="id card outline" size="large"/>} />
                                }        
                            </Link>
                        </div>
                        : (
                            this.state.displayCustomersSelection
                            ? <CustomersSelection 
                                onSelect={async(__, {value}) => {
                                    if (window.confirm(`Lier le client ${value} au dossier d'intervention "${this.props.activity.name}" ?`)) {
                                        let {error} = await Utils.fetchApi({
                                            request: 'activityOperations',
                                            body: {
                                                requestType: 'mergeActivity',
                                                activityId: activityId, 
                                                mergeObject: {
                                                    customerName: value
                                                }, 
                                                historicRequest: 'addCustomerName'
                                            },
                                            client: this.props.session.fetchCredentials
                                        })
                                        global.fireMsg({error, positive: !error && 'Client lié au dossier'})
                                        this.props.updateActivities()
                                        this.setState({displayCustomersSelection: false})

                        
                                    } else this.setState({displayCustomersSelection: false})
                                }}
                            />
                            : <Button.Group>
                                <Button className="greyAppButton">
                                    <Link 
                                        to={{
                                            pathname: "/crm",
                                            state: {
                                                create: activityId
                                            }
                                        }}
                                    >
                                        <span>
                                            Nouveau Client 
                                            <CornerIcons one="user outline" two="add" colorTwo="teal"/>
                                        </span>
                                    </Link>
                                </Button>
                                <Button.Or text="ou" />
                                <Button 
                                    onClick={() => this.setState({displayCustomersSelection: true})} 
                                    style={{backgroundColor: 'red'}} 
                                    className="greyAppButton"
                                >
                                    <span>
                                        Client Existant 
                                        <Icon name="user outline" />
                                    </span>
                                </Button>
                            </Button.Group>
                            
                        )
                    }
                />

                </Grid.Column>

                {ColDivider}

                <Grid.Column width={5}>
                    <b className="red">Notes du dossier</b>
                </Grid.Column>
                <Grid.Column width={1} textAlign="right">
                    <Icon name="clipboard" size="large" color="grey" />
                </Grid.Column>
                <Grid.Column width={8}>
                    <Head
                        size="small"
                        style={{width: '100%'}}
                        titleStyle={{color: 'black', textAlign: 'left'}}
                        contentStyle={{marginLeft: '5%', width: '100%'}}
                        subHeaderStyle={{fontSize: "1.5em"}}
                        title={
                            <ModifiableItem
                                editIconSize="tiny"
                                saveIconSize="tiny"
                                titleClassName="black"
                                inputContainerStyle={{width: '100%', marginTop: '1em'}}
                                title="Notes"
                                pair={{key: "notes", oldValue: notes}}
                                changeMethodAsync={async({key, newValue}) => {
                                    let {error} = await Utils.fetchApi({
                                        request: 'activityOperations',
                                        body: {requestType: "mergeActivity", mergeObject: {[key]: newValue}, activityId: activityId, historicRequest: "newActivityNotes"},
                                        client: this.props.session.fetchCredentials
                                    })
                                    return ({error})
                                }}
                                onChangeMethodCalled={({error, response, request, key, newValue, oldValue}) => {
                                    global.fireMsg({
                                        error: error && "Erreur lors de l'enregistrement des notes, veuillez réessayer",
                                        positive: !error && "Les notes ont été mises à jour"
                                    })
                                }}
                            />
                        }
                    />
                </Grid.Column>

                
                {ColDivider}

    


            </Grid>
        </div>
    }
    ///////////////

    filesTab = () => <Grid>
        {/* add new files */}
        <Grid.Column width={4}>
            <Label className="centered" style={{width: '90%', marginLeft: '5%'}} attached="top">Ajouter de nouveaux fichiers</Label>
            <div onClick={() => this.setState({addFiles: true})} className="padded5"><Segment style={{height:'100%', width:'100%'}} className="centered"><Icon className="textPop" name="add" size="huge" color="grey" /></Segment></div>
        </Grid.Column>
        {
            this.props.activity.files && this.props.activity.files.length
            ? this.props.activity.files.map((f) => typeof f === 'object' && <Grid.Column key={`filecol_${f.name}`} width={4}>
              
                <span className="centered">
                    <Label style={{width: '90%', marginLeft: '5%'}} attached="top">{f.name}<Icon onClick={() => this.deleteActivityFile(f.key)} name="close" className="redOnHover rightAligned"/></Label>
                    {
                        f.type.indexOf('image') >= 0
                        ? <Image onClick={() => window.open(f.src)} src={f.src}/>
                        : (
                            f.type.indexOf('video') >= 0
                            ? <video src={f.src}></video>
                            : (
                                f.type.indexOf('application') >= 0
                                //? <div className="flexy flexCenter"><CornerIcons one="eye" two="dont" colorTwo="red"/><p>Ce fichier ne peut être lu</p><div className="greyAppButton">télécharger</div></div>
                                ? <Head 
                                    
                                    style={{display: 'block'}}
                                    title="lecture impossible" 
                                    icon={<CornerIcons one="eye" two="dont" colorTwo="red"/>} 
                                    subHeaderContainerStyle={{marginTop: '2em'}}
                                    content={<div onClick={() => window.open(f.src, '_blank')} className="greyAppButton overable">télécharger</div>}
                                />
                                : <iframe title={f.name} src={f.src}></iframe>
                            )
                        )
                    }
                </span>
            </Grid.Column>)
            : ""
        }
    </Grid>

    reportsTab = () => <Grid>
        <Modal style={{height: '90vh'}} open={this.state.createNewReport} size="large" onClose={this.closeNewReportView}>
            <div style={{height: '100%'}}>
                <PhotoReport activity={this.props.activity} close={this.closeNewReportView} updateActivities={this.props.updateActivities} client={this.props.session.Client}/>
            </div>
        </Modal>
        {/* add new photo report */}
        <Grid.Column width={4}>
            <Label className="centered" style={{width: '90%', marginLeft: '5%'}} attached="top">Créer un nouveau rapport</Label>
            <div className="padded5"><Segment style={{height:'100%', width:'100%'}} className="centered"><Icon className="textPop" name="add" size="huge" color="grey" onClick={() => this.setState({createNewReport: true})}/></Segment></div>
        </Grid.Column>
        {
            this.props.activity.photoReports && this.props.activity.photoReports.length
            ? this.props.activity.photoReports.map((report, repi) => 
                <Grid.Column key={`report_${repi}`} width={4}>
                    <Label className="centered" style={{width: '90%', marginLeft: '5%'}} attached="top">
                        {report.originalname}
                        <Icon size="small" circular name="window restore outline" className="overable margedLeft" onClick={() => window.open(report.location, '_blank')} />
                        <Icon circular name="close" className="redOnHover" onClick={() => this.removeReport(report)}/>
                    </Label>
                    <iframe title={report.originalname} src={report.location} className="documentFrame" />
                </Grid.Column>
               
            )
            : undefined
        }
    </Grid>
    
    billsTab = () => <div>{
        this.state.createNewBill
        ? <AccountingDocument
            type="bill"
            onClose={(noConfirmation) => {
                noConfirmation && typeof noConfirmation === 'boolean'
                ? this.setState({createNewBill: false})
                : window.confirm("Souhaitez-vous abandonner la facture en cours ?") && this.setState({createNewBill: false})
            }}
            activity={this.props.activity} 
            updateActivities={this.props.updateActivities}
        />
        : <Grid>
            <Grid.Column width={4}>
                <Label className="centered" style={{width: '90%', marginLeft: '5%'}} attached="top">Créer une nouvelle facture</Label>
                <div className="padded5"><Segment style={{height:'100%', width:'100%'}} className="centered"><Icon className="textPop" name="add" size="huge" color="grey" onClick={() => this.setState({createNewBill: true})}/></Segment></div>
            </Grid.Column>
            {
                this.props.activity.bills && this.props.activity.bills.length 
                ? this.props.activity.bills.map((b) => <Grid.Column key={Utils.keyExtractor()} width={4}>
                    <Segment style={{height:'100%', width:'100%', padding: 0}} color={(this.props.activity.transmittedDocs && this.props.activity.transmittedDocs[b.originalname] && this.props.activity.transmittedDocs[b.originalname].transmitted) ? "teal" : "red"}>
                        <Label className="centered" attached="top">
                            {b.originalname}
                            <Icon circular inverted color="grey" /*size="small"*/ name="window restore outline" className="overable margedLeft" onClick={() => window.open(b.location, '_blank')} />
                            {/* <Icon circular inverted color="grey" name="star" className="overable yellowOnHover" size="small" onClick={() => this.setState({editAccountingDocument: {type: "bill", document: b}})} /> */}
                            {
                                this.nextActionIcon({
                                    type: 'bill', 
                                    status: (
                                        (
                                            this.props.activity.transmittedDocs 
                                            && this.props.activity.transmittedDocs[b.originalname]
                                            && this.props.activity.transmittedDocs[b.originalname].transmitted 
                                        )
                                        ? "transmitted" 
                                        : "awaitingTransmission"
                                    ), 
                                    name: b.originalname
                                })
                            }
                            {/* <Icon circular inverted color="grey" name="file alternate outline" className="overable" size="small" onClick={() => window.open(`/bills?open=${b.originalname}`, '_blank')} /> */}
                            <Link to={{
                                pathname: "/bills",
                                state: {
                                    open: b.originalname
                                }
                            }}>
                                <Icon circular inverted color="grey" name="file alternate outline" className="overable" /*size="small"*/ />
                            </Link>
                        </Label>
                        {/* <Image onClick={() => window.open(b.src, "_blank")} src={b.src} width="100%" height="auto" alt="" /> */}
                        <iframe title={b.originalname} src={b.location} className="documentFrame" />
                    </Segment>
                </Grid.Column>)
                : null
            }
        </Grid>
    }</div>

    quotationsTab = () => <div>{
        this.state.createNewQuotation
        ? <AccountingDocument 
            type="quotation"
            onClose={(noConfirmation) => { 
                noConfirmation && typeof noConfirmation === 'boolean'
                ? this.setState({createNewQuotation: false})
                : window.confirm("Souhaitez-vous abandonner le devis en cours ?") && this.setState({createNewQuotation: false})
            }} 
            activity={this.props.activity} 
            updateActivities={this.props.updateActivities}
        />
        : <Grid>
            {
                /* CAN CREATE A NEW QUOTATION ONLY IF THERES NO SIGNED QUOTATIONS YET */
                (
                    this.props.activity.quotations && this.props.activity.quotations.length && this.props.activity.quotations.filter((q) => this.props.activity.transmittedDocs && this.props.activity.transmittedDocs[q.originalname] && this.props.activity.transmittedDocs[q.originalname].signed).length === 0
                    || !(this.props.activity.quotations && this.props.activity.quotations.length)
                )    
                && <Grid.Column width={4}>
                    <span style={{padding: '0px 2px 0px 2px'}}><Label className="centered" style={{width: '90%', marginLeft: '5%'}} attached="top">Créer un nouveau devis</Label></span>
                    <div className="padded5">
                        <Segment style={{height:'100%', width:'100%'}} className="centered">
                            <Icon className="textPop" name="add" size="huge" color="grey" onClick={() => this.setState({createNewQuotation: true})} />
                        </Segment>
                    </div>
                </Grid.Column>
            }
            {
                this.props.activity.quotations && this.props.activity.quotations.length 
                ? this.props.activity.quotations.map((q) => <Grid.Column key={Utils.keyExtractor()} width={4}>
                    <Segment 
                        style={{height:'100%', width:'100%', padding: 0}} 
                        color={
                            (
                                this.props.activity.transmittedDocs 
                                && this.props.activity.transmittedDocs[q.originalname] 
                                && this.props.activity.transmittedDocs[q.originalname].transmitted
                                && !this.props.activity.transmittedDocs[q.originalname].signed
                                && !this.props.activity.transmittedDocs[q.originalname].denied
                            ) 
                            ? "yellow" // transmitted
                            : (
                                (this.props.activity.transmittedDocs && this.props.activity.transmittedDocs[q.originalname] && this.props.activity.transmittedDocs[q.originalname].signed) 
                                ? "teal" // signed
                                : "red"  // awaitingTransmission
                            )
                        }/*color={q.status !== 'awaitingTransmission' ? "teal" : "red"}*/
                    >
                        <Label className="centered" attached="top">
                            {q.originalname}
                            <Icon /*size="small"*/ circular inverted color="grey" name="window restore outline" className="overable margedLeft" onClick={() => window.open(q.location, '_blank')} />
                            {/* <Icon name="star" className="overable yellowOnHover" size="small" onClick={() => this.setState({editAccountingDocument: {type: "quotation", document: q}})} /> */}
                            {
                                this.nextActionIcon({
                                    type: 'quotation', 
                                    status: (
                                        (
                                            this.props.activity.transmittedDocs 
                                            && this.props.activity.transmittedDocs[q.originalname]
                                            && this.props.activity.transmittedDocs[q.originalname].transmitted 
                                            && !(this.props.activity.transmittedDocs[q.originalname].signed 
                                                || this.props.activity.transmittedDocs[q.originalname].denied)
                                        )
                                        ? "transmitted" 
                                        : (
                                            (
                                                this.props.activity.transmittedDocs 
                                                && this.props.activity.transmittedDocs[q.originalname]
                                                && (
                                                    this.props.activity.transmittedDocs[q.originalname].signed
                                                    || this.props.activity.transmittedDocs[q.originalname].denied
                                                )
                                            )
                                            ? "null"    // no action icon
                                            : "awaitingTransmission"
                                        )
                                    ), 
                                    name: q.originalname
                                })
                            }
                            <Link to={{
                                pathname: "/quotations",
                                state: {
                                    open: q.originalname
                                }
                            }}>
                                <Icon circular inverted color="grey" name="file alternate outline" className="overable" /*size="small"*/ />
                            </Link>
                            <span className="overable rightAligned"><Icon circular inverted color="grey" onClick={() => this.removeQuotation(q.key, q.originalname)} name="close" /></span>

                        </Label>
                        {/* <Image onClick={() => window.open(q.src, "_blank")} src={q.src} width="100%" height="auto" alt="" /> */}
                        <iframe title={q.originalname} src={q.location} className="documentFrame" />
                    </Segment>
                </Grid.Column>)
                : null
            }
        </Grid>
    }</div>
    

    historicTab = () =>  <div style={{overflow: 'scroll', padding: 10}}>

            {
                this.props.activity.historic && this.props.activity.historic.length 
                ? this.props.activity.historic.map((h) => (
                    h.title && this.renderHistoric(h)
                ))
                : <div className="flexy flexCenter"><b className="negativeText">Aucune action</b></div>
            }
        
    </div>  

    // the deletation has been confirmed, delete
    deletationConfirmed = () => {
        if (this.state.deletationConfirmed) {
            global.fireMsg({positive: <span>{`Suppression du dossier ${this.props.activity.name}`} <Icon loading name="cog" /></span>})
            this.props.removeActivity(true) // true: noConfirm
        }
    }

    // ask for deletation confirmation
    deletationValidator = (e) => {
        let supposedActivityName = e.target.value
        const matchin = supposedActivityName === this.props.activity.name

        if (matchin) {
           this.setState({deletationConfirmed: true})
        } else if (this.state.deletationConfirmed && !matchin)
            this.setState({deletationConfirmed: false})
    }

    removeTab = () => <div className="padded1 tekoAll" style={{fontSize: '1.3em'}}>
        <Grid centered>
            <Grid.Column width={5}>
                <b className="red textShadow">Suppression</b>
                <p>La suppression du dossier est définitive. Seuls les devis et factures seront conservé et accessible via "Menu" -> "Devis" ou "Factures". Si vous souhaitez conserver des fichiers ou rapports photos conservés ici, téléchargez les puis copiez les dans <Link to="/database">votre base de données</Link></p>
            </Grid.Column>
            <Grid.Column width={1} textAlign="right">
                <CornerIcons size="large" one="folder" two="close" colorOne="grey" colorTwo="red" />
            </Grid.Column>
            <Grid.Column width={8} centered="true">
                {/* email edition */}
                <Head
                    title="Confirmation"
                    content={
                        <div>
                            <div>Veuillez entrer le nom du dossier si vous acceptez sa suppression définitive</div>
                            <span>
                                <Input size="large" error={true} onChange={this.deletationValidator}/>
                                {
                                    this.state.deletationConfirmed
                                    && <span className="margedLeft">
                                        <AnimatedButton visible={`supprimer ${this.props.activity.name}`} invisible={<Icon name="close" />} className="appButton vibrate" onClick={this.deletationConfirmed}/>
                                    </span>
                                }
                            </span>
                        </div>
                        
                    }
                />
            </Grid.Column>
        </Grid>
        <Divider />

    </div>
        
    /* end views */

    /* Tab Menu Handlers */ 
    
    tabToComponent = (tab) => this[`${tab}Tab`]()
    setTabInView = async(tabInView) => {
        let tabName = await this.tabName(tabInView)
        global.fireMsg({positive: "", negative: ""})
        this.setState({tabInView, tabName})
        this.setAppBar()
    }
    /* end tab handlers */
    

    /* Renderers */
    render() {
        let closeEdition = () => this.setState({editAccountingDocument: undefined})
        let closeAddFiles = () => this.setState({addFiles: false})
        console.log('ActivityFile render', {state:this.state, props:this.props})
        return(
            <div style={{height: '100%'}}>
                <Modal dimmer open={this.state.addFiles} onClose={closeAddFiles}>
                    <Head 
                        subHeaderContainerStyle={{marginTop: 10}}
                        titleStyle={{fontSize:'1.2em'}}
                        titleClassName="teko"
                        title="Ajouter des fichiers"
                        contentStyle={{width: '100%'}}
                        content={
                            <Grid divided>
                                <Grid.Column width={8}>
                                    <Label attached="top" pointing="below" color="grey">Importer</Label>
                                             
                                    <Input type="file" multiple onChange={(e) => this.addFilesToActivity(e.target.files)} />
                               
                                </Grid.Column>
                                <Grid.Column width={8}>
                                    <Label attached="top" pointing="below" color="grey">Dessiner</Label>
                                    <div className="cardField noBorder" onClick={() => this.setState({showDrawer: true, addFiles: false})}>
                                        <Icon size="huge" name="paint brush" />
                                    </div>
                                </Grid.Column>
                                <Grid.Column width={8}>
                                <Database 
                              
                                    selectionMode={true} 
                                    onFileSelected={async(e) => {
                                        closeAddFiles()
                                        let file = e.target.value;
                                        let fileName = (file.givenName || file.originalname);

                                        global.fireMsg({
                                            positive: <span>{`Téléchargement du fichier ${fileName} depuis votre base de données`} <Icon name="cog" loading/></span>
                                        })
                                        
                                        console.log('file', file);
                                        
                                        let activityFile = await Promise.resolve({
                                            name: fileName, 
                                            key: file.key, 
                                            type: file.contentType, 
                                            src : `https://workandup-dev.s3.eu-west-3.amazonaws.com/${file.key}`
                                        })
                                        // merge file with files
                                        let files = [...(this.props.activity.files||[]), activityFile]
                                        const {error, response} = await Utils.fetchApi({
                                            request: "activityOperations",
                                            body: {
                                                requestType: 'mergeActivity',
                                                activityId: this.props.activity.activityId, 
                                                mergeObject: {files}, 
                                                historicRequest: "uploadActivityFiles"
                                            }, 
                                            client: this.props.session.fetchCredentials
                                        })

                                        // 
                                        global.fireMsg({error: error && "Erreur lors de l'enregistrement du fichier, veuillez réessayer", positive: !error && `Le fichier ${activityFile.name || ""} a été placé dans le dossier ${this.props.activity.name}`})
                                        // dispatch
                                        this.props.updateActivities(true, response.msg) // res.msg = updated activities from backend mergeActivity
                                        
                                    }}
                                />
                                   
                                </Grid.Column>
                            </Grid>
                           
                        }
                        icon={<CornerIcons one="file outline" two="add" colorTwo="teal" />}
                    />
                </Modal>

                <Modal size="fullscreen" className="fullscreenModal" open={this.state.showDrawer}>
                    <Drawer 
                        activityId={this.props.activity.activityId}
                        close={() => this.setState({showDrawer: false})}
                        updateActivities={this.props.updateActivities} 
                        onSave={this.saveDrawing.bind(this)}
                    />
                </Modal>

                <Modal onClose={closeEdition} size="small" open={typeof this.state.editAccountingDocument === "object"}>
                    {
                        this.state.editAccountingDocument 
                        && <Modal.Header>
                            Mise à jour {this.state.editAccountingDocument.type === 'quotation' ? 'du' : 'de la'} {this.state.editAccountingDocument.document.originalname}
                        </Modal.Header>
                    }
                    <Modal.Content>
                    {
                        this.state.editAccountingDocument 
                        && <StatusSelection 
                            type={this.state.editAccountingDocument.type}
                            ActivityStatus={this.props.ActivityStatus} 
                            //style={{width:'100%'}} 
                            onChange={async(e, {value}) => {
                                let forcedStatus = await Promise.resolve(
                                    value === "signed"
                                    && (
                                        this.state.editAccountingDocument.type === 'quotation'
                                        && "Le client a signé le devis"
                                    ) 
                                )
                                if (value && value !== this.state.editAccountingDocument.document.status && window.confirm(`ÉDITION DU STATUS\n\n - document : ${this.state.editAccountingDocument.document.originalname}\n - status : ${forcedStatus || this.props.ActivityStatus[value].tooltip}\n\nConfirmer l'opération ?`)) {

                                    // set accounting document status via fetch
                                    let {error} = await Utils.fetchApi({
                                        request: "setAccountingDocumentStatus",
                                        body: {
                                            type: this.state.editAccountingDocument.type,        // "quotation" or "bill"
                                            year: parseInt(this.state.editAccountingDocument.document.originalname.split('-')[1]),    // 2019
                                            reference: parseInt(this.state.editAccountingDocument.document.originalname.split('-')[2]).toString(),
                                            status: value
                                        },
                                        client: this.props.session.fetchCredentials
                                    })

                                    if (error) 
                                        console.error("setAccountingDocumentStatus ERROR", error)
                                    else {
                                        this.setState({editAccountingDocument: undefined})
                                        this.props.updateActivities()
                                    }
                                    global.fireMsg({error: error && "Il y a eu une erreur lors de la mis à jour du status, veuillez recommencer l'opération", positive: !error && "État du dossier à jour"})
                                    // update activities or replace locally

                                } else closeEdition()  
                            }}
                        />
                    }
                    </Modal.Content>
                </Modal>              

                {/* tab view component */} 
                {   
                    this.tabToComponent(this.state.tabInView)
                }
            </div>
        )
    }
}

export default connect(Utils.mapStateToProps)(ActivityFile)