import React, { useState } from 'react'
import { Dropdown } from 'semantic-ui-react'

const units = ['u', 'ml', 'm2', 'm3', 'kg', 'L', 'h', 'jour']

const unitOptions = units.map((u) => ({key:u, value:u, text:u}))

/**
 * 
 * @param {*} props 
 *  - onChange (function) 
 *  - value *optional* : will overwrite default value 'u'
 */
const UnitsSelection = (props) => {
    let [value, setValue] = useState(typeof props.value !== 'undefined' ? props.value : unitOptions[0].value)
    //let useValue = typeof props.value !== 'undefined' ? props.value : unitOptions[0].value;
    if (typeof props.value !== 'undefined' && (props.value !== value))
        setValue(props.value)
        // console.log('UnitsSelection props value', props.value)
        // console.log('UnitsSelection value', value)
    return <Dropdown 
    onChange={props.onChange}
    inline 
    options={unitOptions}
    text={value}  
/>}

export default UnitsSelection