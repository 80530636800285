import React from 'react';
import { Select, Icon } from 'semantic-ui-react'

/**
 * ##PROPS
 *  - ActivityStatus
 *  - type
 */
export default class StatusSelection extends React.Component {
    state = {
        statusOptions: undefined
    }

    async componentDidMount() {
        //init select
        let {ActivityStatus, type} = this.props//await Promise.resolve(this.props.ActivityStatus(null, true))
        let originalSigned = ActivityStatus.signed;
        let originalTransmitted = ActivityStatus.transmitted

        ActivityStatus.signed = await Promise.resolve(
            type === 'quotation'
            ? {...ActivityStatus.signed, tooltip: "Le client a signé le devis", icon: "pencil alternate", color:"green"}
            : ActivityStatus.signed
        )
        ActivityStatus.transmitted = await Promise.resolve(
            type === 'bill'
            ? {...ActivityStatus.transmitted, tooltip: "Facture en attente de paiement (transmise)"}
            : ActivityStatus.transmitted
        )
        //console.log('ActivityStatus', ActivityStatus)   // {color, icon, tooltip}
        let keys = await Promise.resolve(Object.keys(ActivityStatus))
        //console.log('keys', keys)
        let statusOptions = await Promise.resolve(keys.filter((_k) => !ActivityStatus[_k].cantSet && ActivityStatus[_k][type]).map((k) => ({
            key: k,
            value: k,
            text: <span><Icon color={ActivityStatus[k].color} name={ActivityStatus[k].icon} /> {ActivityStatus[k].tooltip}</span>
        })))
      
        this.setState({statusOptions});

        // reset original .signed version to avoid activityFile to display "le client a signé le devis" iso "en attente de paiement"
        ActivityStatus.signed = originalSigned
        ActivityStatus.transmitted = originalTransmitted
    }

    render() {
        const {statusOptions} = this.state
        return(
            statusOptions
            ? <Select style={this.props.style} options={statusOptions} onChange={this.props.onChange}/>
            : <div></div>
        )
    }
    

    
    

}


