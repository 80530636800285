import React, {useState} from 'react'

/**
 * ##PROPS
 *  - content (any) : the pressable content
 *  - intervalInMs (number) : interval in millisecond used to call whilePressed when client is pressing down (default: 300)
 *  - whilePressed (function) : event function called every 'intervalInMs' millisecond
 */
const LongPressable = (props) => {
    const [pressingDown, setPressingDown] = useState(false)

    if (pressingDown) {
        setTimeout(
            () => {
                props.whilePressed();
            },
            props.intervalInMs || 300
        )
    }
    return (
        <span onMouseDown={() => setPressingDown(true)} onMouseUp={() => setPressingDown(false)}>
            {props.content}
        </span>
    )
}

export default LongPressable
