import React from 'react'
import { Image, Segment, Grid, Icon } from 'semantic-ui-react'
import '../constants/drawer.css'
import Utils from '../constants/Utils'
const Potentiometer = ({onChange}) => <div className="marged2"><input onChange={(e) => onChange(e.target.value)} type="range" className="potentiometer" /></div>

/**
 * ##PROPS:
 *  - source (string) Image Source
 *  - returnSource (bool) *opt* if true, 'onResized' prop will receive {source: string}
 *  - onResized (function) callback to call when client has resized his image (receive {source} if 'returnSource' prop is true else default {width, height})
 */
export default class ImageResizer extends React.Component {
    state = {
        id: Utils.keyExtractor(),
        originalWidth: undefined,
        originalHeight: undefined,
        width: undefined,
        height: undefined
    }

    setImageSize = async() => {
        let $e = document.getElementById(this.state.id)
        //console.log('$e', $e)
        let originalWidth = $e.naturalWidth
        let originalHeight = $e.naturalHeight
    
        let initialized = await Promise.resolve($e && originalWidth && originalHeight)

        this.setState({originalWidth, originalHeight, initialized})
    }

    componentDidMount() {
        // set component resetting state
        this.setState({initialized: false})
    }

    async componentDidUpdate() {
        const { initialized } = this.state

        if (!initialized) {
            setTimeout(
                () => this.setImageSize(),
                200     
            )
        }
    }

    resize = (resizeValue) => {
        const {originalWidth, originalHeight} = this.state
        let percent = (resizeValue * 2) / 100
        let w = originalWidth * percent
        let h = originalHeight * percent
        this.setState({width: parseInt(w), height: parseInt(h)})
    }

    resizedSource = (src, MAX_WIDTH, MAX_HEIGHT) => {
        let img = document.createElement('img')
        img.src = src
        var canvas = document.createElement("canvas");
        //var canvas = $("<canvas>", {"id":"testing"})[0];
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);

       
        var width = img.width;
        var height = img.height;
        //console.log(`width: ${width}`)
        //console.log(`height: ${height}`)

        MAX_WIDTH = MAX_WIDTH || width;
        MAX_HEIGHT = MAX_HEIGHT || height;

        if (width > height) {
            if (width > MAX_WIDTH) {
                height *= MAX_WIDTH / width;
                width = MAX_WIDTH;
            }
        } else {
            if (height > MAX_HEIGHT) {
                width *= MAX_HEIGHT / height;
                height = MAX_HEIGHT;
            }
        }
        canvas.width = MAX_WIDTH//width || MAX_WIDTH;
        canvas.height = MAX_HEIGHT//height || MAX_HEIGHT;
        // canvas.width = MAX_WIDTH;
        // canvas.height = MAX_HEIGHT;
        var _ctx = canvas.getContext("2d");
        _ctx.drawImage(img, 0, 0, width, height);

        var dataUrl = canvas.toDataURL("image/png");
        return dataUrl
        //document.getElementById('output').src = dataurl;
    }

    /**
     * @dev Resize operation validator : resets a new canvas with resized image and return resized source or dimensions (depending on the 'returnSource' prop)
     */
    onResized = async() => {
        const { height, width, originalHeight, originalWidth } = this.state
        // console.log(`IR state height: ${height}`)
        // console.log(`IR state width: ${width}`)

        if (typeof this.props.onResized === 'function') {
            let w = width || originalWidth
            let h = height || originalHeight

            if (this.props.returnSource) { 
                // console.log(`onResized w: ${w}`)
                // console.log(`onResized h: ${h}`)               
                let source = await this.resizedSource(this.props.source, w, h)
                this.props.onResized({source})
            } 
            
            else this.props.onResized({width: w, height: h})
                
        } else console.error("ImageResizer Error: 'onResized' prop is not a function")
    }

    render = () => {
        //console.log('ImageResizer state', this.state)
        return(
        <div style={styles.container}>
            <div style={styles.image}>
                <Image style={{maxWidth: 'none'}} height={this.state.height} width={this.state.width} id={this.state.id} src={this.props.source} centered/>
            </div>
            <Segment style={styles.segment} attached="bottom"> 
                <Grid divided>
                    <Grid.Column width={14}>
                        <Potentiometer onChange={this.resize} />
                    </Grid.Column> 
                    <Grid.Column centered="true" width={2}>
                        <Icon name="check" color="teal" circular size="big" onClick={this.onResized} />
                    </Grid.Column>    
                </Grid>
            </Segment>
        </div>
        )
    }

}

const styles = {
    container: {
        height: '80vh',
        //width: '100%'
        overflow: 'scroll'
    },
    image: {
        overflow: 'scroll',
        height: '65vh',
        //width: '100%'
    },
    segment: {
        height: '15vh',
        width: '100%'
    }
}