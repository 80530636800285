
import React, {useState} from 'react';
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { Grid, Icon, Modal, Header, Segment, Table, Divider, Input, Form, TextArea, Dropdown } from 'semantic-ui-react'

import Searcher from '../components/Searcher'
import CornerIcons from '../components/CornerIcons'
import ModifiableItem from '../components/ModifiableItem'
import NewCustomerForm from '../components/NewCustomerForm'
import AppLoader from '../components/AppLoader'
import SimpleMap from '../components/SimpleMap'
import PrioritySelection from '../components/PrioritySelection'
import NewCustomerEventForm from '../components/NewCustomerEventForm'
import Head from '../components/Head';
import AnimatedButton from '../components/AnimatedButton';

import Colors from '../constants/Colors'
import Utils from '../constants/Utils'
import CustomersSelection from '../components/CustomersSelection';

const priorities = {
    low: 'Basse',
    medium: 'Moyenne',
    high: "Haute"
}

const SilverSpan = ({title, value}) => <div><span className="silver">{title} </span> <span style={{fontSize: 20, color: 'grey'}}>{ value }</span></div>

const CustomerIcon = (props) => <CornerIcons 
    {...props}
    size="large"  
    one="user outline" 
    two="add"
    colorTwo="teal"
/>

const Crm = (props) => {

    /* STATE HOOKS */
    let [state, _setState] = useState({
        loading: true,
        appBarSet: false
    })
    /* STATE SETTER */
    const setState = (m) => _setState({...state, ...m})

    const newContactHandler = (relatedActivity) => {
        
        setState({addingNewContact: true, openedCustomer: undefined, relatedActivity})
    }

    const customersWithEvents = (customers, _events) => {
        const events = _events || props.session.Client.events
        return new Promise(async(res) => {
            !(events && events.length) && res(customers || [])

            let Customers = [];
            if(!events || !customers || !customers.length) res([])
            else {
        
                customers.map(async(c, ci) => {
                    let cEvents = await Promise.resolve(
                        events.filter((e) => e.linkedCustomer === c.name)
                    )
                
                    c.events = cEvents;

                    Customers.push(c)
                    if ((ci === customers.length - 1) && Customers.length === customers.length)
                        res(Customers)
                })
        
            }
        })   
    }
    /* ACTIVITIES and CUSTOMERS WITH EVENTS GETTER / SETTER + APP BAR SETTER */
    const initCrm = async(additiveState, useSetActivities) => {
        additiveState = additiveState || {}
        const {customers} = props.session.Client
        let customersEventsAttached = await customersWithEvents(customers)
        let {error, response:activities} = (
            useSetActivities 
            ? (() => ({response: state.activities}))()
            : await Utils.fetchApi({
                method: 'get',
                request: 'activities',
                client: props.session.fetchCredentials
            })
        )

        const leftArrow = state.openedCustomer || additiveState.openedCustomer /* || (props.location && props.location.state && props.location.state.open) */
        ? <Icon key="crmLeftArrow" name="arrow left" size="large" className="overable" onClick={() => [setState({openedCustomer: false, addingNewContact: false}), initCrm(null, true)]} />
        : ""
        // set app bar
        props.appBarSetter({
            viewName: "Fichier Clients",
            searcher: <Searcher 
                data={customersEventsAttached}
                dataLen={customersEventsAttached.length}
                dataName="contact"
                editList={editCustomersList}
                onUpdateSearch={onUpdateSearch}
            />,
            logError: error || undefined,
            controllers: 
            [
                leftArrow,
                !(state.openedCustomer || additiveState.openedCustomer) && <Icon key={Utils.keyExtractor()} circular size="large" className="overable" name="add user" onClick={() => newContactHandler()} />
            ],
            centerController: "",
            bottomController:  ""
        })

        let {open, create} = props.location && props.location.state || {}

        let c = (open && !state.openedCustomer)
            ? await Utils.customerFromName(open, props.session.Client.customers)
            : (
                (create && !state.addingNewContact)
                && newContactHandler(create)
            )
       // console.log('BEFORE customersEventsAttached', customers)
        
        setState({appBarSet: true, customers: customersEventsAttached, activities, loading: false, addEventFor: undefined, openedCustomer: c && c.name && c || undefined, ...additiveState})
    
    }


    !state.appBarSet && initCrm();
       


    const onUpdateSearch = (search) => {
        let isSearching = search && search.length > 0
        setState({isSearching, customers: isSearching ? state.customers : props.session.Client.customers})
    }
    
    

    const editCustomersList = (list) => {
        setState({customers : list || []})
    }

    const customerEditor = async({key, oldValue, newValue}) => {
        let fetchin = await Utils.fetchApi({
            request: "modifyCustomer",
            body: {
                customerName: state.openedCustomer.name,
                key, 
                oldValue,
                newValue
            },
            client: props.session.fetchCredentials
        })
        return fetchin
      }

    const dispatchNewCustomer = async({key, newValue}) => {
        // console.log(`updating customer[${key}] locally with new value: "${newValue}"`)
        
        let {customers} = props.session.Client

        let Customers = await Promise.resolve(
            customers && customers.length && customers.map((c) => 
                c.name === state.openedCustomer.name
                ? (() => { c[key] = newValue; return c; })()
                : c  
            )
        )

        let withEvents = await customersWithEvents(Customers)
        // Reset redux session.Client for initCrm to use the updated version
        props.dispatch({
            type: 'SET_CLIENT',
            value: {customers: withEvents}
        })
        
    }

    const customerEdited = ({error, key, newValue}) => {
        global.fireMsg({
            error: error && 'Erreur lors de la modification du client, veuillez recommencer',
            positive: !error && `Le client ${state.openedCustomer.name} a été modifié`
        })

        if (!error) {
            dispatchNewCustomer({key, newValue})
        }
    }

    const renderAddressMap = (address) => <SimpleMap 
        location={address}
        popup={'AMC LEBLANC'}
        className="customerAddressMap"
        onError={() => global.fireMsg({error: 'Construction de la carte impossible'})}
    />

    const addCalendarEventFor = () => {
        setState({addEventFor: state.openedCustomer})
    }

    const deleteCustomerEvent = async(eventId, title) => {
        let confirmed = window.confirm(`Supprimer l'évènement ${title} ?`)
        if (confirmed) {
            let {error} = await Utils.fetchApi({
                request: "deleteEvent",
                body: {eventId},
                client: props.session.fetchCredentials
            })
            if (!error) {
                let events = await Promise.resolve(props.session.Client.events.filter((e) => e.eventId !== eventId))
                let customers = await customersWithEvents(props.session.Client.customers, events)
                // Local Update: reinit session and crm with new object
                props.dispatch({
                    type: 'SET_CLIENT',
                    value: {customers, events}
                })
                global.fireMsg({positive: 'Évènement supprimé'})

            } else global.fireMsg({error: "Une erreur est survenu lors de la suppression de l'évènement, veuillez recommencer"})
        }
    }

    const closeNewEventForm = () => setState({addEventFor: undefined})

    const newCustomerEventSubmition = async({events}) => {
        if(!events) global.fireMsg({error: "Erreur : \nL'évènement n'a visiblement pas été enregistré. Veuillez recommencer le cas échéant"})
        else {
            dispatchNewCustomer({key: "events", newValue: events})
        }
        closeNewEventForm()
    }

    const deleteCustomer = async(customer) => {
        let customers = await Promise.resolve(props.session.Client.customers.filter((c) => c.name !== customer.name))

        let {error, response} = await Utils.fetchApi({
            request: "mergeClient",
            body: {customers},
            client: props.session.fetchCredentials
        })

        if (!error) {
            // Local Update: reinit session and crm with new object
            let withEvents = await customersWithEvents(customers, props.session.Client.events)
            setState({openedCustomer: undefined})

            props.dispatch({
                type: 'SET_CLIENT', 
                value: {customers: withEvents}
            })

            setState({customers: withEvents, openedCustomer: false})

        } else global.fireMsg({error: "Une erreur est survenu lors de la suppression de l'évènement, veuillez recommencer"})
    
    }

    const shareCustomer = async(e, {value:tm}) => {
        let username = await Promise.resolve(Utils.teamMemberFromId(tm, {returnKey: 'username'}, props.session.TML))
        if (window.confirm(`Confirmer le partage du client ${state.openedCustomer.name}\navec ${username} ?`))
        {
            const {error} = await Utils.fetchApi({
                request: 'shareCustomer',
                body: {
                    customerName: state.openedCustomer.name,
                    customerAddedOn: state.openedCustomer.addedOn,
                    to: tm
                },
                client: props.session.fetchCredentials
            })

            global.fireMsg({
                error: error && "Erreur lors du partage du client, veuillez réessayer",
                positive: !error && `Le client ${state.openedCustomer.name} est maintenant partagé avec ${username}`
            })

        }
    }

    const transferCustomer = async(e, {value: tm}) => {
        let username = await Promise.resolve(Utils.teamMemberFromId(tm, {returnKey: 'username'}, props.session.TML))
        if (window.confirm(`Confirmer le transfère du client ${state.openedCustomer.name}\nà ${username} ?\n\n(vous n'aurez plus accès à ce client)`))
        {
            const {error, response: customers} = await Utils.fetchApi({
                request: 'transferCustomer',
                body: {
                    customerName: state.openedCustomer.name,
                    customerAddedOn: state.openedCustomer.addedOn,
                    to: tm
                },
                client: props.session.fetchCredentials
            })

            global.fireMsg({
                error: error && "Erreur lors du transfère du client, veuillez réessayer",
                positive: !error && `Le client ${state.openedCustomer.name} a été transféré à ${username}`
            })

            if (!error) {
                // update customers list
                let withEvents = await customersWithEvents(customers)
                props.dispatch({
                    type: 'SET_CLIENT',
                    value: {customers: withEvents}
                })

                setState({customers: withEvents, openedCustomer: false})
            }
        }
    }

    /* CUSTOMER FILE */
    const customerFile = () => {
        const { name, address, notes } = state.openedCustomer

        return(
            <div key={`cfile_${name || Utils.keyExtractor()}`} className="padded1 tekoAll" style={{fontSize: '1.3em'}}>
                <Modal size="small" className="flexy flexCenter tekoAll" dimmer="blurring" open={typeof state.addEventFor !== 'undefined'} onClose={closeNewEventForm}>
                    <NewCustomerEventForm 
                        customerName={name} 
                        onFormSubmitionSuccess={newCustomerEventSubmition}
                        onFormSubmitionError={newCustomerEventSubmition}
                    />
                </Modal>
                <Grid centered>
                    {/* NOM PRENOM */}
                    <Grid.Column width={5}>
                        <b className="red">Nom Prénom</b>
                    </Grid.Column>
                    <Grid.Column width={2} textAlign="center">
                        <Icon name="id card outline" size="large" color="grey" />
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <h3>{name}</h3>
                    </Grid.Column>

                    <Grid.Column width={16}><Divider /></Grid.Column>

                    {/* PRIORITY */}
                    <Grid.Column width={5}>
                        <b className="red">Priorité</b>
                    </Grid.Column>
                    <Grid.Column width={2} textAlign="center">
                        <Icon name="sort amount up" size="large" color="grey" />
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <ModifiableItem
                            pair={{key: "priority", oldValue: state.openedCustomer.priority}}
                            customEditionComponent={(changeHandler, value) => <PrioritySelection 
                                selected={value}
                                changeHandler={changeHandler}
                            />}
                            changeMethodAsync={customerEditor}
                            onChangeMethodCalled={customerEdited}
                            renderValue={(value) => <span>{priodot(value)} {Utils.majFirstChar(priorities[value])}</span>}
                        />
                    </Grid.Column>

                    <Grid.Column width={16}><Divider /></Grid.Column>

                    {/* ADDRESS */}
                    <Grid.Column width={5}>
                        <b className="red">Adresse</b>
                        <p>Afin que l'adresse du client soit présentée de manière cohérente sur les documents générés par l'application, merci de placer une virgule avant le code postal et la ville <i className="silver">(ex.: 5 rue de paris, 80000 Amiens)</i></p>
                    </Grid.Column>
                    <Grid.Column width={2} textAlign="center">
                        <Icon name="map marker alternate" size="large" color="grey" />
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <ModifiableItem
                            titleClassName="black"
                            title={address}
                            pair={{key: "address", oldValue: address}}
                            changeMethodAsync={customerEditor}
                            onChangeMethodCalled={customerEdited}
                            renderValueNoWrap={renderAddressMap}
                        />
                        
                    </Grid.Column>

                    <Grid.Column width={16}><Divider /></Grid.Column>
                    
                    {/* NOTES */}
                    <Grid.Column width={5}>
                        <b className="red">Notes</b>
                    </Grid.Column>
                    <Grid.Column width={2} textAlign="center">
                        <Icon name="sticky note outline" size="large" color="grey" />
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <ModifiableItem
                            pair={{key: "notes", oldValue: notes}}
                            changeMethodAsync={customerEditor}
                            onChangeMethodCalled={customerEdited}
                            customEditionComponent={(changeHandler, value) => <Form><TextArea style={{fontSize: 20}} value={value} onChange={changeHandler} /></Form>}
                        />
                    </Grid.Column>
                    
                    <Grid.Column width={16}><Divider /></Grid.Column>

                    {/* EVENTS */}
                    <Grid.Column width={5}>
                        <b className="red">Évènements</b>
                        <p>Les évènements créés via <Link to='/agenda'>l'agenda</Link> et liés au client apparaissent ici</p>
                    </Grid.Column>
                    <Grid.Column width={2} textAlign="center">
                        <Icon name="calendar alternate outline" size="large" color="grey" />
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <Grid className="padded1">
                            {
                                state.openedCustomer 
                                && <Grid.Column width={4} key={Utils.keyExtractor()}>
                                    <Segment className="cardField" onClick={addCalendarEventFor} color="grey" style={{height: '100%'}}>
                                        <Header className="centered">
                                            <Icon name="calendar plus outline" size="big" color="grey" />
                                            <Header.Subheader>
                                                <SilverSpan title="" value="Nouvel Évènemement" />
                                            </Header.Subheader>
                                        </Header>
                                    </Segment>
                                </Grid.Column>
                            }
                            {   
                                state.openedCustomer && state.openedCustomer.events && state.openedCustomer.events.map((e) =>
                                    <Grid.Column width={4} key={Utils.keyExtractor()}>
                                        <Segment className="overable" onMouseEnter={() => setState({showDeletationOptionFor: e.eventId})} onMouseLeave={() => setState({showDeletationOptionFor: undefined})} color={(new Date(e.end).getTime() < Date.now()) ? "red" : "teal" }>
                                            <Header key={Utils.keyExtractor()}>
                                                <Icon name="calendar alternate outline" size="large" color="grey"/>
                                                <Header.Content>
                                                    <span>
                                                        {e.title} 
                                                        {priodot(e.priority)} 
                                                        {(state.showDeletationOptionFor && state.showDeletationOptionFor === e.eventId) && <Icon floated="right" name="delete calendar" style={{color: Colors.amcred}} className="vibrate" onClick={() => deleteCustomerEvent(e.eventId, e.title)} />}
                                                    </span>
                                                </Header.Content>
                                                <Header.Subheader className="margedTop">
                                                    <SilverSpan title="Du" value={Utils.formatedDate(new Date(e.start))} />
                                                    <SilverSpan title="Au" value={Utils.formatedDate(new Date(e.end))} />
                                                </Header.Subheader>
                                                
                                            </Header>
                                        </Segment>
                                    </Grid.Column>
                                )
                            }
                        </Grid>
                    </Grid.Column>

                    <Grid.Column width={16}><Divider /></Grid.Column>

                    <Grid.Column width={5}>
                        <b className="red">Interventions</b>
                        <p>Les interventions créés via <Link to='/activities'>interventions</Link> et liés au client apparaissent ici</p>
                    </Grid.Column>
                    <Grid.Column width={2} textAlign="center">
                        <Icon name="shipping fast" size="large" color="grey" />
                    </Grid.Column>
                    <Grid.Column width={8}>
                        
                        <Grid className="padded1">
                            {
                                state.openedCustomer 
                                && <Grid.Column width={4} key={Utils.keyExtractor()}>
                                    <Link
                                        to={{
                                            pathname: "/activities",
                                            state: {
                                                create: state.openedCustomer.name
                                            }
                                        }}
                                    >
                                    <Segment className="cardField" /*onClick={() => this.addInterventionFor(openedCustomer.name)}*/ color="grey" style={{height: '100%'}}>
                                        <Header className="centered">
                                            <Icon name="plus square outline" size="big" color="grey" />
                                            <Header.Subheader>
                                                <SilverSpan title="" value="Nouvelle Intervention" />
                                            </Header.Subheader>
                                        </Header>
                                    </Segment>
                                    </Link>
                                </Grid.Column>
                            }
                            {
                                state.openedCustomer && state.activities && state.activities.filter((_a) => _a.customerName === state.openedCustomer.name).map((a) => 
                                    <Grid.Column width={4} key={Utils.keyExtractor()}>
                                        <Link to={{
                                            pathname: '/activities',
                                            state: {
                                                open: a.activityId
                                            }
                                        }}>
                                            <Segment color={a.status === 'paid' ? "teal" : "orange"} className="overable" /*onClick={() => this.goToActivity(a.activityId)}*/>
                                                <Header>
                                                    <Icon name="cubes" size="large" color="grey"/>
                                                    <Header.Content>
                                                        <span>{a.name || 'pas de titre'}</span>
                                                    </Header.Content>
                                                    <Header.Subheader className="margedTop">
                                                        <SilverSpan title="Ouvert le" value={a.openedOn ? Utils.formatedDate(new Date(JSON.parse(a.openedOn)), true) : 'non communiqué'} />
                                                        {
                                                            
                                                            a.status && a.status.indexOf('clientSignedQuotation') >= 0 && <SilverSpan title="Devis signé le" value={Utils.formatedDate(new Date(JSON.parse(a.status).filter((s) => (s.key === "clientSignedQuotation"))[0].timestamp), true)} />
                                                        }
                                                    </Header.Subheader>
                                                    
                                                </Header>
                                            </Segment>
                                        </Link>
                                    </Grid.Column>
                                )
                            }
                        </Grid>

                    </Grid.Column>

                    <Grid.Column width={16}><Divider /></Grid.Column>
                    
                    {/* SHARE */}
                    <Grid.Column width={5}>
                        <b className="red">Partage</b>
                        <p>Partager la gestion du client avec un collègue <br />(vous conserverez la gestion du client)</p>
                        
                    </Grid.Column>
                    <Grid.Column width={2} textAlign="center">
                        <span>
                            <Icon color="grey" name="id card outline" />
                            <Icon color="grey" name="arrows alternate horizontal" />
                            <Icon color="grey" name="user outline" />
                        </span>
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <CustomersSelection
                            TMLMode={true}
                            onSelect={shareCustomer}
                        />
                    </Grid.Column>

                    <Grid.Column width={16}><Divider /></Grid.Column>

                    {/* TRANSFER */}
                    <Grid.Column width={5}>
                        <b className="red">Transfère</b>
                        <p>Transférer la gestion du client à un collègue <br />(vous perdrez la gestion et n'aurez plus accès à la fiche client)</p>
                        
                    </Grid.Column>
                    <Grid.Column width={2} textAlign="center">
                        <span>
                            <Icon color="grey" name="id card outline" />
                            <Icon color="grey" name="long arrow alternate right" />
                            <Icon color="grey" name="user outline" />
                        </span>
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <CustomersSelection
                            TMLMode={true}
                            onSelect={transferCustomer}
                        />
                    </Grid.Column>

                    <Grid.Column width={16}><Divider /></Grid.Column>

                    {/* DELETATION */}
                    <Grid.Column width={5}>
                        <b className="red">Suppression</b>
                        <p>Entrez le nom du client pour confirmer sa suppression. <br />La supression du client est définitive</p>
                    </Grid.Column>
                    <Grid.Column width={2} textAlign="center">
                        <CornerIcons one="user outline" two="close" colorOne="grey" colorTwo="red" />
                    </Grid.Column>
                    <Grid.Column width={8}>
                        
                        <Input error placeholder="Nom du client" onChange={(e) => {
                            if (e.target.value === state.openedCustomer.name)
                                setState({deletationConfirmation: true})
                            else if (state.deletationConfirmation && state.deletationConfirmation !== e.target.value) {
                                setState({deletationConfirmation: false})
                            }
                        }} />
                        {
                            state.deletationConfirmation
                        && <AnimatedButton className="vibrate appButton margedLeft" visible={<span className="txtSM">{`Supprimer ${state.openedCustomer.name}`}</span>} invisible={<CornerIcons one="user outline" two="close" colorTwo="red" />} onClick={() => deleteCustomer(state.openedCustomer)} />
                        }
                       
                        
                    </Grid.Column>

                </Grid>
            </div>
        )
    }

    // prio (enum) : 'high', 'medium' or 'low'
    const priodot = (prio) => <Icon 
        name="circle" 
        size="tiny" 
        color={prio === 'high' ? "red" : (prio === "low" ? 'green' : 'orange')} 
    />

    const lastModification = (timestamp) => Utils.formatedDate(new Date(timestamp), true)

    const lastModificationDays = (timestamp) => parseInt(((Date.now() - timestamp) / 60 / 60 / 24) / 1000 )

    const crmCustomerColor = (timestamp, addedOn) => {
        const colors = {
            red: 'rgba(255, 17, 17, 0.3)',
            orange:'rgba(255, 115, 0, 0.3)',
            green:'rgba(29, 206, 138, 0.3)'
        }
        let lastModified = lastModificationDays(timestamp || addedOn)   // in days

        return (lastModified < 3 ? colors.green : lastModified >= 7 ? colors.red : colors.orange); 
    }

   
    
    const openCustomer = (customer) => {
        // open customer and reset app bar 
        initCrm({openedCustomer: customer}, true)
    }

    /* CONTENT */
    ///// QUOTATION TABLE LINE
    const customerLine = (c) => 
        <Table.Row 
            key={Utils.keyExtractor()} 
            className="zoomOnHoverLight" 
            onClick={() => openCustomer(c)}
            style={{backgroundColor: crmCustomerColor(c.timestamp, c.addedOn)}}
        >
            {/* NAME */}
            <Table.Cell>
                {c.name}
            </Table.Cell>
            
            {/* ADDRESS */}
            <Table.Cell>
                {c.address}
            </Table.Cell>

            {/* EMAIL */}
            <Table.Cell>
                {c.email}
            </Table.Cell>
            
            {/* TELEPHONE */}
            <Table.Cell>
                {c.tel}
            </Table.Cell>

            {/* PRIORITY */}
            <Table.Cell textAlign="center">
                {priodot(c.priority)}
            </Table.Cell>

            {/* LAST MODIF */}
            <Table.Cell textAlign="center">
                <Head
                    className="centered"
                    size="tiny"
                    title={`Le ${lastModification(c.timestamp || c.addedOn)}`}
                    content={`Il y a ${lastModificationDays(c.timestamp || c.addedOn)} jours`}
                />
                {/* <div style={{display: "flex", flex : 1, flexDirection: 'row'}}>
                    <div style={{display: "flex", flex: .5}}>
                        {
                            `Le ${lastModification(c.timestamp || c.addedOn)}`
                        }
                    </div>
                    <div style={{display: "flex", flex: .5}}>
                        {
                            `Il y a ${lastModificationDays(c.timestamp || c.addedOn)} jours`
                        }
                    </div>
                </div> */}
            </Table.Cell>

             {/* ADDED DATE/BY */}
             <Table.Cell textAlign="center">
             <div className="flexy flexCenter">
                <Head
                    className="centered"
                    size="tiny"
                    title={Utils.formatedDate(new Date(parseInt(c.addedOn)), true)}
                    content={`Par ${Utils.teamMemberFromId(c.addedBy, {returnKey: 'username'}, props.session.TML)}`}
                />
               
            </div>
                
            </Table.Cell>

        </Table.Row>

    const closeNewContactForm = () => setState({addingNewContact: false})

    
    //console.log("Crm state", state)


    return(
       
            state.loading
            ? <AppLoader
                open={state.loading}
                title="Chargement du fichier client"
                subTitle="veuillez patienter"
            />
            : (
                state.openedCustomer
                ? customerFile(state.openedCustomer)
                : <div>
                    {/* ADD NEW CUSTOMER MODAL */}
                    <Modal size="large" className="flexy flexCenter tekoAll" dimmer="blurring" open={state.addingNewContact} onClose={closeNewContactForm}>
                        <NewCustomerForm 
                            relatedActivity={state.relatedActivity} 
                            close={closeNewContactForm}
                            onSave={() => initCrm({addingNewContact: false}, true)}
                            useLink={props.location && props.location.state && props.location.state.create && '/activities'}    
                        />
                    </Modal>
                    <Table celled>
                        <Table.Header style={{textAlign: 'left'}}>
                            <Table.Row className="centered">
                                <Table.HeaderCell width={3}>Nom </Table.HeaderCell>
                                <Table.HeaderCell width={2}>Adresse</Table.HeaderCell> 
                                <Table.HeaderCell width={2}>E-mail</Table.HeaderCell> 
                                <Table.HeaderCell width={2}>Téléphone</Table.HeaderCell> 
                                <Table.HeaderCell width={1}>Priorité</Table.HeaderCell>
                                <Table.HeaderCell width={2}>Dernière Modification</Table.HeaderCell>
                                <Table.HeaderCell width={2}>Ajouté</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body style={{overflow: 'scroll'}}>
                            {
                                state.customers && state.customers.length 
                                ? state.customers.map((c) => customerLine(c))
                                : null
                            }
                        </Table.Body>

                        {
                            (!state.customers || !state.customers.length)
                            && <Table.Footer className="flexy flexCenter">Aucun client listé</Table.Footer>
                        }
                    </Table>  
                </div>
            )
        //)  
       
    )
}

export default connect(Utils.mapStateToProps)(Crm);