import React from 'react';
import { Dropdown } from 'semantic-ui-react'

/**
 * 
 * @param {*} props 
 * ##PROPS
 *  - onChange (function) : method called on change (event, {value})
 *  - value (controll component by passing a value)
 */
const TopicsSelection = (props) => {
    const categoriesSelectionOptions = [
        {key: "cat_mac", value: "maconnerie", text: "Maçonnerie"},
        {key: "cat_men", value: "menuiserie", text: "Menuiserie"},
        {key: "cat_ren", value: "renovation", text: "Rénovation"},
        {key: "cat_cou", value: "couverture", text: "Couverture"},
        {key: "cat_ter", value: "terrassement", text: "Terrassement"},
        {key: "cat_ent", value: "entretien", text: "Entretien"},
        {key: "cat_iso", value: "isolation", text: "Isolation"},
        {key: "cat_ame", value: "amenagement", text: "Aménagement"}
      
    ]

    return (
        <span style={props.containerStyle}>
            <Dropdown
                search 
                multiple
                value={props.value}
                placeholder="Séléctionner une ou plusieurs catégories"
                options={categoriesSelectionOptions}
                onChange={props.onChange}
            />
        </span>
    )
}

export default TopicsSelection