import React, {useState} from 'react'
import {Table, TextArea, Input, Segment, Checkbox, Dropdown, Form, Label, Icon} from 'semantic-ui-react'
import CornerIcons from './CornerIcons'
import UnitsSelection from './UnitsSelection'
import TvaDropdown from './TvaDropdown'
// const tvaOptions = [
//     {key: 'tva_20', text: '20', value: 20},
//     {key: 'tva_10', text: '10', value: 10},
//     {key: 'tva_5.5', text: '5,5', value: 5.5},
//     {key: 'tva_2_1', text: '2,1', value: 2.1}
// ]

// const TvaDropdown = ({onChange, defaultValue}) => <Dropdown inline
//     onChange={(e, {value}) => onChange(value)}
//     options={tvaOptions}
//     defaultValue={defaultValue || tvaOptions[0].value}
// />

/******************************************************** TABLEROW ******************************************************/
/**
 * ##PROPS
 *  - pickProduct (function) : method called each time client wants to pick one of its products
 *  - pickedProduct {object} : currenlty picked product
 *  - unpickProduct (function) : callable to unpick a product 
 *  - validateDocLine (function) : called to validate docLine *(docline, rowId)=>{}*
 *  - removeRow (function) : function called to remove a line from accountingDocument object (using component rowId)
 *  - rowId (string) : unique timestamp rowId used for tracking TableRow btw components
 *  - displayMode (bool) : indicate if TableRow needs to render in displayMode (default: false)
 *  - presetDocLine {object} : a docLine to preset the row with (used by edition mode)
 */
const TableRow = (props) => {
    let [hidden, setHidden] = useState(false)
    let [docLine, setDocLine] = useState({})
    let [displayMode, setDisplayMode] = useState(props.displayMode || false)
    let [presetDocLineAdded, setPresetDocLineAdded] = useState(false)
    const rowId = props.rowId

    if (hidden) return null 
    else if(props.presetDocLine && !presetDocLineAdded) {
        //console.log('case props.presetDocLine && !presetDocLineAdded, setDisplayMode to true')
        setPresetDocLineAdded(true)
        setDocLine({...props.presetDocLine})
        //
        setDisplayMode(true)
        //

    } else {

        //console.log(`TableRow ${rowId}`, {props, docLine, displayMode})

        const totalHT = () => {
            const {quantity, unitPrice} = docLine

            return( parseFloat(quantity || 0) * parseFloat(typeof unitPrice !== 'undefined' ? (unitPrice || 0) : (props.pickedProduct && props.pickedProduct.unitPrice) || 0) )
        }
        const setLine = (merger) => {
            if (merger.importProduct) {
                //console.log('setLine receives "importProduct" prop. Calling pickProduct()')
                props.pickProduct()
            }
            //console.log('setting docLine with merger', merger)
            setDocLine({...docLine, ...merger})
        }

        const validateDocLine = async() => {
            // save docline within AccountingDocument this.state.accountingDocument (pdfmake DD object unmounted root) calling validateQuoline prop
            props.validateDocLine(docLine, rowId)

            // set component as displayMode and editable (front end)
            setDisplayMode(true)

        }

        const reset = () => {
            if (window.confirm(displayMode ? 'Supprimer la ligne ?' : 'Effacer la ligne en cours ?')) {
                if (displayMode) {
                    props.removeRow(rowId)
                    setHidden(true)
                    
                } else {
                    props.unpickProduct()
                    setDocLine({})
                    setDisplayMode(false)
                }
                
            }
        }

        
        // console.log('docLine.pickedProductId', docLine.pickedProductId)
        // console.log('props.pickedProduct.productId', props.pickedProduct && props.pickedProduct.productId)
        // console.log('CONDITION', props.pickedProduct && !(docLine.pickedProductId === props.pickedProduct.productId))

        // if props.pickedProduct changes, store it within docLine and reset unitPrice
        if (props.pickedProduct && !(docLine.pickedProductId === props.pickedProduct.productId)) {
            //console.log('TableRow %s pickedProduct change triggered: calling setLine', rowId)
            setLine({name: props.pickedProduct.name, pickedProductId: props.pickedProduct.productId, unitPrice: props.pickedProduct.unitPrice, unit: props.pickedProduct.unit, tva: props.pickedProduct.tva})
        }

        // if importProduct as been disengaged, reset props.pickedProduct
        if (!docLine.importProduct && props.pickedProduct) {
            //console.log('TableRow !docLine.importProduct && props.pickedProduct')

            props.unpickProduct()
        }
        
        let empty = !Object.keys(docLine).length && !props.pickedProduct
        return (<Table.Row>  
            {/* Libellé */}                                      
            <Table.Cell textAlign="center">
                {
                    displayMode
                    ? <p className="leftAligned">{docLine.name}</p>
                    : <Form><TextArea className="accountingDocumentInput" style={{minHeight: 100}} onChange={(e) => setLine({name: e.target.value})} value={empty ? "" : (docLine.name || (props.pickedProduct && props.pickedProduct.name))}/></Form>  // Form to use semantic styling
                }
                
            </Table.Cell>

            {/* Quantité */}
            <Table.Cell textAlign="center">
                {
                    displayMode
                    ? <p>{docLine.quantity}</p>
                    : <Input style={{width: '5em'}} onChange={(e) => setLine({quantity: e.target.value})} value={empty ? "" : docLine.quantity} />
                }
                
            </Table.Cell>

            {/* Unité */}
            <Table.Cell textAlign="center">
                <UnitsSelection value={docLine.unit || (props.pickedProduct && props.pickedProduct.unit)} onChange={(e, {value}) => setLine({unit: value})} />
            </Table.Cell>

            {/* Prix Unitaire HT */}
            <Table.Cell textAlign="center">
                {
                    displayMode
                    ? <p>{docLine.unitPrice}</p>
                    : <Input style={{width: '10em'}} icon="euro" className="accountingDocumentInput" onChange={(e) => setLine({unitPrice: e.target.value})} value={typeof docLine.unitPrice !== 'undefined' ? docLine.unitPrice : (props.pickedProduct && props.pickedProduct.unitPrice) || ""}/>
                }
                
            </Table.Cell>

            {/* TVA */}
            <Table.Cell>
                {
                    displayMode
                    ? <span>{docLine.tva}</span>
                    : <span>
                        <TvaDropdown 
                            onChange={(tva) => setLine({tva})}
                            value={docLine.tva}
                        />
                        %
                    </span>
                }
                
            </Table.Cell>

            {/* TOTAL HT */}
            <Table.Cell textAlign="center">
                <p>{totalHT().toFixed(2)}</p>                
            </Table.Cell>

            {/* Line Options */}
            <Table.Cell textAlign="center" style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                {   
                    /* import product or create a new one*/
                    !displayMode 
                    && <Segment>
                        <Label attached="top" className={`impFlex flexCenter txtS ${(docLine.storeAsProduct || props.pickedProduct) ? "lightGreen" : ""}`}>
                            {docLine.importProduct ? "Produit importé" : "Nouveau Produit"}
                        </Label>
                        <Checkbox 
                            checked={empty ? false : (docLine.importProduct || docLine.storeAsProduct) && true} 
                            slider 
                            onChange={() => setLine({
                                storeAsProduct: 
                                    docLine.importProduct          // dont storeAsProduct if importProduct
                                    ? false
                                    : !docLine.storeAsProduct,     // else toggle storeAsProduct
                                
                                importProduct: 
                                    (!docLine.storeAsProduct && docLine.importProduct)    // if importAsProduct 
                                    ? false                                                 // set to false
                                    : docLine.importProduct                                // else keep current state
                            })}
                        />
                    </Segment>
                }
                {
                    (!docLine.storeAsProduct && !displayMode) 
                    && <CornerIcons 
                        onClick={() => setLine({importProduct: true, storeAsProduct: false})} 
                        one={<Icon name="box" color="grey" size="small" className="overable lightMargin" />} 
                        corner={"top"} // /!\ setting right or left will change the icon's arrow direction 
                        two="long arrow alternate down" 
                        colorTwo="green"
                    />
                }

                <div className="flexy flexCenter">
                    
                    {
                        displayMode 
                        ? <Icon circular name="edit" size="small" className="greyAppButton overable lighMargin" onClick={() => setDisplayMode(false)}/>
                        : <Icon circular name="check" color="teal" size="small" className="greyAppButton overable lightMargin" onClick={validateDocLine} />
                    }
                    <Icon circular name="close" size="small" className="redAppButton overable lightMargin vibrate" onClick={reset} />
                </div>
            </Table.Cell>
        </Table.Row>)
    }
}

export default TableRow