import React from 'react'
import { Icon, List, Modal, Grid, Input, Segment, Divider, Button, Select } from 'semantic-ui-react'
import Searcher from '../components/Searcher'
import Utils from '../constants/Utils'
import CornerIcons from '../components/CornerIcons'
import Head from '../components/Head'
import TopicsSelection from '../components/TopicsSelection'
import TwoButtons from '../components/TwoButtons'
import Layout from '../constants/Layout'
import moment from 'moment'
import ModifiableItem from '../components/ModifiableItem'
import AnimatedButton from '../components/AnimatedButton'
import UnitsSelection from '../components/UnitsSelection'
import TvaDropdown from '../components/TvaDropdown'
import AppLoader from '../components/AppLoader'

import { connect } from 'react-redux'

const addNewIcon = ({onClick}) => <CornerIcons key={Utils.keyExtractor()} className="overable margedLeft" one="box" two="add" colorTwo="teal" onClick={onClick}/>
//const deleteIcon = ({onClick}) => <CornerIcons key={Utils.keyExtractor()} className="overable margedLeft" one="box" two="close" colorTwo="red" onClick={onClick}/>


class Products extends React.Component {
    constructor(props) {
        super(props);
        this.closeProd = this.closeProd.bind(this)
        
        this.state = {
            openedProduct : undefined, 
            createNew: false,
            products : [],
            loading: true
        }
    
        this.product = {
            productId: Utils.keyExtractor(),
            addedOn: Date.now()
        }

    }
    



    editProductsList = (list) => {
        let backUp = this.state.products
        this.setState({backUp, products : list || []})
    }

    onUpdateSearch = (search) => {
        let isSearching = search && search.length > 0
        this.setState({isSearching})
        if (!isSearching)
            this.initProducts()
    }

    // responsible for re-fecthing products list and set the updated version of openedProduct (if already set in state)
    updateProducts = async() => {
        let {error, response: products} = await Utils.fetchApi({
            request: "products",
            method: 'get',
            client: this.props.session.fetchCredentials

        })
        if (error) {
            this.setState({products: this.state.products || []})
            global.fireMsg({error: 'Erreur avec le serveur: récupération des dossiers impossible, veuillez recharger la page'})
        } else {
            if (this.state.openedProduct) {
                // reset updated version of openedProduct
                for (var prod of products) {
                    if (prod.productId === this.state.openedProduct.productId)
                        this.setState({products, openedProduct: prod, createNew: false})
                    else if (prod === products[products.length-1])
                        this.setState({products, openedProduct: undefined, createNew: false})
                }
            }
            else this.setState({products, createNew: false})
        }
    }
    //
    openProduct = (openedProduct) => {
        this.setState({openedProduct, createNew: false})
    }

    //renderItem = (prod) => <div>{prod.name}</div>
    renderItem = (prod) => <Grid key={Utils.keyExtractor()} className={`padded1 overable ${Layout.isSmallDevice ? 'txtXL' : (Layout.isTablet ? 'txtL' : 'txtM')}`} onClick={() => {this.openProduct(prod)}}>
           
        {/* title and ref (productId) */}
        <Grid.Column width={(Layout.isSmallDevice || Layout.isTablet) ? 4 : 5}>
            <div className="flexy">
                {/* {this.prodImage(prod)} */}
                <Icon circular floated="left" name="box" />
                <List.Content style={{justifyContent: 'center'}}>
                    <List.Header><b>{prod.name || 'pas de dénomination'}</b></List.Header>
                    <List.Description className="emDotEight flexy flexCenter">{`#${prod.productId || ' '}`}</List.Description>
                </List.Content>
                
            </div>
        </Grid.Column>

         {/* Price */}
         <Grid.Column width={(Layout.isSmallDevice || Layout.isTablet) ? 2 : 3} className="rightAligned">
            <Head 
                iconStyle={{fontSize: 30}}
                size="medium"
                title={<p className="txtL">{prod.unitPrice}</p>}
                content="Prix unitaire"
                icon="euro"
            />
        </Grid.Column>

          {/* tva */}
          <Grid.Column width={2}>
            <div className="flexy flexCenter">
                <Head 
                    size="medium"
                    iconStyle={{fontSize: 30}}
                    title={`${(prod.tva || 20)}%`}
                    content="TVA"
                    icon="payment"
                />                
            </div>
        </Grid.Column>
        
        {/* Topics */}
        <Grid.Column width={2} className="centered">
            <div className="left-aligned tekotxt"><Icon size="small" name="folder outline" /><br /> {(prod.topics && prod.topics.length > 1) ? "Catégories" : "Catégorie"} <div className="silver centered">{(prod.topics && prod.topics.length) ? prod.topics.join(', ') : "-"}</div></div>
        </Grid.Column>
        
        {/* dates */}
        <Grid.Column width={Layout.isTablet ? 5 : 4}>
            <span className="flexy flexCenter" style={{height: '100%'}}>
                <div className="left-aligned tekotxt"><Icon size="small" name="calendar alternate outline" /><br />Ajouté le <div className="silver">{moment(Number(prod.addedOn)).format('LL')}</div></div>
                <div className="left-aligned tekotxt marginHorizontal"><Icon size="small" name="edit" /><br />Modifié le <div className="silver">{prod.lastModified ? Utils.formatedDate(new Date(prod.lastModified), true) : '--/--/----'}</div></div>
            </span>
        </Grid.Column>

        {/* */}
        <Grid.Column width={16}>
            <Divider />
        </Grid.Column>
    </Grid> 
    renderAllProducts = () => <List animated divided relaxed className="tekoAll" style={{fontSize: '1.5em'}}>
        {
            (this.state.products && this.state.products.length)
            ? this.state.products.map((p) => this.renderItem(p))
            : (
                this.state.isSearching 
                ? <div className="flexy flexCenter emOne negativeText">Pas de résultat</div>
                : <div className="flexy flexCenter emOne">Vous n'avez pas enregistré d'article</div>
            )
        }
    </List>

    setAppBar = () => {
        let products = this.state.products || []
        this.props.appBarSetter(
            {
                viewName: "Liste de vos articles",
                controllers: [
                    <Icon key={Utils.keyExtractor()} className="overable" size="large" name="arrow left" onClick={() => this.setState({openedProduct: undefined, createNew: false})} />,
                    addNewIcon({onClick: () => this.setState({createNew: true})}),
                    //deleteIcon({onClick: () => this.removeOpenedProduct()})
                ],
                searcher: <Searcher 
                    data={products}
                    dataLen={products.length}
                    dataName="article"
                    editList={this.editProductsList.bind(this)}
                    onUpdateSearch={this.onUpdateSearch.bind(this)}
                />,
                centerController: "",
                bottomController: ""
                
            }
        );
    }
    // is the resetView method passed to children 
    initProducts = async() => {
        let {error, response: products} = await Utils.fetchApi({
            request: "products",
            method: 'get',
            client: this.props.session.fetchCredentials

        })
        if (error) {
            console.error('initProduct Error', error)
            this.setState({products: [], loading: false})
            global.fireMsg({error: 'Récupération des articles impossible, veuillez recharger la page'})
        } else {
            // console.log('products', products)
            this.setState({products, loading: false})
        }
        this.setAppBar()
    }

    componentDidMount() {
        this.initProducts()
    }
    closeNewProduct = () => {
        if (window.confirm('Abandonner le nouvel article en cours ?'))
            this.setState({createNew: false})
    }

    // sets a new product :
    //  topics,  
    setProduct = (key, value) => {
        this.product = {...this.product, [key] : value}
    }

    save = async() => {
        // console.log('saving new product', this.product)
        let {error} = (this.product.name && this.product.unitPrice) 
        ? await Utils.fetchApi({
            request: 'createProduct',
            body: this.product,
            method: 'post',
            client: this.props.session.fetchCredentials

        })
        : {error: 'Le nouvel article doit avoir au moins une dénomination et un prix pour être enregistré'}

        if (error) {
            console.error(error)
        } else {
            this.product = {productId: Utils.keyExtractor()}
            this.updateProducts()
        }

        global.fireMsg({error, positive: !error && 'Nouvel article sauvegardé'})
        this.setState({createNew: false})
    }

    fields = () => [
        { name: "Dénomination de l'article", key: "name", icon: 'comments outline' },
        { name: "Prix unitaire", key: "unitPrice", icon: 'euro' },
        { name: "Unité", key: 'unit', icon: 'neuter', component: <UnitsSelection value={this.product.unit} onChange={(e, {value}) => this.setProduct('unit', value)} />},
        {name: "Tva", key: 'tva', icon: 'law', component: <TvaDropdown value={this.product.tva} onChange={(tva) => this.setProduct('tva', tva)} defaultValue={20}/>},
        { name: "Catégorie", key: "topics", icon: 'folder outline', component: <TopicsSelection containerStyle={{width: '40vw'}} onChange={(__, {value}) => this.setProduct('topics', value)}/> },
    ]


    fieldRenderer = ({key, name, component, icon}) => <Head 
        contentStyle={{width: '100%'}}
        className="allWidth centered"
        key={`naf_${key}`}
        title={name}
        content={component || <Input style={{width: '40vw'}} value={this.product[key]} onChange={(e) => this.setProduct(key, e.target.value)}/>}
        icon={icon}
    />

    // Product Edition handlers
    productEditionHandler = async({ key, newValue }) => {
        const {productId} = this.state.openedProduct
        let {error, response} = await Utils.fetchApi({
            request: "mergeProduct",
            body: { productId, mergeObject: { [key]: newValue }, returnUpdatedProducts: true },
            client: this.props.session.fetchCredentials

        })
        if (error) {
            console.error('productEditionhandler ERROR', error)
            return {error}
        } else {
            // console.log('UPDATED PRODUCTS', response)
            for (let p of response) {
                if (p.productId === productId) {
                    this.setState({products: response, openedProduct: p})
                }

            }
            
            return {response}
        }
    }

    onChangeMethodCalled = ({error}) => {
        if (error) {
            console.error(`onChangeMethodCalled ERROR: ${error}`)
        } 
        else this.updateProducts()
        
        global.fireMsg({error, positive: !error ? 'Article mis à jour' : undefined})
    }

    closeProd = () => {
        const _close = () => this.setState({openedProduct: undefined})

        let unsavedEdition = document.querySelector('.teal.small.send.circular.inverted.icon.overable')
        unsavedEdition
        ? window.confirm('Modification non enregistrée.\nQuitter sans sauvegarder ?') && _close()
        : _close()
    }
    
    removeProduct = async() => {
        const {openedProduct} = this.state

        if (window.confirm(`Supprimer définitivement la référence #${openedProduct.productId} ?`)) {
            let {error} = await Utils.fetchApi({
                request: "removeProduct",
                body: {productId: openedProduct.productId},
                client: this.props.session.fetchCredentials

            })
            if (error) {
                console.error("Remove Product operation failed", error)
            } else {
                this.updateProducts()
            }
            
            global.fireMsg({error, positive: !error && `L' article #${openedProduct.productId} a été supprimé`})
        }
    }
    //
    render() {
        const { loading, createNew, openedProduct: op, products } = this.state
        return(
            loading
            ? <AppLoader
                open={loading}
                title="Chargement des articles en cours"
                subTitle="veuillez patienter"
            />
            : <div>
               <Modal size="large" open={createNew} onClose={this.closeNewProduct}>
                    <Grid>
                        <Grid.Column width={6}>
                            <div className="flexy flexCenter allSize" style={{flexDirection: 'column'}}>
                                <Head
                                    title="Nouvel Article"
                                    icon={<Icon name="box" size="huge" />}
                                    content={`Enregistrement d'un ${products.length ? ((products.length+1)+'ème') : '1er'} article`}
                                />
                                <Segment className="centered">
                                    <TwoButtons 
                                        buttonOne={{
                                            className: "greenAppButton",
                                            title: "Enregistrer",
                                            clickHandler: this.save.bind(this),
                                            icon: "upload"
                                        }}
                                        buttonTwo={{
                                            className: "redAppButton",
                                            title: "Annuler",
                                            icon: "cancel",
                                            clickHandler: this.closeNewProduct
                                        }}
                                    />
                                    {/* <Button className="greenAppButton" onClick={this.publish}>Publier <Icon name="upload" /></Button> */}
                                </Segment>
                            </div>
                        </Grid.Column>
                        <Grid.Column width={10}>
                            <div className="padded3">
                                {
                                    this.fields().map((f) => this.fieldRenderer(f))
                                }
                               
                            </div>
                        </Grid.Column>
                    </Grid>
                </Modal> 

                <Modal size="large" open={typeof op !== 'undefined'} onClose={this.closeProd}>
                    {
                        op && <div className="padded3">
                            <Segment raised>
                                <Grid>
                                    <Grid.Column centered width={6}>
                                        <Head
                                            className="centered"
                                            title={op.name}
                                            content={`#${op.productId}`}
                                            icon="box"
                                        />
                                    </Grid.Column>
                                    <Grid.Column centered width={10}>
                                        <span className="flexy flexCenter" style={{height: '100%'}}>
                                            <div className="left-aligned tekotxt"><Icon size="small" name="calendar alternate outline" /><br />Ajouté le <div className="silver">{moment(Number(op.addedOn)).format('LL')}</div></div>
                                            <div className="left-aligned tekotxt marginHorizontal"><Icon size="small" name="user outline" /><br />Ajouté par <div className="silver">{Utils.teamMemberFromId(op.addedBy, {returnKey:'username'}, this.props.session.TML)}</div></div>
                                            <div className="left-aligned tekotxt"><Icon size="small" name="edit" /><br />Modifié le <div className="silver">{op.lastModified ? Utils.formatedDate(new Date(op.lastModified), true) : '--/--/----'}</div></div>
                                        </span>
                                    </Grid.Column>
                                    
                                </Grid>
                            </Segment>
                            <ModifiableItem
                                title="Dénomination de l'article"
                                titleStyle={{fontSize: '1.2em'}}
                                content={`#${op.productId}`}
                                icon="box"
                                pair={{key: 'name', oldValue: op.name}}
                                changeMethodAsync={this.productEditionHandler}
                                onChangeMethodCalled={this.onChangeMethodCalled}
                            />
                            <Divider />
                            <ModifiableItem
                                title="Prix à l'unité"
                                titleStyle={{fontSize: '1.2em'}}
                                content={`#${op.unitPrice}`}
                                tag={<span><Icon name="euro" size="small"/></span>}
                                pair={{key: 'unitPrice', oldValue: op.unitPrice}}
                                changeMethodAsync={this.productEditionHandler}
                                onChangeMethodCalled={this.onChangeMethodCalled}
                            />
                            <Divider style={{marginBottom: '4px'}}/>
                            <Head
                                className="noSpace"
                                title="Unité"
                                titleStyle={{fontSize:".72em"}}
                                titleClassName="silver"
                                subHeaderStyle={{fontSize: '1.2em'}}
                                content={<UnitsSelection value={op.unit} onChange={(e, {value}) => this.productEditionHandler({key: "unit", newValue: value})} />}
                            />
                             <Divider style={{marginBottom: '4px'}}/>
                            <Head
                                className="noSpace"
                                title="TVA"
                                titleStyle={{fontSize:".72em"}}
                                titleClassName="silver"
                                subHeaderStyle={{fontSize: '1.2em'}}
                                content={<TvaDropdown value={op.tva} onChange={(value) => this.productEditionHandler({key: "tva", newValue: value})} />}
                                //content={<UnitsSelection value={op.unit} onChange={(e, {value}) => this.productEditionHandler({key: "unit", newValue: value})} />}
                            />
                            <Divider />
                            <ModifiableItem
                                title={(op.topics && op.topics.length > 1) ? "Catégories" : "Catégorie"}
                                titleStyle={{fontSize: '1.2em'}}
                                renderValue={(value) => value && value.join(', ')}
                                pair={{key: 'topics', oldValue: op.topics}}
                                customEditionComponent={(changeHandler, value) => <TopicsSelection 
                                    value={value}
                                    onChange={(e, {value}) => changeHandler({target:{value}})}
                                />}
                                changeMethodAsync={this.productEditionHandler}
                                onChangeMethodCalled={this.onChangeMethodCalled}
                            />
                            <Divider />
                            <div className="flexy flexCenter">
                                <AnimatedButton visible="Ok" invisible={<Icon size="large" name="sign-out" />} className="widthTen centered greenAppButton" onClick={this.closeProd} />
                                <AnimatedButton visible="Supprimer" invisible={<Icon name="close" size="large" />} className="appButton vibrate" style={{borderRadius: '1em'}} onClick={this.removeProduct} />
                            </div>
                            
                        </div>
                    }
                    
                </Modal>
               
               { this.renderAllProducts() }
            </div>
        )
    }
}

export default connect(Utils.mapStateToProps)(Products)