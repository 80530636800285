/*  ACCOUNTING DOCUMENT TABLE ROWS 
    handle accounting document articles lines states
*/
import React, { useState } from 'react'
import TableRow from './TableRow'
/**
 * 
 * @param {*} props
 *  - pickProduct
 *  - addOne
 *  - toggleAddOne
 *  - setTotals : sets AccountingDocuments.js component state.accountingDocument.totals 
 *  - initialRowsStates : rowsStates to use while initiating (*each object must contain a docLine key*)
 */
const TableRows = (props) => {
    //console.log('TableRows props.initialRowsStates', props.initialRowsStates)
    // all rows states
    let [rowsStates, setRowsStates] = useState({})
    let [lastRow, setLastRow] = useState(Date.now())
    
    // row state setter

    const setRowState = (rowId, stateProps) => setRowsStates({...rowsStates, [rowId]: {...rowsStates[rowId], ...stateProps}})
    
    const removeRow = async(rowId) => {
        let cleanIds = await Promise.resolve(Object.keys(rowsStates).filter((k) => k !== rowId))
        let cleanRowsArr = await Promise.resolve(cleanIds.map((ci) => ({id: ci, rowState: rowsStates[ci]}) ))
        //console.log('removeRow cleanRowsArr', cleanRowsArr)
        let cleanRows = await new Promise(async(cleaned) => {
            if (!cleanRowsArr.length) cleaned({})
            else {
                let CR = {}
                for (let row of cleanRowsArr) {
                    CR[row.id] = await Promise.resolve(row.rowState)

                    if (row === cleanRowsArr[cleanRowsArr.length-1])
                    cleaned(CR)
                }
            }
        })
        
        props.setTotals(cleanRows)
        setRowsStates(cleanRows)

     
    }
    const validateDocLine = (docLine, rowId) => {

        if (typeof docLine === 'object') {
            props.setTotals({...rowsStates, [rowId]: {docLine, displayMode: true}})
            setRowState(rowId, { docLine, displayMode: true }) 
        }

        props.toggleAddOne()
    }

    // call props.pickProduct with a callback to set pickedProduct locally
    const pickProduct = (productSetter) => {
        props.pickProduct(productSetter.bind(this))
    }

    const unpickProduct = (rowId) => {
        setRowState(rowId, {pickedProduct: undefined})
    }

    const TABLE_ROW = (id) => <TableRow 
        key={id}
        rowId={id} 
        presetDocLine={rowsStates[id] ? rowsStates[id].docLine || (rowsStates[id].unitPrice && rowsStates[id]) : undefined}
        removeRow={removeRow.bind(this)} 
        validateDocLine={validateDocLine.bind(this)} 
        unpickProduct={() => unpickProduct(id)} // productSetter preProped with rowId:id
        pickProduct={() => pickProduct((pickedProduct) => setRowState(id, {pickedProduct}))} // productSetter preProped with rowId:id
        pickedProduct={rowsStates[id] && rowsStates[id].pickedProduct}
        displayMode={rowsStates[id] && rowsStates[id].displayMode}
    />

    const rsKeys = Object.keys(rowsStates)

    let rowsIds = rsKeys.sort((a, b) => {
        return parseInt(a) - parseInt(b);
    })

    if (props.initialRowsStates && (!rsKeys || !rsKeys.length)) {
        //console.log('set block')
        setRowsStates(props.initialRowsStates)
        props.setTotals(props.initialRowsStates)
    }
    
    if (props.addOne) {                                         /* Add a new row if needed without changing the id each time to allow TableRow before id state being stored in TableRows rowsStates */
        let unConsumed = (lastRow && !rowsStates[lastRow])      // know if last id has been consumed (used)
        let id = unConsumed ? lastRow : Date.now()              // use last id if unConsumed
        !unConsumed && setLastRow(id)                           // if consumed, reset lastRow
        rowsIds = [...rowsIds, id]                              // set rowsIds with appropriate ID
    }
    //console.log('rowsStates', rowsStates)
    //console.log('rowsIds', rowsIds)
    return(
        
        rowsIds.map((id) => TABLE_ROW(id))
    )
    
}

export default TableRows