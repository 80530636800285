const tintColor = '#2f95dc';

export default {
  tintColor,
  tabIconDefault: '#ccc',
  tabIconSelected: tintColor,
  tabBar: '#fefefe',
  errorBackground: '#ed1a0a',
  errorText: '#fff',
  warningBackground: '#EAEB5E',
  warningText: '#666804',  
  noticeBackground: tintColor,
  noticeText: '#fff',
  anthracite : '#333d40',
  amcred : '#a74230',
  white : '#ffffff',
  cyan: '#49b675',
  quetzalGreen: '#006E6D',
  desktopFolders: '#767676'
};
