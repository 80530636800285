import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Icon, Loader, Modal, Image, Divider, List, Grid, Input, Segment, Button } from 'semantic-ui-react'
import Utils from '../constants/Utils'
import Head from '../components/Head'
import Layout from '../constants/Layout'
import ActivityFile from '../components/ActivityFile'
import Searcher from '../components/Searcher'
import CornerIcons from '../components/CornerIcons'
import CustomersSelection from '../components/CustomersSelection'
import TwoButtons from '../components/TwoButtons'
import AppLoader from '../components/AppLoader'


/* ACTIVITY SCHEMA */
const ActivitySchema = [
    "activityId",
    "name", 
    "openedOn",
    "topics",           
    "customerName",
    "photoReports",   // must take a 'sent' key (timestamp)  
    "files",
    "location",
    "drawings",
    "lastModified",
    "notes",
    "status",
    "historic",
    "quotations",
    "bills"
]


const ActivityStatus = {
    'noDatas' : 
    {
        cantSet: true,
        icon: 'unlink',
        color: 'yellow',
        tooltip: "En file d'attente",
    },

    'noAccountingDocumentIssued' :
    {
        cantSet: true,
        icon: 'half hourglass',
        color: 'yellow',
        tooltip: "En cours de traitement"
    },

    'awaitingTransmission' :
    {
        icon: 'envelope',
        color: 'red',
        tooltip: 'Document en attente de transmission',
        // quotation: false,
        // bill: false
    },
    
    'transmitted':
    {
        icon: 'envelope',
        color: 'teal',
        tooltip: 'En attente d\'une réponse client (devis transmis)',
        quotation: true,
        bill: true
    },

    'signed' :  // case bills : en attente de paiement
    {
        icon: 'half hourglass',
        color: 'teal',
        tooltip: 'En attente de paiement',
        quotation: true,
        //bill: false
    },

    'signedNoBill' :    // case !bills : en attente de réalisation
    {
        icon: 'hourglass start',
        color: 'orange',
        tooltip: "En attente de réalisation",
        quotation: false,
        bill: false
    },

    'denied' :
    {
        icon: 'pencil alternate',
        color: 'red',
        tooltip: 'Le client a répondu défavorablement au devis',
        quotation: true,
        //bill: false
    },

    'paid' :
    {
        icon: 'check',
        color: 'green',
        tooltip: 'La facture a été acquittée',
        //quotation: false,
        bill: true
    },
    
}



/* ACTIVITIES ICONS */
// const addNewIcon = ({onClick}) => <Icon.Group key={Utils.keyExtractor()} className="overable margedLeft" onClick={onClick || undefined} size='large'>
//     <Icon name='folder outline' />
//     <Icon corner name='add' color="teal" />
// </Icon.Group>
const addNewIcon = ({onClick}) => <CornerIcons key={Utils.keyExtractor()} className="overable margedLeft" one="folder outline" two="add" colorTwo="teal" onClick={onClick}/>
const deleteIcon = ({onClick}) => <CornerIcons key={Utils.keyExtractor()} className="overable margedLeft" one="folder outline" two="close" colorTwo="red" onClick={onClick}/>

const unkownUserIcon = () =>  <Icon.Group size="small" data-tooltip="client inconnu">
    <Icon size='large' color='red' name='dont' style={{marginLeft: '2px'}}/>
    <Icon color='black' name='user outline' />
</Icon.Group>

const iconCount = (item, name, icon) => {
    let len = item ? item.length : 0;

    return <span className="flexy flexCenter" style={{height: '100%', flexDirection: 'column'}}>
        {/* <div className="centered tekotxt"> */}
            <Icon  size="small" name={icon} />
            {
                (len>1 && (name!=="Historique" && name!=="Devis")) 
                ? (name+"s") 
                : name
            }
        {/* </div> */}
        <div className="silver">{len}</div>
    </span>
}

// - DEPRECATED (no status conditions) - 
// only one status version (object {key, timestamp} used iso first version array)
// const currentStatusIcon = (_status, addText, bigIcon) => {
//     console.log('_status', _status)   // {key, timestamp}
//     console.log('currentStatusIcon ActivityStatus', ActivityStatus)
//     if (_status) {
//         let status = ActivityStatus[_status] || {} // {tooltip, icon}

//         return <span style={{height: '100%'}} className="flexy flexCenter" data-tooltip={status.tooltip}><Icon style={bigIcon ? {fontSize: '2em'} : undefined} name={status.icon} color={status.color} size="small" />{addText ? status.tooltip : ""}</span>
//     }
// }

/*
    * CURRENT STATUS ICON USING CONDITION *
    case: (!status || noDatas) => if (files || notes || photoReports) => "en cours de traitement" else "en file d'attente" 
    case : (status && status === 'signed') && !(bills && bills.length) => 'signedNoBill' => "En attente de réalisation"
    else ActivityStatus[status]
*/
const currentStatusIcon = (activity, addText, bigIcon) => {
    
    if (activity.status) {
        
        let status = ActivityStatus[(
            ((activity.status && activity.status === 'noDatas') || !activity.status)
            ? (((activity.files && activity.files.length) || activity.notes || (activity.photoReports && activity.photoReports.length)) ? "noAccountingDocumentIssued" : "noDatas")
            : (
                (activity.status && activity.status === 'signed') && !(activity.bills && activity.bills.length)
                ? 'signedNoBill'
                : activity.status
            )
        )] || {} // {tooltip, icon}

        return <span style={{height: '100%'}} className="flexy flexCenter" data-tooltip={status.tooltip}><Icon style={bigIcon ? {fontSize: '2em'} : undefined} name={status.icon} color={status.color} size="small" />{addText ? status.tooltip : ""}</span>
    }
}

/**
 * ##PROPS
 *  - customerName *opt*
 *  - onSave (func)
 *  - newActivity {object} to merge with state.newActivity
 */
class NewActivity extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isPublishing: false,
            newActivity : {
                activityId: Utils.random().toString(),
                name: undefined, 
                customerName: 
                    this.props.customerName 
                    || (this.props.location && this.props.location.state && this.props.location.state.create) 
                    || undefined,
                location: undefined,
                notes: undefined,
                openedOn: Date.now(),
                photoReports: [],     
                files: [],
                drawings: [],
                status: "noDatas",
                historic: []
            }
        }

        this.setter = this.setter.bind(this)
    }

    setter = (key, value) => this.setState({newActivity: {...this.state.newActivity, [key] : value}})

    componentDidMount() {
        if (this.props.newActivity) {
            this.setState({newActivity: {...this.state.newActivity, ...this.props.newActivity}})
        }
    }

    fields = [
        { name: "Nom du dossier", key: "name", icon: 'comments outline' },
        { name: "Adresse du chantier", key: "location", icon: 'map marker' },
        { name: "Client connu", key: "customerName", icon: 'user outline', component: (customers, customerName) => <CustomersSelection customers={customers} value={customerName} style={{width: '50vw'}} onSelect={(__, {value}) => this.setter('customerName', value)}/> },
    ]


    fieldRenderer = ({key, name, component, icon}) => <Head 
        className="allWidth centered"
        key={`naf_${key}`}
        title={name}
        content={component ? component(this.props.customers, this.state.newActivity.customerName) : <Input style={{width: '50vw'}} value={this.state.newActivity[key]} onChange={(e) => this.setter(key, e.target.value)}/>}
        icon={icon}
    />

    save = async() => {
        const {newActivity} = this.state
        
        let {error} = newActivity.name 
        ? await Utils.fetchApi({
            request: 'activityOperations',
            body: {
                requestType: 'storeNew',
                ...newActivity
            },
            client: this.props.fetchCredentials
        })
        : {error: 'Le nouveau dossier doit avoir un nom pour être enregistré'}

        if (error) {
            console.error(error)
        } else {
            this.props.onSave()
        }

        global.fireMsg({error, positive: !error && 'Nouveau dossier sauvegardé'})
    }
    

    render() {
        return(
            <div>
                {
                    this.fields.map((f) => this.fieldRenderer(f))
                }

            <Segment className="centered">
                
                <Button.Group>
                    <Button onClick={this.save.bind(this)} className="greenAppButton">Enregistrer <Icon name="upload" /></Button>
                    <Button.Or text='ou' />
                    {
                        this.props.location && this.props.location.state && this.props.location.state.create
                        ? <Link to={{
                            pathname: "/crm",
                            state: {
                                open: this.state.customerName
                            }
                        }}>
                            Annuler
                            <Icon name="cancel" />
                        </Link>
                        : <Button onClick={this.props.onDeny} className="redAppButton">Annuler <Icon name="cancel" /></Button>

                    }
                </Button.Group>
                
                {/* <TwoButtons 
                    buttonOne={{
                        className: "greenAppButton",
                        title: "Enregistrer",
                        clickHandler: this.save.bind(this),
                        icon: "upload"
                    }}
                    buttonTwo={{
                        className: "redAppButton",
                        title: "Annuler",
                        icon: "cancel",
                        clickHandler: this.props.onDeny
                    }}
                /> */}
                {/* <Button className="greenAppButton" onClick={this.publish}>Publier <Icon name="upload" /></Button> */}
            </Segment>
            </div>
        )
    }

}
    

class Activities extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activities: undefined,
            createNew: false,
        }
        this.remoteRequests = props.location && props.location.state || {}
        this.initActivities = this.initActivities.bind(this)
        this.openActivity = this.openActivity.bind(this)
        this.updateActivities = this.updateActivities.bind(this)
    }

    // new version using Link component's state with auto prop 'location' when passing to={ { pathname:"/ROUTE_NAME", state:{} } }
    async componentDidUpdate() {
        let {newCustomer, open, create} = this.remoteRequests
        
        if (open && !this.state.openedActivity) {
            let openedActivity = await Utils.activityFromId(open, this.state.activities)
            this.setState({openedActivity})
        } 
        else if (create && !this.state.createNew) {
            //console.log('creating new activities for customer', create)
            this.setState({createNew: true, newActivityProps: {customerName: create}})
        }
        else if (newCustomer && !this.state.createNew) {    // from /crm -> NewCustomerForm
            this.props.location.state.newCustomer = undefined   // avoid update loop
            //console.log('creating new customer for current opened activity, \nnewCustomer :', newCustomer)
            if (this.props.session && this.props.session.Client && this.props.session.Client.customers && JSON.stringify(this.props.session.Client.customers).indexOf(newCustomer.name) >= 0) {
                //console.log('**** Client déjà enregistré ****')
            } else {
                // store new client
                global.fireMsg({positive: <span>Enregistrement du nouveau client {newCustomer.name} en cours <Icon loading name="cog" /></span>})

                let {error} = await Utils.fetchApi({
                    request: "newCustomer",
                    body: {...newCustomer, addedOn:Date.now(), addedBy: this.props.session.Client.id},
                    client: this.props.session.fetchCredentials

                });
                global.fireMsg({
                    error: error && "L'enregistrement du nouveau client a échoué. Veuillez recommencer l'opération",
                    positive: !error && "Nouveau client enregistré et lié au dossier"
                })
                !error && this.updateActivities(true)
                }

        } 

        this.remoteRequests = {}
        

    }



    editActivitiesList = (list) => {
        let backUp = this.state.activities
        this.setState({backUp, activities : list || []})
    }

    onUpdateSearch = (search) => {
        let isSearching = search && search.length > 0
        this.setState({isSearching})
        if (!isSearching)
            this.initActivities()
    }

    removeOpenedActivity = async(noConfirm) => {
        const {openedActivity} = this.state
        if (openedActivity) {
            if (noConfirm || window.confirm(`Supprimer définitivement le dossier ${openedActivity.name} ainsi que tout son contenu ?`)) { 
                const {error} = await Utils.fetchApi({
                    request: 'activityOperations',
                    body: {requestType: 'deleteActivity', id: openedActivity.activityId},
                    client: this.props.session.fetchCredentials

                })
                if (error) {
                    console.error(error)
                    global.fireMsg({error: 'Une erreur est survenue lors de la suppression du dossier, veuillez recommencer'})
                } else {
                    global.fireMsg({positive: 'Dossier supprimé'})
                    this.setState({openedActivity: undefined})
                    this.initActivities()
                }
            }
        }
    }

    setControllers = async(activityDisplayMode) => {
        console.log('setcontrollers activityDisplayMode: ', activityDisplayMode)
        const appBarConfig = await Promise.resolve({
            viewName: "Interventions",
            centerController: "",
            [!activityDisplayMode ? 'bottomController' : 'unused']: !activityDisplayMode && "",
            controllers: [
                <Icon key={Utils.keyExtractor()} className="overable" size="large" name="arrow left" onClick={() => { 
                    // close activity
                    this.setState({openedActivity: undefined, createNew: false})
                    // reset app bar
                    this.setControllers()
                }} />,
                activityDisplayMode ? "" : addNewIcon({onClick: () => this.setState({createNew: true})}),
                
                //deleteIcon({onClick: () => this.removeOpenedActivity()})
            ],
            searcher: activityDisplayMode ? "" : <Searcher
                data={this.state.activities || []}
                dataLen={(this.state.activities || []).length}
                dataName="dossier"
                editList={this.editActivitiesList.bind(this)}
                onUpdateSearch={this.onUpdateSearch.bind(this)}
            />
        })

        console.log('appBarConfig', appBarConfig)

        this.props.appBarSetter(appBarConfig)
    }
    // is the resetView method passed to children 
    initActivities = async() => {
        let {error, response: activities} = await Utils.fetchApi({
            request: "activities",
            method: 'get',
            client: this.props.session.fetchCredentials

        })
        if (error) {
            this.setState({activities: []})
            global.fireMsg({error: 'Erreur avec le serveur: récupération des dossiers impossible, veuillez recharger la page'})
        } else {
            this.setState({activities})
        }
        this.setControllers()
    }

    async componentDidMount() {
        this.initActivities()
    }


    openActivity = (openedActivity) => {
        //console.log('opening Activity id', openedActivity.activityId)
        this.setState({openedActivity})
        this.setControllers(true)
    }

    // responsible for re-fecthing activities list and set the updated version of openedActivity (if already set in state)
    // params openLast: *optional*
    updateActivities = async(openLast, _activities) => {
        let {error, response: activities_} = await Utils.fetchApi({
            request: "activities",
            method: 'get',
            client: this.props.session.fetchCredentials

        })
        const activities = _activities || activities_
        if (error) {
            this.setState({activities: this.state.activities || []})
            global.fireMsg({error: 'Erreur avec le serveur: récupération des dossiers impossible, veuillez recharger la page'})
        } else {
            if (this.state.openedActivity || openLast) {
                // hide add new controller
                this.setControllers(true)
                // reset updated version of openedActivity
                for (var act of activities) {
                    // open new created one
                    if (openLast && act === activities[activities.length-1])
                        this.setState({activities, openedActivity: act, createNew: false})
                    
                    // or re-open the already opened one 
                    else if (this.state.openedActivity && (act.activityId === this.state.openedActivity.activityId))
                        this.setState({activities, openedActivity: act, createNew: false})
                }
            }
            else this.setState({activities, createNew: false})
        }
    }
    //


    /* RENDERERS */
    renderItem = ( activity ) => (
        <Grid key={Utils.keyExtractor()} className={`padded1 overable ${Layout.isSmallDevice ? 'txtXL' : (Layout.isTablet ? 'txtL' : 'txtM')}`} onClick={() => {this.openActivity(activity)}}>
           
            {/* title and location */}
            <Grid.Column width={3}>
                <div className="flexy">
                    {/* {this.activityImage(activity)} */}
                    <Icon circular floated="left" name="folder outline" />
                    <List.Content>
                        <List.Header><b>{activity.name || 'pas de titre'}</b></List.Header>
                        <List.Description className="emDotEight flexy flexCenter">{activity.location || ' '}</List.Description>
                    </List.Content>
                    
                </div>
            </Grid.Column>

            {/* status */}
            <Grid.Column width={1}>
                {
                    // currentStatusIcon(
                    //     ((activity.status && activity.status === 'noDatas') || !activity.status)
                    //     ? (((activity.files && activity.files.length) || activity.notes || (activity.photoReports && activity.photoReports.length)) ? "noAccountingDocumentIssued" : "noDatas")
                    //     : activity.status
                    // )
                    currentStatusIcon(activity)
                }
            </Grid.Column>

            {/* customer */}
            <Grid.Column width={(Layout.isSmallDevice || Layout.isTablet) ? 2 : 3}>
                <span style={{height: '100%'}} className="flexy flexCenter">
                    {
                        activity.customerName 
                        ? <span><Icon name={"user outline"} size="small" /> {activity.customerName } </span>
                        : unkownUserIcon()
                    }
                </span>
                {/* <Head size="tiny" contentStyle={{fontSize: '1em', textAlign: 'left'}} subHeaderStyle={{textAlign: 'left'}} subHeaderContainerStyle={{fontSize: '.8em'}} title="Client" titleStyle={{textAlign: 'left'}} content={activity.customerName || 'inconnu'} icon={<Icon name="user outline" size="small" />}/> */}
            </Grid.Column>


            {/* dates */}
            <Grid.Column width={Layout.isTablet ? 4 : 3}>
                <span className="flexy flexCenter" style={{height: '100%'}}>
                    <div className="left-aligned tekotxt"><Icon size="small" name="calendar alternate outline" /><br />Ouvert le <span className="silver">{Utils.formatedDate(new Date(Number(activity.openedOn)), true)}</span></div>
                    <div className="left-aligned tekotxt"><Icon size="small" name="edit" /><br />Modifié le <span className="silver">{activity.lastModified ? Utils.formatedDate(new Date(activity.lastModified), true) : '--/--/----'}</span></div>
                </span>
            </Grid.Column>

            {/* quotations */}
            <Grid.Column width={1}>
                {iconCount(activity.quotations, "Devis", "file alternate outline")}
            </Grid.Column>

            {/* bills */}
            <Grid.Column width={1}>
                {iconCount(activity.bills, "Facture", "file")}
            </Grid.Column>

            {/* files */}
            <Grid.Column width={1}>
                {iconCount(activity.files, "Fichier", "file outline")}
            </Grid.Column>

            {/* reports */}
            <Grid.Column width={1}>
                {iconCount(activity.photoReports, "Rapport", "file pdf")}
            </Grid.Column>

             {/* historic */}
             <Grid.Column width={(Layout.isSmallDevice || Layout.isTablet) ? 1 : 2}>
                {iconCount(activity.historic ? activity.historic : 0, "Historique", "history")}
            </Grid.Column>

            {/* */}
            <Grid.Column width={16}>
                <Divider />
            </Grid.Column>
        </Grid>
    )

    renderAllActivities = (activities) => <List animated divided relaxed className="tekoAll" style={{fontSize: '1.5em'}}>
        {
            (activities && activities.length)
            ? activities.map((a) => this.renderItem(a))
            : (
                this.state.isSearching 
                ? <div className="flexy flexCenter emOne negativeText">Pas de résultat</div>
                : <div className="flexy flexCenter emOne">La liste des interventions est vide</div>
            )
        }
    </List>

    newActivityCreated = async() => {
        this.setState({createNew: false})
        this.updateActivities(true)
    }
        

    render () {
        const { createNew, activities, openedActivity } = this.state;
        // console.log('Activities render state', this.state)
        // console.log('Activities render props', this.props)

        //activities && console.log('activities', activities)
        return(
            activities
            ? (
                createNew
                ? <NewActivity fetchCredentials={this.props.session.fetchCredentials} customers={this.props.session.Client.customers} onSave={this.newActivityCreated} newActivity={this.state.newActivityProps} onDeny={(() => this.setState({createNew: false})).bind(this)}/>
                : (
                    openedActivity
                    ? <ActivityFile 
                        ActivityStatus={ActivityStatus} 
                        currentStatusIcon={currentStatusIcon} 
                        activity={openedActivity} 
                        activities={activities} 
                        updateActivities={this.updateActivities} 
                        removeActivity={this.removeOpenedActivity.bind(this)}
                    />
                    : this.renderAllActivities(activities)
                )
            )
            : <AppLoader open={!activities} title="Chargement des dossiers d'interventions" />
            //: <Modal open><Loader>Chargement des dossiers</Loader></Modal>
        )
    }

}

export default connect(Utils.mapStateToProps)(Activities)