import React from 'react';
import { Header, Icon, Input, Loader } from 'semantic-ui-react';
import Utils from '../constants/Utils'
import {connect} from 'react-redux'


/**
 * @title ModifiableItem React Component
 * @dev Allow any app's input to set values to db through server with a simple validation
 * ##PROPS
 *  - ...Header props
 *  - pair *REQUIRED* {object: key, oldValue} : key/value pair to store
 *  - disabled (bool|any) : a Modifiable Item can be disabled, no edition validation button will be display after a value change is triggered
 *  - validated (bool|any) : a Modifiable Item can appear as validated : no edition will be possible ever and the edit icon will be replaced by a green check one
 *  - valueFontSize (number) : size of the selected value text (default: 20)
 *  - valueClassName
 *  - titleClassName (string) : an additional className for title
 *  - titleStyle {object} : an additional style object for title
 *  - mainContainerStyle {object} : style object for the main ModifiableItem container
 *  - inputContainerStyle {object}
 *  - placeholder (string) : a placeholder string for the default Input component
 *  - tag (string): a tag to display next to value
 *  - changeMethodAsync (function) *opt* : async method to use to handle storage instead of default fetchApi using "request" prop. receives {key, newValue, oldValue}. if error, MUST return {error: *any*}
 *  - request (string) *only if no changeMethodAsync prop is provided*:  name of the request (comprehensible by server)
 *  - additionalRequestBodyProp {object} *opt* *only if no changeMethodAsync prop is provided*: merge an object with fetchApi method 'body' prop
 *  - alertMode (bool) *optional* : if set to true, fire an Alert result (default: false)
 *  - editIconSize (enum) *opt* : size of the edit icon (default: 'small')
 *  - saveIconSize (enum) *opt* : size of the save icon (default: 'small') 
 *  - onModify (function) *optional* : if set, this method will be call each time the value is modified (e, {key, newValue, oldValue, hasChanged, toggleEdit}) // toggleEdit allow control of edition mode on edition component
 *  - onChangeMethodCalled (function) *optional* : if set, this function will be called each time changeMethodAsync or fetch(request) respond (return {error, response, request, key, newValue, oldValue})
 *  - customEditionComponent (react component) *optional* if set, will replace default Input component when editing 
 *    *(changeHandler(fun), value(any)) => {}*
 *  - restrictEdition *opt* (func): if provided, this method will be called before rendering the edition button (MUST return a boolean) 
 *  - renderValue *opt* (function): if provided this method is used to render the edited value instead of straight display *(value)=>{}* 
 *  - forceDisplayMode (bool): if set, the ModifiableItem will be forced to render in its non-edition mode (default: false)
 */

class ModifiableItem extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            hasChanged : false,
            newValue: undefined,
            isStoring: false,
            isEditing: false
        }
    }

    componentDidUpdate() {
        if (this.state.isEditing && this.props.forceDisplayMode)
            this.setState({isEditing: false})
    }

    SilverSpan = ({title, controllers, titleStyle, className}) => <li><span className={`silver ${className}`} style={titleStyle}>{title} </span> {controllers} </li>
    SilverSpanNoLi = ({title, controllers, titleStyle, className}) => <span><span className={`silver ${className}`} style={titleStyle}>{title} </span> {controllers} </span>

    changeHandler = (e) => {
        const {pair, onModify} = this.props
        const {key, oldValue} = pair || {}
        const NewVal = e.target.value
        const state_ = {key, newValue: NewVal, oldValue: this.state.oldValue || oldValue, hasChanged: NewVal !== (this.state.oldValue || oldValue)}

        onModify && typeof onModify === 'function' && onModify(e, {...state_, toggleEdit: this.toggleEdit.bind(this)})
        // display a 'save changes' button
        //@dev IMPORTANT: oldValue -> keep actual state.oldValue previously set by storeChanges else fetch fails on next storeChanges action (api oldValue is not current for matching if reset here)
        this.setState(state_) // old version of hasChanged: NewVal !== oldValue
    }

    storeChanges = async() => {
        this.setState({isStoring:true})
        //console.log('updating customer', JSON.stringify(this.state)); 
        const {key, newValue, oldValue} = this.state
        const {request, changeMethodAsync, alertMode, onChangeMethodCalled, additionalRequestBodyProp} = this.props

        let fetched = changeMethodAsync
            ? await changeMethodAsync({key, newValue, oldValue})
            : await Utils.fetchApi({
                //clientId: this.state.clientId,
                body : {...this.state, ...(additionalRequestBodyProp || {})},
                request,
                client: this.props.fetchCredentials
                // default method : POST
            })


        onChangeMethodCalled && onChangeMethodCalled({...fetched, request, key, newValue, oldValue})   // {error, response}
        
        this.setState({isStoring:false, hasChanged:false, oldValue: newValue});

        let success = !fetched.error
        alertMode && alert(success ? 'Succès' : 'Erreur', success ? 'modification effectué' : 'Il y a eu un problème, veuillez réessayer.')
        success && this.toggleEdit()

    }

    toggleEdit = () => this.setState({isEditing: this.state.isEditing ? false : true})

    render() {
        const { hasChanged, isStoring, isEditing, newValue, oldValue } = this.state
        //console.log('this.props.pair.oldValue', this.props.pair.oldValue)
        //console.log('ModifiableItem state', this.state)
        //
        //const value = newValue || oldValue || this.props.pair.oldValue
        //

        const value = typeof newValue !== 'undefined' 
            ? newValue
            : oldValue || this.props.pair.oldValue
        
        
        

        const SPAN = this[this.props.noLi ? "SilverSpanNoLi" : "SilverSpan"](
            {
                controllers: <span>
                    {
                        this.props.validated
                        ? <Icon color="green" name="check" size={this.props.editIconSize || "small"} />
                        : !this.props.forceDisplayMode && <Icon className="overable" circular color="grey" name="edit" size={this.props.editIconSize || "small"} onClick={this.toggleEdit.bind(this)} />
                
                    }
                    {
                        hasChanged && !this.props.disabled
                        && <Icon className="overable" name="check circle outline" color="teal" size={this.props.saveIconSize || "large"} onClick={this.storeChanges} />
                    }
                </span>, 
                title: this.props.title,
                titleStyle: this.props.titleStyle, 
                className: this.props.titleClassName
            }
        );

        return(
            <div style={this.props.mainContainerStyle || undefined}>
                   
                {
                    isStoring
                    && <Loader inverted={true}></Loader>
                }
  
                <Header title={this.props.title} as={this.props.as} className={this.props.className} style={{fontSize: '1em'}}>   {/* instead of title={this.props.title}, was ---> {...{...this.props, pair: undefined}} */}
                    <Header.Content>
                        {   
                            (this.props.restrictEdition && typeof this.props.restrictEdition === 'function')
                            ? (
                                this.props.restrictEdition()
                                ? SPAN
                                : ''
                            ) : SPAN
                            
                        }
                    </Header.Content>
                    <Header.Subheader style={this.props.inputContainerStyle}>
                    { 
                        isEditing
                        ? (
                            this.props.customEditionComponent
                            ? this.props.customEditionComponent(this.changeHandler, value)
                            : <Input onChange={this.changeHandler} placeholder={this.props.placeholder} value={value} style={{width: '100%', fontSize: 20}}/> 
                        )
                        : (
                            !this.props.renderValueNoWrap
                            && <Header style={{fontSize: this.props.valueFontSize || 20}}>{this.props.renderValue ? this.props.renderValue(value) : <span className={this.props.valueClassName}>{value}</span>}{this.props.tag || ""}</Header> 
                        )
                    }
                    </Header.Subheader>
                </Header>
                {
                    this.props.renderValueNoWrap
                    && this.props.renderValueNoWrap(value)
                }
            </div>
        )
    }
}

export default connect(Utils.mapFetchCredentialsToProp)(ModifiableItem)

